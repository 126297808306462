import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ChatMessageType, IUser } from '@src/shared/types';
import { LocalStorageKey } from '@src/core/utils';
const { v4 } = require('uuid');

export interface AuthState {
  sessionID: string
  me?: IUser
  messages: any
}

let sessionID = window.localStorage.getItem(LocalStorageKey.SESSION_ID);
if(!sessionID) {
  sessionID = v4();
  window.localStorage.setItem(LocalStorageKey.SESSION_ID, sessionID!);
}

// localStorage에 이미 저장된 me 정보가 있으면, 그걸로 초기화
let mePayload = JSON.parse(window.localStorage.getItem(LocalStorageKey.ME) || '{}');
if(!window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN))
  mePayload = undefined;

//
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: undefined,
    sessionID: sessionID,
    me: mePayload || undefined,
    messages: []
  } as AuthState,
  reducers: {
    saveMessages: (state: AuthState, action) => {
      state.messages = action.payload;
      return state;
    },
    setMe: (state: AuthState, action) => {
      state.me = action.payload;
      if(state.me)
        window.localStorage.setItem(LocalStorageKey.ME, JSON.stringify(state.me));
      else
        window.localStorage.removeItem(LocalStorageKey.ME);

      //
      return state;
    },
  }
});

export const { saveMessages, setMe } = authSlice.actions;

export default authSlice.reducer;
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Toolbar,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { getString, httpGet } from '@src/core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { IChat, IChatMessage, IUser, IUserAttachment, IUserCashReceipt, IShopItemModel } from '../../shared/types';
import { setMe } from '../../store/auth';
import "./AdminChatHistoryScreen.css";
import Header from '@src/Header';
import prettyMilliseconds from 'pretty-ms';
import AdminTabs, { AdminTab } from "@src/screens/admin/AdminTabs";

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

type Receipt = {
  createdAt: string;
  user?: {
    name: string;
  };
  cashMoney: number;
  cashMoneyPromotion: number;
};

type MonthlySales = {
  [key: string]: {
    total: number;
    receipts: Receipt[];
  };
};

const getMonthlySales = (receipts: Receipt[], items: IShopItemModel[]): MonthlySales => {
  const monthlySales: MonthlySales = {};

  receipts.forEach((receipt) => {
    const date = new Date(receipt.createdAt);
    const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;

    const amount = receipt.cashMoney + receipt.cashMoneyPromotion;
    const cashValue =
        (amount === 10 && items[0]?.realPrice) ||
        (amount === 20 && items[1]?.realPrice) ||
        (amount === 40 && items[2]?.realPrice) ||
        (amount === 70 && items[3]?.realPrice) ||
        (amount === 100 && items[4]?.realPrice) ||
        0;

    if (!monthlySales[yearMonth]) {
      monthlySales[yearMonth] = { total: 0, receipts: [] };
    }
    monthlySales[yearMonth].total += cashValue;
    monthlySales[yearMonth].receipts.push(receipt);
  });

  return monthlySales;
};

function AdminConnectScreen() {
  const history = useHistory();
  const [receipts, setReceipts] = useState<Receipt[]>([]);
  const now = new Date().getTime();
  const params = useParams() as any;
  const signupType = params.signupType;
  const [userCount, setUserCount] = useState(0);
  const [paymentCount, setPaymentCount] = useState(0);
  const [birthdayUserCount, setBirthdayUserCount] = useState(0);
  const [items, setItems] = useState<IShopItemModel []>([]);

  useEffect(() => {
    httpGet('/api/admin/connect?signupType=' + signupType).then((res) => {
      setUserCount(res.userCount);
      setBirthdayUserCount(res.birthdayUserCount);
      setPaymentCount(res.paymentCount);
      setReceipts(res.receipts);
    });
  }, [signupType]);

  useEffect(() => {
    httpGet('/api/shop/items').then((res) => {
      let items: IShopItemModel [] = res.items;
      setItems(items);
    });
  }, [])

  function onBack() {
    history.push('/profile');
  }

  const monthlySales = getMonthlySales(receipts, items);

  return (
      <>
        <Header backButton="/more" onBack={onBack} title="" />
        <div className="profileChatHistoryScreen">
          <div>
            <Typography variant="h6" style={{ marginBottom: '10px' }}>유저 가입자</Typography>
            <Typography variant="h6" style={{ marginBottom: '10px' }}>{userCount}</Typography>
            <Typography variant="h6" style={{ marginBottom: '10px' }}>생년월일 입력 가입자</Typography>
            <Typography variant="h6" style={{ marginBottom: '10px' }}>{birthdayUserCount}</Typography>
            <Typography variant="h6" style={{ marginBottom: '10px' }}>결제 건수</Typography>
            <Typography variant="h6" style={{ marginBottom: '10px' }}>{paymentCount}</Typography>
          </div>
          <Table aria-label="customized table">
            <colgroup>
              <col width="40%" />
              <col width="40%" />
              <col width="20%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell>{getString('Username')}</StyledTableCell>
                <StyledTableCell>{getString('Amount')} 매출</StyledTableCell>
                <StyledTableCell style={{ width: '150px', textAlign: 'right' }}>{getString('Time')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(monthlySales).map(([yearMonth, data]) => (
                  <React.Fragment key={yearMonth}>
                    {data.receipts.map(c => (
                        <StyledTableRow key={c.createdAt}>
                          <StyledTableCell>{c.user?.name}</StyledTableCell>
                          <StyledTableCell>
                            {
                              c.cashMoney + c.cashMoneyPromotion === 10 ? items[0]?.realPrice :
                                  c.cashMoney + c.cashMoneyPromotion === 20 ? items[1]?.realPrice :
                                      c.cashMoney + c.cashMoneyPromotion === 40 ? items[2]?.realPrice :
                                          c.cashMoney + c.cashMoneyPromotion === 70 ? items[3]?.realPrice :
                                              c.cashMoney + c.cashMoneyPromotion === 100 ? items[4]?.realPrice :
                                                  null
                            }
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '150px', textAlign: 'right' }}>
                            {new Date(c.createdAt).toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric' })}
                          </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    <StyledTableRow key={`${yearMonth}-total`}>
                      <StyledTableCell colSpan={3} style={{ textAlign: 'left'}}>
                        {yearMonth}월 리치고 매출 합계: <b>{data.total} 원</b>
                      </StyledTableCell>
                    </StyledTableRow>
                  </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      </>
  );
}

export default AdminConnectScreen;

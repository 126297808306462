import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import {
    AppBar,
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
    Toolbar,
    Typography,
    makeStyles
} from '@material-ui/core';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuIcon from '@material-ui/icons/Menu';
import {LocalStorageKey, getString, httpGet, httpPost, setAccessToken} from '@src/core/utils';
import {
    CodeResponse,
    CredentialResponse,
    TokenResponse,
    useGoogleLogin,
    useGoogleOneTapLogin
} from '@react-oauth/google';
import {useDispatch, useSelector} from 'react-redux';
import {ReducerType} from '@src/store';
import {IUser} from '@src/shared/types';
import {setMe} from '../../store/auth';
import {StatusCode} from '@src/shared/statuscode';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import SignupScreen from "./SignupScreen";
import KakaoLogin from "react-kakao-login";
import NaverLogin from '@src/components/NaverLogin';
const InApp = require('detect-inapp');
const inapp = new InApp(navigator.userAgent || navigator.vendor);

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginRight: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.primary.main,
    },
    profile: {
        backgroundImage: "url('https://img.piku.co.kr/w/uploads/721FCA/3795bd98e8918513062bee2a16da417a.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        width: '50%',
        height: '10em',
        maxWidth: '100%',
    }
}));

function SigninScreen() {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [secret, setSecret] = useState('');
    const dispatch = useDispatch();
    const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const classes = useStyles();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

    const [loginOpen, setLoginOpen] = useState(false);
    const [signUpOpen, setSignUpOpen] = useState(false);

    const handleClickUpOpen = () => {
        setSignUpOpen(true);
    };

    const handleUpClose = () => {
        setSignUpOpen(false);
    };

    const handleClickInOpen = () => {
        setLoginOpen(true);
    };

    const handleInClose = () => {
        setLoginOpen(false);
    };

    const handleLoginGoogle = useGoogleLogin({
        onSuccess: (credentialResponse) => {
            signin({googleAccessToken: credentialResponse.access_token});
        },
        onError: () => {
            console.log('Google Login Failed');
        },
    });

    function loginGoogle() {
        //
        // if(inapp.isInApp) {
        //     return alert(getString('Browser_NotSupported'));
        // }

        handleLoginGoogle();
    }

    function loginNaver(execute: any) {
        //
        // if(inapp.isInApp) {
        //     return alert(getString('Browser_NotSupported'));
        // }
        execute();

    }

    // useGoogleOneTapLogin({
    //     disabled: inapp.isInApp,
    //     onSuccess: (credentialResponse: CredentialResponse) => {
    //         signin({googleCredential: credentialResponse.credential});
    //     },
    //     onError: () => {
    //         console.log('Google One Tap Login Failed');
    //     },
    // });

    async function signin(params: any) {
        params.sessionID = sessionID;
        params.recaptchaToken = await executeRecaptcha!('signin');
        params.referralID = window.localStorage.getItem(LocalStorageKey.REFERRAL_ID);

        //
        httpPost('/api/users/signin', params).then((res) => {
            if (res.status >= StatusCode.SUCCESS) {

                //
                setAccessToken(res.token);
                dispatch(setMe(res.user));

                //
                let search = new URLSearchParams(window.location.search);
                history.push(search.get('redirect') || '/');
            }
        });

        return false;
    }

    function signinKakao() {
        let restApiKey = 'ba884ada7dbe83d9e02162e7897029d9';
        let redirectUri = `${window.location.protocol}//${window.location.host}/signin/kakao`;
        let url = `https://kauth.kakao.com/oauth/authorize?client_id=${restApiKey}&redirect_uri=${redirectUri}&response_type=code`;

        //
        window.location.href = url;
    }

    return (
        <article className="page-wrapper">
            <h6 style={{marginTop: '150px', lineHeight: '36.4px', fontWeight: '700', fontSize: '28px', marginBottom: '16px', color: 'rgba(0,0,0,0.8)'}}>
                <b style={{color: '#19DDC5'}}>플루밍</b>에 오신것을<br/>환영해요 :)
            </h6>
            <p style={{marginBottom: '145px', fontSize: '14px', color: 'rgba(0,0,0,0.45)'}}>내 마음에 필요했던 조언을 받아보세요</p>

            <div className="btn-box" style={{display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center', marginTop: '3rem', gap: '0.8rem 0px',}}>
                <NaverLogin
                    clientId={"egTe4QxSj9dbHtVzu7Hw"}
                    callbackUrl={`${window.location.protocol}//${window.location.host}/signin/naver${window.location.search}`}
                    render={({ onClick }: any) =>
                        <button className="social-btn naver" type="button" onClick={() => loginNaver(onClick)}>
                            <img style={{marginLeft: '8px'}} src="/naver.svg" alt="" width="20" height="20"/>
                            <span>{getString('Signin_Naver')}</span>
                            <img src="/blank.png" alt="" width="20" height="20"/>
                        </button>}
                    onSuccess={(naverUser: any) => console.log(naverUser)}
                    onFailure={(error: any) => console.error(error)}
                />
                <button className="social-btn kakao" type="button" onClick={signinKakao}>
                    <img style={{marginLeft: '8px'}} src="/kakao.svg" alt="" width="20" height="20"/>
                    <span>{getString('Signin_Kakao')}</span>
                    <img src="/blank.png" alt="" width="20" height="20"/>
                </button>
                <button className="social-btn google" type="button" onClick={loginGoogle}>
                    <img style={{marginLeft: '8px'}} src="/google.svg" alt="플루밍 구글" width="20" height="20"/>
                    <span>{getString('Signin_Google')}</span>
                    <img src="/blank.png" alt="플루밍" width="20" height="20"/>
                </button>
                {/*<button className="signup-btn" onClick={handleClickUpOpen}>{getString('Signup_Button')}</button>*/}
                {/*<button className="signup-btn" onClick={handleClickUpOpen} onClick={() => history.push('/signup')}>계정 만들기</button>*/}
                {/*<Button color="default" onClick={() => history.push('/forgotPassword')}
                        type="submit" style={{
                    color: '#868686',
                    fontSize: '13px'
                }}>
                    {getString('ForgotPassword')}
                </Button>*/}
            </div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '32px', marginBottom: '8px', fontSize:'12px', fontWeight: '500', color: 'rgba(0,0,0,0.45)'}}>
                <a onClick={handleClickInOpen} style={{textDecoration: 'underline', cursor: 'pointer'}}>이메일로 로그인</a>
                <span style={{margin: '0px 8px'}}> / </span>
                <p><a href="/terms" style={{textDecoration: 'underline', cursor: 'pointer'}}>이용약관</a> 및 <a href="/privacy" style={{textDecoration: 'underline', cursor: 'pointer'}}>개인정보 취급방침</a></p>
            </div>
            <p style={{fontSize: '12px', fontWeight: '500', color: '#00000073', textAlign: 'center'}}>Copyright ⓒ fluming.ai ALL RIGHTS RESERVED</p>
            {/*<p style={{textAlign: 'center', fontSize: '0.8em', color: '#999', marginTop: '2rem', paddingBottom: '2rem', lineHeight: '18px'}}>
                <a style={{color: "#ff797f", cursor: 'pointer'}} onClick={handleClickInOpen}>로그인</a>을 수행하시면 플루밍의 <a href="/privacy" style={{color: "#ff797f"}}>개인정보처리방침</a> 및<br/><a href="/terms" style={{color: "#ff797f"}}>이용약관</a>에 동의하시는 것으로 간주됩니다.
            </p>*/}



            <Dialog
                open={loginOpen}
                onClose={handleInClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const email = formJson.email;
                        console.log(email);
                        handleInClose();
                    },
                }}
                id="emailSignIn"
            >
                <DialogContent id="emailSignForm">
                    <button type="button" className="back-btn" onClick={handleInClose}>뒤로가기</button>
                    <form onSubmit={(e) => {e.preventDefault();}}>
                        <div className="form-wrapper">
                            <h6>이메일 로그인</h6>
                            <div className="input-wrapper">
                                <label>{getString('Signup_Email')}</label>
                                <input className={`${email !== '' && !emailRegex.test(email) ? "error" : ""}`} type="email" value={email} placeholder="이메일 입력" onChange={(e) => setEmail(e.target.value)} autoComplete="email"/>
                                {
                                    email !== '' && !emailRegex.test(email) &&
                                    <p className="error-text">올바르지 않은 이메일 형식입니다</p>
                                }
                            </div>
                            <div className="input-wrapper">
                                <label>{getString('Signup_Password')}</label>
                                <input type="password" className={`${secret !== '' && !passwordRegex.test(secret) ? "error" : ""}`} value={secret} placeholder="영문, 숫자, 기호 포함 8~20자" onChange={(e) => setSecret(e.target.value)} autoComplete="password"/>
                                {
                                    secret !== '' && !passwordRegex.test(secret) &&
                                    <p className="error-text">영문, 숫자, 기호 포함 8~20자</p>
                                }
                            </div>
                            <button type="button" disabled={email === '' || secret === '' ? true : false} className={`signin-btn ${email === '' || secret === '' ? "" : "filled"}`} onClick={() => signin({email, secret})}>{getString('Signin')}</button>
                        </div>
                        <div className="btn-box">
                            <a href="/password">비밀번호 재설정</a>
                            <a href="/signup">회원가입</a>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog
                open={signUpOpen}
                onClose={handleUpClose}
            >
                <DialogContent>
                    <SignupScreen/>
                </DialogContent>
            </Dialog>
        </article>
    )
}



export default SigninScreen
 
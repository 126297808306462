import { List, ListItem, ListItemIcon, ListItemText, Modal } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useRef, useState } from "react";
import { Adsense } from '@ctrl/react-adsense';
import { ICharacter } from "@src/shared/types";
import { getString, httpPost } from "@src/core/utils";

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      maxWidth: '100%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  

export function ADModal({open, onClose, character, sessionID}: {
    open: boolean,
    onClose: () => void,
    character: ICharacter | undefined,
    sessionID: string
}) {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);

    function close() {
      httpPost('/api/characters/adWatched', {
        sessionID: sessionID,
        characterHashID: character?.hashID,
      }).then((res) => {
        onClose();
      });
    }

    return (
        <Modal
            open={open}
            onClose={close}
            >
            <div style={modalStyle} className={classes.paper}>
                광고가 여기 노출됩니다.<br/>
                <Adsense
                client="ca-pub-7640562161899788"
                slot="7259870550"
                />
                <p>
                  <Button onClick={close} variant="contained" color="secondary" fullWidth>{getString('ADModal_Continue')}</Button>
                </p>
            </div>
        </Modal>
    )
}

export default ADModal;
 import { setAccessToken } from '@src/core/utils';
import { setMe } from '@src/store/auth';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
 import { Link, useHistory } from 'react-router-dom';

function SignoutScreen() {

  let history = useHistory();
  let dispatch = useDispatch();

  useEffect(() => {
    setAccessToken(null);
    dispatch(setMe(null));
    history.push('/');
  }, []);

  return (<>
    </>);
 }
 
 export default SignoutScreen
 
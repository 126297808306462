import React from 'react';
import { Link } from 'react-router-dom';
import "../../assets/css/app.css";

function Chat({name, category, message, profilePic, timestamp, link}: {
    name: string,
    category?: string,
    message: string,
    profilePic: string,
    timestamp: string,
    link?: string,
}) {

    const createMarkup = (html: string) => {
        return { __html: html };
    };

    return (
        /*<Link to={`/play/${c.hashID}`} style={{display: 'flex', alignItems: 'center', gap: '0px 16px', width: '100%', height: '162px', backgroundColor: '#ffffff', padding: '18px 16px', boxSizing: 'border-box'}}>
            <div style={{position: 'relative', width: '126px', height: '126px', backgroundImage: `url('${c.imageURL}')`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '6.5px'}}>

            </div>
            <div className="character-info">
                <div className="category-title">
                    <span className="character-list--item" dangerouslySetInnerHTML={{ __html: c.name }}/>
                </div>
                <p className="description">해당 캐릭터에 대한 설명이 들어가는 란입니다</p>
                <div className="pricing">
                    <img src="./gold.svg" width={16} height={16} style={{marginRight: '4px'}}/>
                    <b style={{fontWeight: '600', fontSize: '14px', color: 'rgba(0,0,0,0.85)', marginRight: '3px'}}>{c.cashPrice.toLocaleString()}다이아</b>
                    <p style={{fontWeight: '400', fontSize: '12px', color: 'rgba(0,0,0,0.45)'}}>채팅해제+사주노트</p>
                </div>
                <div className="tags">
                    <span>#태그1</span>
                    <span>#태그2</span>
                </div>
                {/!*<span style={{position: 'absolute', top: '8px', right:'15px', color: 'white', textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>❤️ {c.likeCount}</span>*!/}
                {/!*<div style={{display:'flex', flexDirection: 'column', justifyContent:'flex-end', alignItems:'flex-start', height:'100%', bottom: '0px', textAlign: 'center', width: '100%', backgroundColor:'rgba(0,0,0,0.3)', color:'white', fontWeight: 'normal', padding: '40px 30px', boxSizing:'border-box'}}>
                                                <span className="character-price">
                                                  {<img src="/gold.png" alt="" width="25" height="25" style={{marginRight: '5px'}}/>} {c.cashPrice.toLocaleString()}
                                                </span>

                                                 <span className="card-category" style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", wordBreak: "break-all", fontSize: '19px', fontWeight: 'normal'}}>상담/명리학</span>
                                            </div>*!/}
            </div>
        </Link>*/

        <Link to={link || `/@${name}`} className="chat-list-item-row" style={{display: 'flex', alignItems: 'center', gap: '0px 12px', width: '90%', height: '95px', backgroundColor: '#ffffff', padding: '18px 24px', boxSizing: 'border-box', borderTop: '1px solid #f4f4f4', borderBottom: '1px solid #f4f4f4', margin: '0px auto'}}>
            <div style={{position: 'relative', minWidth: '59px', width: '59px', height: '59px', backgroundImage: `url('${profilePic}')`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '100px'}}/>
            <div className="chat chat-history-item">
                <div className="chat__details" style={{overflow: 'hidden'}}>
                    <div className="top-line">
                        <h6 dangerouslySetInnerHTML={{ __html: name }}/>
                        <div className="category">{category}</div>
                    </div>
                    {/*<div className="middle-line">
                        <div className="last-dialog-text">마지막 대화</div>
                        <time className="chat__timestamp">{timestamp} 전</time>
                    </div>*/}
                    <div className="bottom-line">
                        <span dangerouslySetInnerHTML={createMarkup(message)}/>
                    </div>
                </div>

            </div>
        </Link>
    )
}

export default Chat

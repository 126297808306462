import React, { useEffect, useState } from 'react'
import "../chat/ChatListScreen.css";
import Point from './Point'
import { Link } from 'react-router-dom';
import { getString, gotoSignin, httpGet } from '../../core/utils';
import { IChat, IUser } from '../../shared/types';
import { useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import prettyMilliseconds from 'pretty-ms';
import NotificationsActiveOutlined from "@material-ui/icons/NotificationsActiveOutlined";


function PointListScreen() {

  const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);
  const [now, setNow] = useState(new Date().getTime());
  const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);

  useEffect(() => {

    if(!me)
      gotoSignin(false);
  }, []);

  return (
      <div className="page-contents-wrapper chat-list-page">
        <h4 className="page-heading" style={{fontSize: '29px', marginBottom: '43px', color: '#000000'}}>결제/사용내역</h4>
        <div className="chats more chat-list f-container">
          {/*<div className="header-tool-box"/>*/}
          <div className="chat-list-box">
              <Point
                  name="1다이아"
                  category="사용"
                  message="서유리의 진로상담 대화"
                  timestamp="2024.03.06 11:32:17"
              />
              <Point
                  name="85다이아"
                  category="사용"
                  message="서유리의 진로상담 결제"
                  timestamp="2024.03.06 11:23:09"
              />
              <Point
                  name="200다이아"
                  category="충전"
                  message="국민카드(8762) 결제"
                  timestamp="2024.03.06 11:10:12"
              />
          </div>
          {/*{chats.length === 0 &&
            <div style={{textAlign: 'center'}}>
              {getString('Chats_Empty')}
            </div>
          }*/}
        </div>
      </div>
  )
}

export default PointListScreen;
 
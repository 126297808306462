import "./MoreScreen.css";
import {getString, httpGet} from '../../core/utils';
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ReducerType} from "@src/store";
import {IUser, IUserCashReceipt, UserCashReceiptType} from "@shared/types";
import prettyMilliseconds from 'pretty-ms';

function Payments() {

    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    const [receipts, setReceipts] = useState<IUserCashReceipt []>([]);
    const [now, setNow] = useState(new Date().getTime());

    useEffect(() => {
        httpGet('/api/shop/receipts').then((res) => {
            setReceipts(res.receipts);
        });
    }, []);

    return (
        <div className="page-contents-wrapper" id="paymentsHistory">
            <div className="my-cash-wrapper" style={{borderBottom: 'none'}}>
                <div className="my-cash">
                    <div className="left">
                        💎
                        보유 다이아
                    </div>
                    <div className="right">
                        <b>{me?.cashMoney ? me?.cashMoney?.toLocaleString() : '0'}</b>
                    </div>
                </div>
            </div>

            <div className="point-history">
                {
                    receipts.length === 0 ?
                        <div className="no-data">
                            <img src="/alert.svg" width={64} height={64} alt="플루밍 얼럿"/>
                            내역이 없습니다
                        </div>
                        :
                        receipts.map(x => (
                            <div className="point-box history">
                                <div className="recharging-text">
                                    <time>{prettyMilliseconds(now - x.createdAt, {compact: true}).replace(/d/g, '일').replace(/m/g, '분').replace(/h/g, '시간')}전</time>
                                    {x.type == UserCashReceiptType.CHARGE &&
                                        <div className="point-history-row">
                                            <span className="text">다이아 충전</span>
                                            <span className="number plus">+ {(x.cashMoney + x.cashMoneyPromotion).toLocaleString()}</span>
                                        </div>
                                    }
                                    {x.type != UserCashReceiptType.CHARGE &&
                                        <div className="point-history-row">
                                            <span className="text">다이아 차감</span>
                                            <span className="number">
                                                {(x.cashMoney + x.cashMoneyPromotion).toLocaleString().includes('-') ? "" : "-"}
                                                {(x.cashMoney + x.cashMoneyPromotion).toLocaleString()}
                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))
                }
            </div>
        </div>
    )
}

export default Payments
 
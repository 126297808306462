import {IInfluencerAlbum} from "../../server/shared/types";

export interface IBirthday {

    name: string
    year: number
    month: number
    day: number
    yang: boolean
    sex: boolean

    sangsi: boolean
    hour: number
    minute: number

    birthplace: boolean
    
    mbti?: string

    situation?: string
}

export interface IInfluencerSns {
    instagram: string
    youtube: string
    tiktok: string
    twitter: string
}

export interface IInfluencer {
    hashID: string
    name: string
    kor_name: string
    follower: number
    sns: IInfluencerSns
    mainImageURL: string
    user_id: number
    voice_id: string
    album: IInfluencerAlbum;
}

export interface IUser {
    hashID: string
    snsID: string
    name: string
    introduction: string
    imageURL: string
    todayFortune: string
    homeAddress: string
    signupType: string

    // Private
    email?: string
    cashMoney?: number
    gmLevel?: number
    birthday?: IBirthday
    recommendEventJoined?: boolean
    checkinToday?: boolean
}

export interface IUserAttachment {
    hashID: string
    imageURL: string
    opened: boolean
    minLoveLevel: number
    cashPrice: number
    createdAt: number
    name: string
}

export interface IUserProgress {
    user?: IUser
    level: number
    exp: number
    attachmentUnlockCount: number
}

export interface IUserImageGeneration {
    hashID: string
    name: string
    imageURL: string
}

export interface IChat {
    hashID: string
    name: string
    lastMessage: string
    updatedAt: number
    createdAt: number
    state: ChatState
    freeChatCount: number
    homeAddress: string
    step: number
    chatUnseStep: number
    starRate: number
    base64: string

    //
    user?: IUser
    targetUser?: IUser
    character?: ICharacter
    birthday?: IBirthday
    birthday2?: IBirthday

    // Deprecated
    loveLevel?: number
    loveEXP?: number
    loveMaxEXP?: number
}

export enum ChatState {
    NONE = 0,
    INPUT = 1,
    INPUT_TWO = 2,
    BEFORE_PAYING = 4,
    REQUEST_PAYMENT = 5,
    PLAY = 6, // Play freely
    PLAY_STEP = 7, // Play Step by step 
}

export interface IChatMessage {
    id?: number
    senderHashID: string
    senderSessionID: string
    name?: string
    image?: string
    type: number
    state: number
    text: string
    createdAt: number
    attachment?: ICharacterAttachment

    step?: number
    selections?: string []
}

export enum ModelType {
    GPT_3_5 = 0,
    GPT_4 = 1,
}

export enum ChatMessageType {
    CHAT = 0,
    IMAGE = 1,
    VIDEO = 2,
    VOICE = 3,
    SELECTION = 4,
    NOTICE = 5,
    INPUT_BIRTHDAY = 6,
    REQUEST_PAYMENT = 7,
    PAYMENT_COMPLETED = 8,
}

export enum CharacterType {
    SAJU_SINGLE = 1,
    SAJU_COUPLE = 2,
    SAJU_FRIENDS = 3,
    SAJU_TODAY = 4,
    SAJU_YEAR = 5,
    SAJU_MBTI_SINGLE = 6,

    ASTROLOGY_SINGLE = 10,
    ASTROLOGY_COUPLE = 11,
    ASTROLOGY_FRIENDS = 12,

    TAROT = 20,

    CHAT = 30
}

export interface ICharacterConditionMessages {
    need_birthday: string
    need_birthday_2: string
    nonpaying_persona: string
    nonpaying_text: string
    paying_completed: string
}

export interface ICharacterStepMessages {
    text: string
    persona: string
    sayHints: string []
}

export interface ICharacterUnseSteps {
    order: number;
    message: string[];
    [key: number]: string[];
}

export interface ICharacter {
    hashID: string
    type: CharacterType
    name: string
    desc: string
    helloText: string
    imageURL: string
    subImageURL: string
    unseImageURL: string
    unseAdImageURL: string
    featuredImageURL: string
    likeCount: number
    gender: number
    category: number
    categoryName?: string
    visible: number
    chat?: IChat
    tags?: ITag []
    sayHints?: string []
    cashPrice: number
    detailsHTML: string
    freeChatCount: number
    limitOneChat: boolean
    isUnse: boolean
    unseName: string

    owner?: string
    starPoint?: number
    modelID?: string
    persona?: string
    conditionMessages?: ICharacterConditionMessages
    unseSteps?: ICharacterUnseSteps
    stepMessages?: ICharacterStepMessages []

    // Private
    chatCount?: number
    messageCount?: number
}

export interface ICharacterAttachment {
    hashID: string
    imageURL: string
    opened: boolean
    minLevel: number
    cashPrice: number
    createdAt: number
    name: string

    keywords?: string
}

export interface ICategory {
    id: number
    name: string
}

export interface ITag {
    id: number
    name: string
}

export interface IUserCashReceipt {
    type: number
    user?: IUser
    cashMoney: number
    cashMoneyPromotion: number
    targetCharacterHashID: string
    createdAt: number
}

export interface IShopItemModel {
    hashID: string
    name: string
    realPrice: number
    addCashMoney: number
    addCashMoneyPromotion: number
}

export enum Gender {
    FEMALE = 0,
    MALE = 1,
    UNKNOWN = 2,
}

export enum UserCashReceiptType {
    CHAT = 1,
    CHARGE = 2,
    PENDING_CHARGE = 3,
}

export enum PacketType {
    SUBMIT = "1",
    WRITE = "2",
    CHAT_UPDATED = "3",
    START_WRITE = "4",
    LOGIN = "5",
    END_WRITE = "6",

    //
    CALL_START = "7",
    CALL_END = "8",

    //
    TTS_CHUNK = "10",
    TTS_CHUNK_FINAL = "11",
}

export enum SignupType {
    EMAIL = "email",
    GOOGLE = "google",
    NAVER = "naver",
    KAKAO = "kakao",
    RICHGO = "richgo",
    GUEST = "guest",
}

export type LocalizeString = string | {[lang: string]: string};
export function localizeString(str: LocalizeString, lang: string) {
    if (typeof str === "string") {
        return str;
    } else {
        return str[lang] || str["en"];
    }
}
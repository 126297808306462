import React, {useState, useRef, useEffect, useCallback} from 'react'
import {
    Avatar,
    Button,
    ButtonGroup,
    FormControlLabel,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    withStyles,
    Slider,
    Modal,
    AppBar,
    Tabs,
    Tab,
    Paper,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    IconButton,
    Drawer,
} from '@material-ui/core'
import "./ChatScreen.css";
import {saveMessages, setMe} from '../../store/auth';
import {useDispatch, useSelector} from 'react-redux';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Snackbar from '@mui/material/Snackbar';
import {
    CharacterType,
    ChatMessageType,
    ChatState,
    ICharacter,
    IChat,
    IChatMessage,
    IUser,
    IUserAttachment,
    ModelType,
    PacketType
} from '@src/shared/types';
import axios from 'axios';
import {LocalStorageKey, getString, httpGet, httpPost, localeString} from '../../core/utils';
import {useHistory, useParams} from 'react-router-dom';
import {ReducerType} from '../../store';
import ShopModal from '../app/ShopModal';
import ChatFortune from './ChatFortune';
import ChatRankings from './ChatRankings';
import Header from '@src/Header';
import {Menu} from '@material-ui/icons';
import ImageModal from './ImageModal';
import {StatusCode} from '@src/shared/statuscode';
import FavoriteIcon from "@material-ui/icons/Favorite";
import AgreeTermsModal from '../app/AgreeTermsModal';
import ADModal from './ADModal';
import {format} from 'util';
import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import {API_HOST} from '@src/core/commons';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import BirthdayInputDialog from './BirthdayInputDialog';
import {io} from 'socket.io-client';
import prettyMilliseconds from 'pretty-ms';
import FormControl from "@mui/material/FormControl";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import parse from 'html-react-parser';
import {getBase64} from "react-images-uploading/dist/utils";
import ReactDOM from 'react-dom';
import StarRatingComponent from 'react-star-rating-component';

export interface ChatMessageElementProps {
    now: number,
    character: ICharacter | undefined,
    message: IChatMessage,
    isOpponent: boolean,
    userImageURL: string,
    isLast?: boolean,
    onClickImage?: (url: string) => void,
    onShowBirthdayInput?: () => void,
    onBuy?: () => void,
    onShowResult?: () => void,
    charName: string,
    setTarotOpen: React.Dispatch<React.SetStateAction<boolean>>;
    chat: IChat,
    submitMessage?: (message: IChatMessage) => Promise<boolean>;
    addMessage?: (message: IChatMessage) => void;
    loading?: boolean
}

function splitTextByParagraphs(text: string): string[] {
    return text.split(/\n\s*\n/);
}

export function ChatMessageElement(
    {
        now,
        chat,
        character,
        message,
        isOpponent,
        userImageURL,
        isLast,
        onClickImage,
        onShowBirthdayInput,
        onBuy,
        onShowResult,
        charName,
        setTarotOpen,
        submitMessage,
        addMessage,
        loading
    }
        : ChatMessageElementProps) {

    const params: { chatHashID: string } = useParams();
    const history = useHistory();
    const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);

    let payload = {
        chatHashID: params.chatHashID,
        sessionID: sessionID,
        language: navigator.language
    };

    useEffect(() => {
        // "button-fixed" 클래스를 가진 요소를 선택합니다.
        const bottomFixed = document.querySelector('.bottom-fixed') as HTMLElement;
        const chatScreen = document.querySelector('.chatScreen__content') as HTMLElement;

        if (bottomFixed && chatScreen) {
            if (message.text === "") {
                // text가 빈 문자열일 경우, "d-none" 클래스를 추가합니다.
                bottomFixed.classList.add('d-none');
                chatScreen.classList.add('d-none');
            }
        }
    }, [message.text]);  // text 값의 변화에 따라 useEffect를 트리거합니다.

    const fileInputRef = useRef<HTMLInputElement>(null);

    const submitMessageWithImage = async (imageData: string): Promise<boolean> => {
        try {
            // 사용자에게 먼저 이미지 표시
            let localMessage: IChatMessage = {
                type: ChatMessageType.IMAGE,
                state: 0,
                text: imageData,  // placeholder text
                senderHashID: sessionID, // 로컬 표시용
                senderSessionID: sessionID,
                createdAt: new Date().getTime(),
            };

            if (addMessage) {
                addMessage(localMessage);
            }

            // 서버에 이미지 전송
            let m: IChatMessage = {
                type: ChatMessageType.IMAGE,
                state: 0,
                text: imageData,  // placeholder text
                senderHashID: '',
                senderSessionID: sessionID,
                createdAt: new Date().getTime(),
            };

            // base64 데이터를 chat 객체에 추가
            if (chat) {
                chat.base64 = imageData; // 이미지 데이터 저장
            }

            if (submitMessage) {
                const result = await submitMessage(m); // 한 개의 인수로 수정

                if (!result) {
                    console.log("이미지 메시지 전송 실패");
                    return false;
                }

                return true;
            } else {
                console.warn("submitMessage 함수가 전달되지 않았습니다.");
                return false;
            }

        } catch (error) {
            console.error("이미지 처리 중 오류 발생:", error);
            return false;
        }
    };

    var inner = <></>;

    const [isImageUploading, setIsImageUploading] = useState(false);
    const [showFaceAnalyzing, setShowFaceAnalyzing] = useState(false);

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setIsImageUploading(true);
            setShowFaceAnalyzing(true);
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = async () => {
                if (typeof reader.result === 'string') {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = async () => {
                        const MAX_SIZE = 1080;
                        let width = img.width;
                        let height = img.height;

                        if (width > MAX_SIZE || height > MAX_SIZE) {
                            if (width > height) {
                                height *= MAX_SIZE / width;
                                width = MAX_SIZE;
                            } else {
                                width *= MAX_SIZE / height;
                                height = MAX_SIZE;
                            }
                        }

                        const canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;
                        const ctx = canvas.getContext('2d');
                        if (ctx) {
                            ctx.drawImage(img, 0, 0, width, height);
                            const resizedDataUrl = canvas.toDataURL(file.type);
                            await submitMessageWithImage(resizedDataUrl);
                            setIsImageUploading(false);
                            window.location.reload();
                        }
                    };
                }
            };

            reader.readAsDataURL(file);
        }
    };

    console.log("chat : ", chat)

    useEffect(() => {
        setShowFaceAnalyzing(false);
    }, []);

    // 마지막 일 때에만 특수 버튼 표시
    if (isLast) {
        if (message.type == ChatMessageType.INPUT_BIRTHDAY) {
            inner = (
                <button className="chat-btn in-chat" onClick={onShowBirthdayInput}>정보 입력하기 ✍🏻</button>
            );
        } else if (message.type == ChatMessageType.REQUEST_PAYMENT) {
            inner = (
                <button className="chat-btn in-chat please-pay" onClick={onBuy}>
                    네, 좋아요
                    {
                        character?.cashPrice == 0 ?
                            <span style={{display: 'inline-flex', justifyContent: 'center'}}>💎 무료</span>
                            :
                            <span style={{display: 'inline-flex', justifyContent: 'center'}}>💎 {character?.cashPrice.toLocaleString()}</span>
                    }
                </button>
            );
        } else if (message.type == ChatMessageType.SELECTION) {
            inner = (
                <div className="btn-box">
                    {message.selections?.map((selection, index) => (
                        <button key={index} className="chat-btn in-chat" onClick={() => {
                            // const m: IChatMessage = {
                            //     type: ChatMessageType.CHAT,
                            //     state: 0,
                            //     text: selection,
                            //     senderHashID: '',
                            //     senderSessionID: sessionID,
                            //     createdAt: new Date().getTime(),
                            // };
                            // submitMessage(m);
                        }}>{selection}</button>
                    ))}
                </div>
            );
        } else if (message.type == ChatMessageType.PAYMENT_COMPLETED) {
            inner = (
                <>
                    {character?.hashID == 'kp31lr' ?
                        <>
                            <input
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                onChange={handleFileUpload}
                                style={{display: 'none'}}
                            />
                            <button className="chat-btn in-chat" onClick={() => fileInputRef.current?.click()}>
                                이미지 업로드 📷
                            </button>
                        </>
                        :
                        <button className="chat-btn in-chat"
                                style={{display: `${character?.isUnse !== true && character?.hashID !== 'm8kez8' && character?.type !== 20 ? "flex" : "none"}`}}
                                onClick={() => {
                                    localStorage.setItem('read?', "yes");
                                    if (onShowResult) {
                                        onShowResult();
                                    }
                                }}>
                            🎀 보고서 확인하기
                        </button>
                    }
                </>
            );
        }
    }

    const paragraphs = message.text ? splitTextByParagraphs(message.text) : [];

    return (
        <>
            {showFaceAnalyzing && (
                <div className="face-analyzing">
                    관상을 분석중입니다 <br/>
                    잠시만 기다려주세요 <br/><br/>
                    (최대 소요시간 1분)
                    <img src="/loading-circle.gif" alt=""/>
                </div>
            )}
            {!isImageUploading && (
                <>
                    {message.type === ChatMessageType.IMAGE && !isOpponent && (
                        <div className={`chatScreen__message`}>
                            <div className="dialog-row user">
                                <p className="chatScreen__paragraphUser">
                                    <img src={chat?.base64} alt="Uploaded Image" style={{maxWidth: '200px'}}/>
                                </p>
                                <div className="chatScreen__timestamp">
                                    {new Date(message.createdAt).toLocaleTimeString('ko-KR', {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                    {paragraphs.length > 0 ? paragraphs.map((paragraph, index) => (
                        message.type === ChatMessageType.IMAGE || isOpponent ? (
                            <div key={`${message.id}-${index}`}
                                 className={`chatScreen__message ${inner ? "has-inner" : ""}`}>
                                <div className="dialog-character">
                                    <div style={{display: 'flex', alignItems: 'flex-start'}}>
                                        <Avatar className="chatScreen__avatar" src={userImageURL}
                                                style={{marginRight: '8px', width: "30px", height: "30px"}}/>
                                        <p className="chatScreen__paragraph">
                                            <span>{parse(paragraph)}</span>
                                        </p>
                                    </div>
                                    <div className="dialog-row">
                                        <div className="chatScreen__timestamp">
                                            {new Date(message.createdAt).toLocaleTimeString('ko-KR', {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {isLast && index === paragraphs.length - 1 &&
                                    <div className="btn-box">
                                        {inner}
                                    </div>
                                }
                            </div>
                        ) : (
                            <div key={`${message.id}-${index}`} className={"chatScreen__message"}>
                                <div className={`dialog-row user`}>
                                    <p className="chatScreen__paragraphUser">
                                        {
                                            character?.type == 20 && /\b\d{4}-\d{2}-\d{2}\b/g.test(message?.text)
                                                ? '정보 입력 완료'
                                                : paragraph
                                        }
                                    </p>
                                    <div className="chatScreen__timestamp">
                                        {new Date(message.createdAt).toLocaleTimeString('ko-KR', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </div>
                                </div>
                                {isLast && index === paragraphs.length - 1 && (
                                    <>
                                        {inner}
                                        {character?.type == 20 && chat?.state > 5 &&
                                            <div className="btn-box" style={{marginTop: '15px', marginBottom: '0px'}}>
                                                <button className="chat-btn in-chat" id="tarotPick"
                                                        onClick={() => setTarotOpen(true)}>🔮 타로카드 뽑기
                                                </button>
                                            </div>
                                        }
                                    </>
                                )}
                            </div>
                        )
                    )) : (
                        loading &&
                        <div className={`chatScreen__message ${inner ? "has-inner" : ""}`}>
                            <div className="dialog-character">
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Avatar className="chatScreen__avatar" src={userImageURL}
                                            style={{marginRight: '8px', width: "30px", height: "30px"}}/>
                                    <h6>{charName}</h6>
                                </div>
                                <div className="dialog-row">
                                    <p className="chatScreen__paragraph">
                                        <img src={`${character?.type !== 20 ? "/loading.gif" : "/loading-tarot.gif"}`}
                                             width={60} style={{marginTop: '6px'}} alt="플루밍 로딩"/>
                                    </p>
                                    <div className="chatScreen__timestamp">
                                        {new Date(message.createdAt).toLocaleTimeString('ko-KR', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </div>
                                </div>
                            </div>
                            {isLast &&
                                <div className="btn-box">
                                    {inner}
                                </div>
                            }
                        </div>
                    )}
                </>
            )}
        </>
    );
}

enum ChatTab {
    CHATS = "chats",
    FORTUNE = "fortune",
    SHOP = "shop",
}

function base64ToArrayBuffer(base64: string) {
    const binaryData = atob(base64);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < binaryData.length; i++) uint8Array[i] = binaryData.charCodeAt(i);
  
    return { arrayBuffer, length: uint8Array.length };
}
  
function createAudioBuffer(arrayBuffer: ArrayBuffer, length: number) {
    const data = new DataView(arrayBuffer);
  
    const audioBuffer = (window as any).audioContext.createBuffer(1, length / 2, 44100);
    const channelData = audioBuffer.getChannelData(0);
  
    for (let i = 0; i < data.byteLength; i += 2) {
      const sample = data.getInt16(i, true);
      channelData[i / 2] = sample / 32768;
    }
  
    return audioBuffer;
  }

function ChatScreen() {
    const params: { chatHashID: string, type: string } = useParams();
    const history = useHistory();
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    const dispatch = useDispatch();
    const [now, setNow] = useState(new Date().getTime());
    const recognition = useRef(new (window as any).webkitSpeechRecognition()).current;
    const [sendCounter, setSendCounter] = useState(0);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const isFirstRender = useRef(true);

    const [isListening, setIsListening] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    //
    useEffect(() => {
        recognition.continuous = false;
        recognition.interimResults = true;
        recognition.lang = 'ko-KR';

        recognition.onstart = () => {
            setIsListening(true);
        }

        recognition.onend = () => {
            setIsListening(false);
        }

        // 음성 인식 결과 이벤트 핸들러
        recognition.onresult = (event: any) => {
            let interimTranscript = '';
            let finalTranscript = '';

            for (let i = 0; i < event.results.length; i++) {
                const transcript = event.results[i][0].transcript;
                if (event.results[i].isFinal) {
                    finalTranscript += transcript;
                } else {
                    interimTranscript += transcript;
                }
            }

            setInput(finalTranscript || interimTranscript); // 중간 결과나 최종 결과를 input에 반영

            if (finalTranscript) {
                setSendCounter(Date.now());
                stopRecognition();
                recognition.stop();
                initTTSStream();
            }
        };
        recognition.onerror = (event: any) => {
            console.error('Speech recognition error:', event.error);
        };
        
    }, [recognition]);

    const startRecognition = () => {
        recognition.start();
        audioRef.current!.muted = true;
    };

    const stopRecognition = () => {
        recognition.stop();
        audioRef.current!.muted = false;
    };

    const [playbackFailed, setPlaybackFailed] = useState(false);

    function removeEmoji(text: string): string {
        return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    }
    
    const handleManualPlay = () => {
        if (audioRef.current) {  // audioRef.current가 null이 아닌 경우에만 실행
            audioRef.current.play().then(() => {
                setPlaybackFailed(false);  // 수동 재생 성공 시 플래그 초기화
            }).catch((error) => {
                console.error("Error starting playback:", error);
            });
        }
    };

    useEffect(() => {
        handleSend(null);
    }, [sendCounter]);

    //
    const [initialMessages, setInitialMessages] = useState<IChatMessage []>([]);
    const [input, setInput] = useState('');
    const [tab, setTab] = useState(ChatTab.CHATS);
    const scrollCounterRef = useRef(0);
    const refDivDialogBox = useRef<HTMLDivElement>(null);

    //
    const lastMessageRef = useRef<IChatMessage>();
    const messagesRef = useRef<IChatMessage []>([]);
    const [___messages, _setMessages] = useState<IChatMessage []>(messagesRef.current);

    //
    const [tarotOpen, setTarotOpen] = useState(false)

    const [rating, setRating] = useState(0);

    const onStarClick = (nextValue: number) => {
        setRating(nextValue);
    };

    const getMessages = () => messagesRef.current;

    function setMessages(m: IChatMessage []) {
        messagesRef.current = m;
        _setMessages(m);
    }

    //
    const chatStateRef = useRef(ChatState.NONE);
    const [___chatState, _setChatState] = useState(ChatState.NONE);
    const getChatState = () => chatStateRef.current;


    function setChatState(s: ChatState) {
        chatStateRef.current = s;
        _setChatState(s);
    }

    const [loading, _setLoading] = useState(false);
    const loadingRef = useRef(false);
    const setLoading = (b: boolean) => {
        loadingRef.current = b;
        _setLoading(b);
    }

    //
    const refDivMessages = useRef<HTMLElement | null>();
    const [character, setCharacter] = useState<ICharacter>();
    const [chat, setChat] = useState<IChat | null>();
    const refChat = useRef<IChat | null>();
    const [fortunePayload, setFortunePayload] = useState<string>('');

    //
    const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);

    // Modals
    // const [showAgreeTermsModal, setShowAgreeTermsModal] = useState(false);
    const [imageModalURL, setImageModalURL] = useState('');
    const [showMenuDialog, setShowMenuDialog] = useState(false);
    const [showADModal, setShowADModal] = useState(false);
    const [showBirthdayInputDialog, setShowBirthdayInputDialog] = useState(false);

    //
    const [liked, setLiked] = useState(false);
    const socketRef = useRef(io(API_HOST + '/', {
        path: '/api/server/socketio',
        autoConnect: false,
    }));

    // References
    // const refImageUploader = useRef<HTMLInputElement | null>(null);
    const refInput = useRef<HTMLInputElement | null>(null);

    const [callToInfluencer, setCallToInfluencer] = useState(false)

    const joinAudioRef = useRef(new Audio('/callring_shu_1.mp3'));
    const declineAudioRef = useRef(new Audio('/decline.mp3'));

    //
    /*useEffect(() => {
        // 컴포넌트가 렌더링 된 후에 dialogBox의 가장 하단으로 스크롤
        if (refDivDialogBox.current) {
            refDivDialogBox.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    }, [___messages]); // messages 배열이 업데이트 될 때마다 실행*/

    function initTTSStream() {
        const mediaSource = new MediaSource();
        audioRef.current!.src = URL.createObjectURL(mediaSource);
        let bufferQueue: ArrayBuffer[] = [];

        //
        mediaSource.addEventListener('sourceopen', () => {
            if(mediaSource.sourceBuffers.length > 0)
                return;
            const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');

            sourceBuffer.addEventListener('updateend', () => {
                if (bufferQueue.length > 0 && !sourceBuffer.updating) {
                    let buf = bufferQueue.shift()!;
                    if(buf.byteLength == 0) {
                        mediaSource.endOfStream();
                    } else
                        sourceBuffer.appendBuffer(buf);
                }
            });

            //
            socketRef.current.off(PacketType.TTS_CHUNK);
            socketRef.current.on(PacketType.TTS_CHUNK, (data: ArrayBuffer) => {

                const str = new TextDecoder("utf-8").decode(data);
                const response = JSON.parse(str);

                if(response.audio) {
                    const {arrayBuffer, length} = base64ToArrayBuffer(response.audio);
                    if(length > 0) {
                        console.log("Received audio chunk:", length, sourceBuffer.updating);
                        if(bufferQueue.length > 0) {
                            bufferQueue.push(arrayBuffer);
                        } else {
                            try {
                                sourceBuffer.appendBuffer(arrayBuffer);
                            } catch {
                                bufferQueue.push(arrayBuffer);
                            }
                        }
                    }
                }

                if(response.isFinal) {
                    if(sourceBuffer.updating)
                        bufferQueue.push(new ArrayBuffer(0));
                    else if(mediaSource.readyState == 'open')
                        mediaSource.endOfStream();
                }

                audioRef.current!.muted = false;
            });
        });
    }

    useEffect(() => {

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        audioRef.current!.pause();
        joinAudioRef.current.pause();
        declineAudioRef.current.pause();

        if (callToInfluencer) {
            setIsPlaying(true);
            joinAudioRef.current.play().catch(error => console.error("Join audio playback failed:", error));
            declineAudioRef.current.currentTime = 0;

            joinAudioRef.current.onended = () => {
                setIsPlaying(false);
                startRecognition();
                socketRef.current.emit(PacketType.CALL_START);
            };

            //
        } else {
            joinAudioRef.current.currentTime = 0;
            declineAudioRef.current.play().catch(error => console.error("Decline audio playback failed:", error));

            stopRecognition();
            socketRef.current.emit(PacketType.CALL_END);
        }
    }, [callToInfluencer]);

    useEffect(() => {
        // 스크롤을 하단으로 이동하는 함수
        const scrollToBottom = () => {
            const dialogBox = refDivDialogBox.current;
            if (dialogBox) {
                const scrollHeight = dialogBox.scrollHeight;
                const height = dialogBox.clientHeight;
                const maxScrollTop = scrollHeight - height;
                dialogBox.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            }
        };

        // requestAnimationFrame을 사용하여 스크롤 이동 실행
        const animationFrameId = requestAnimationFrame(scrollToBottom);

        // 컴포넌트가 언마운트될 때 requestAnimationFrame 취소
        return () => cancelAnimationFrame(animationFrameId);
    }, [___messages]); // messages 배열이 업데이트 될 때마다 실행

    const handleSend = async (e: any) => {

        e?.preventDefault();
        if (loading) return;

        let text = input.trim();
        setInput("");
        if (!text) return;

        let messageSentSuccessfully;
        messageSentSuccessfully = await submitMessageByText(text);

        if (!messageSentSuccessfully) {
            console.log("메시지 전송 실패, 입력 복원");
            setInput(text);
        } else {
            const chatWrapper = document.getElementById('dialogBox');
            if (chatWrapper) {
                chatWrapper.scrollTop = chatWrapper.scrollHeight;
                setTimeout(() => {
                    chatWrapper.scrollTop = chatWrapper.scrollHeight;
                }, 2000);
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [loading])

    async function submitMessageByText(text: string): Promise<boolean> {

        if (loading)
            return false;

        //
        let m: IChatMessage = {
            type: ChatMessageType.CHAT,
            state: 0,
            text: text,
            senderHashID: '',
            senderSessionID: sessionID,
            createdAt: new Date().getTime(),
        };


        //
        return await submitMessage(m);
    }

    const removeRecommend = (event: React.MouseEvent<HTMLButtonElement>) => {
        const target = event.target as HTMLElement;
        target.classList.add('d-none');
    };

    function addMessage(m: IChatMessage) {
        setMessages([...getMessages(), m]);
    }

    const [tarotSubmit, setTarotSubmit] = useState(false);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const tarotSubmitRef = useRef(tarotSubmit);

    useEffect(() => {
        tarotSubmitRef.current = tarotSubmit;
    }, [tarotSubmit]);

    useEffect(() => {
        if (tarotSubmit === true) {
            const timer = setTimeout(() => {
                setTarotSubmit(false);
            }, 2000);

            // 클린업 함수: 컴포넌트가 언마운트되거나, 타이머가 다시 설정될 때 이전 타이머를 클리어합니다.
            return () => clearTimeout(timer);
        }
    }, [tarotSubmit]);

    async function waitForTarotSubmit(): Promise<void> {
        while (!tarotSubmitRef.current) {
            setIsWaitingForResponse(true); // 응답을 기다리는 상태로 설정
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1000ms 대기 후 다시 확인
        }
        setIsWaitingForResponse(false); // 응답 대기 상태 해제
    }

    function handleStatus(res: any, resolve: (success: boolean) => void) {
        if (res.status == StatusCode.NOT_ENOUGH_CASH_MONEY) {
            console.log("다이아 부족 오류");
            alert('다이아가 부족합니다. 다이아를 충전해주세요.');
            history.push('/shop');
            resolve(false);
        } else if (res.status < 0) {
            console.log("서버 응답 오류:", res);
            alert('Failed status=' + res.status);
            resolve(false);
        } else {
            if (res.error) {
                console.error("서버에서 오류 발생:", res.error);
                alert("이미지 분석 중 오류가 발생했습니다: " + res.error.message);
                resolve(false);
            } else {
                resolve(true);
            }
        }
    }

    async function submitMessage(m: IChatMessage): Promise<boolean> {
        addMessage(m);

        if (character?.type == 20) {
            await waitForTarotSubmit();
        }

        scrollCounterRef.current++;
        setLoading(true);
        try {

            return new Promise((resolve) => {
                socketRef.current.emit(PacketType.SUBMIT, {
                    type: m.type,
                    input: m.text,
                    base64: chat?.base64, // base64 데이터 추가
                }, (res: any) => {
                    handleStatus(res.status, resolve);
                });
            });

        } catch (e) {
            console.error("메시지 전송 중 오류 발생:", e);
            if (e instanceof Error) {
                console.log("오류 메시지:", e.message);
                console.log("오류 스택:", e.stack);
            } else {
                console.log("알 수 없는 오류 유형:", typeof e);
            }
            return false;
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (tab == ChatTab.FORTUNE) {
            window.scrollTo(0, 0);
            const headerElement = document.querySelector('.header') as HTMLElement;
            if (headerElement) {
                headerElement.style.visibility = 'hidden';
            }
        } else if (tab == ChatTab.SHOP) {
            setTab(ChatTab.CHATS);
        } else if (tab == ChatTab.CHATS) {
            window.scrollTo(0, document.body.scrollHeight);
            const headerElement = document.querySelector('.header') as HTMLElement;
            // headerElement가 존재하면 스타일을 변경
            if (headerElement) {
                headerElement.style.visibility = 'visible';
            }
        }
    }, [tab]);

    useEffect(() => {
        let payload = {
            chatHashID: params.chatHashID,
            sessionID: sessionID,
            language: navigator.language
        };

        httpPost('/api/characters/play', payload).then((res) => {
            if (res.status >= StatusCode.SUCCESS) {
                setCharacter(res.character);
                setLiked(res.liked);
                setFortunePayload(res.fortunePayload);
                setInitialMessages(res.messages);
                setChat(res.chat);
                setChatState(res.chat.state);
                refChat.current = res.chat;

                // 여기서 chatUnseStep을 설정합니다.
                if (res.chat.chatUnseStep !== undefined) {
                    setCurrentStep(res.chat.chatUnseStep);
                }

                scrollCounterRef.current++;
                initSocketIO();
            } else {
                history.push('/');
            }
        });

        return () => {
            socketRef.current!.disconnect();
        }
    }, []);

    function initSocketIO() {
        // Init socket.io server
        httpGet('/api/server/socketio').then((res) => {
            let socket = socketRef.current!;
            socket.connect();

            //
            socket.on('connect_error', (error: any) => {
                // window.location.reload();
            });
            socket.on('disconnect', (error: any) => {
                // window.location.reload()
            });
            socket.on('connect', () => {
                const token = window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
                socketRef.current!.emit(PacketType.LOGIN, {
                    chatHashID: params.chatHashID,
                    todayDate: new Date().toLocaleDateString(),
                    sessionID: sessionID,
                    userHashID: me?.hashID,
                    token: token,
                }, (res: any) => {
                    handleStatus(res, (success) => {});
                });
            });

            //
            socket.on(PacketType.WRITE, handleWritePacket);
            socket.on(PacketType.END_WRITE, () => {

                // 미 결제 상태에서는, 결제 요청하기
                setLoading(false);
                if (lastMessageRef.current && getChatState() == ChatState.BEFORE_PAYING) {
                    lastMessageRef.current.type = ChatMessageType.REQUEST_PAYMENT;
                    updateMessage(lastMessageRef.current, false);
                }
                lastMessageRef.current = undefined;
            });
            socket.on(PacketType.START_WRITE, () => {
                const answer: IChatMessage = {
                    id: 0,
                    senderHashID: '',
                    senderSessionID: '',
                    type: ChatMessageType.CHAT,
                    state: 0,
                    text: '',
                    createdAt: new Date().getTime(),
                }
                lastMessageRef.current = answer;

                //
                setLoading(true);
                setMessages([...getMessages(), answer]);

                // 맨 밑으로 스크롤하기
                scrollCounterRef.current++;
            });
            socket.on(PacketType.CHAT_UPDATED, (newChat, me) => {
                dispatch(setMe(me));

                //
                refChat.current!.freeChatCount = newChat.freeChatCount;
                refChat.current = {...refChat.current!};
                setChat(refChat.current);
            });
        });
    }

    useEffect(() => {
        if (getChatState() == ChatState.NONE || !character)
            return;

        // 상담 데이터 있는지 체크하기
        if (getChatState() == ChatState.INPUT) {
            setMessages([...initialMessages, {
                type: ChatMessageType.INPUT_BIRTHDAY,
                state: 0,
                text: localeString(character.conditionMessages?.need_birthday || '') || '생년월일을 알아야 사주를 볼 수 있어!',
                senderHashID: '',
                senderSessionID: '',
                createdAt: new Date().getTime(),
            }]);
        } else if (___chatState == ChatState.INPUT_TWO) {
            setMessages([...initialMessages, {
                type: ChatMessageType.INPUT_BIRTHDAY,
                state: 0,
                text: localeString(character.conditionMessages?.need_birthday_2 || '') || '친구의 생년월일도 알아야 사주를 볼 수 있어!',
                senderHashID: '',
                senderSessionID: '',
                createdAt: new Date().getTime(),
            }]);
        } else if (___chatState == ChatState.PLAY_STEP) {
            let stepData = character.stepMessages![chat!.step];

            //
            setMessages([...initialMessages, {
                type: ChatMessageType.SELECTION,
                state: 0,
                text: stepData.text,
                senderHashID: '',
                senderSessionID: '',
                createdAt: new Date().getTime(),
                step: chat!.step,
                selections: stepData.sayHints,
            }]);
        } else {
            if (getChatState() == ChatState.REQUEST_PAYMENT && initialMessages.length > 0) {
                initialMessages[initialMessages.length - 1].type = ChatMessageType.REQUEST_PAYMENT;
            }
            setMessages([...initialMessages]);
        }

    }, [___chatState]);

    const handleWritePacket = useCallback((messageID: number, text: string) => {
        //
        let m = lastMessageRef.current;
        if (m) {
            m.id = messageID;
            m.text += text;
            updateMessage(m, false);

            //
            scrollCounterRef.current++;
        }
    }, []);

    useEffect(() => {
        // chatWrapper를 HTMLDivElement로 정확히 지정
        const chatWrapper = document.getElementById('chatWrapper') as HTMLDivElement;
        const body = document.documentElement || document.body;

        setTimeout(() => {
            if (chatWrapper) {
                if (window.innerHeight >= chatWrapper.scrollHeight) {
                    window.scrollTo(0, -50);
                } else {
                    body.scrollTo(0, body.scrollHeight);
                }
            }
        }, 1)
    }, [___messages, loading]);

    function clearChat() {

        //
        window.localStorage.removeItem(LocalStorageKey.BIRTHDAY);
        window.localStorage.removeItem(LocalStorageKey.BIRTHDAY_TWO);
        httpPost('/api/characters/clear', {
            chatHashID: chat?.hashID,
        }).then((res) => {
            if (res.status >= StatusCode.SUCCESS) {
                window.location.reload();
            }
        });
    }

    function updateMessage(m: IChatMessage, scrollToBottom: boolean = true) {
        const lst = getMessages();
        var idx = lst.findIndex(x => x.id == m.id);
        if (idx === -1)
            return;

        //
        lst[idx] = {...m};
        setMessages([...lst]);

        //
        if (scrollToBottom) {
            scrollCounterRef.current++;
        }
    }

    // function clickUpload() {
    //     refImageUploader.current!.value = '';
    //     refImageUploader.current!.click();
    // }

    // function onUpload() {
    //     let files = refImageUploader.current?.files;
    //     if(files && files[0]) {
    //         let file = files[0];
    //         httpPost('/api/chats/uploadTemp', {
    //             chatHashID: chat?.hashID,
    //             filename: file.name,
    //         }).then(async (res) => {
    //             if(res.status >= 0) {
    //                 await axios.put(res.uploadURL, file, {
    //                     headers: {
    //                       "Content-Type": "application/octet-stream",
    //                     }
    //                 });

    //                 let m: IChatMessage = {
    //                     type: ChatMessageType.IMAGE,
    //                     state: 0,
    //                     text: res.imageURL,
    //                     senderHashID: '',
    //                     senderSessionID: sessionID,
    //                     createdAt: new Date().getTime(),
    //                 };
    //                 await submitMessage(m);
    //             }
    //         })
    //     }
    // }

    function like() {
        setLiked(!liked);

        httpPost('/api/characters/like', {
            characterHashID: character?.hashID,
            chatHashID: chat?.hashID,
            enable: !liked,
        }).then((res) => {
        });
    }

    function onFocusInput() {
        // let agreed = window.localStorage.getItem(LocalStorageKey.AGREE_CHAT_TERMS);
        // if(!agreed) {
        //     refInput.current!.blur();
        //     setShowAgreeTermsModal(true);
        //     return;
        // }
    }

    // function onAgreeTerms() {
    //     window.localStorage.setItem(LocalStorageKey.AGREE_CHAT_TERMS, 'true');
    //     refInput.current!.focus();
    // }

    useEffect(() => {
        ChannelService.hideChannelButton();

        return () => ChannelService.showChannelButton();
    }, []);

    useEffect(() => {
        // 컴포넌트가 렌더링 된 후에 dialogBox의 가장 하단으로 스크롤
        if (refDivDialogBox.current) {
            refDivDialogBox.current.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
    }, []); // 의존성 배열에 특정 상태를 추가하여 해당 상태가 변경될 때마다 스크롤이 이동하도록 설정할 수 있습니다.

    const [shuffledIndices, setShuffledIndices] = useState<number[]>([]);

    // 힌트 셔플 함수
    const shuffleHints = () => {
        const hints = character?.sayHints;
        if (!hints || hints.length === 0) {
            setShuffledIndices([]);
            return;
        }

        let indices = hints.map((_, index) => index);
        indices.sort(() => Math.random() - 0.5);
        // 상태 업데이트로 인덱스 셔플
        setShuffledIndices(indices);
    };

    // 컴포넌트 마운트 시 1회 힌트 셔플
    useEffect(() => {
        shuffleHints();
    }, [character?.sayHints]); // character.sayHints가 변경될 때마다 셔플

    useEffect(() => {
        const goodkingAppElement = document.getElementById('goodkingApp');
        if (tab === ChatTab.FORTUNE) {
            // tab이 FORTUNE일 때 chat-screen 클래스 제거
            goodkingAppElement?.classList.remove('chat-screen');
        } else {
            // 그렇지 않을 때 chat-screen 클래스 추가
            goodkingAppElement?.classList.add('chat-screen');
        }

        // 컴포넌트가 언마운트 되거나 tab 값이 바뀌기 전에 chat-screen 클래스를 다시 추가
        return () => {
            goodkingAppElement?.classList.remove('chat-screen');
        };
    }, [tab]); // tab 상태를 의존성 배열에 추가


    interface IChatMessageExtended extends IChatMessage {
        isFirstMessageOfDay?: boolean;
    }

    const messagesByDate = ___messages.reduce((acc: {
        [key: string]: IChatMessageExtended[]
    }, message: IChatMessage) => {
        const date = new Date(message.createdAt).toLocaleDateString('ko-KR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long'
        });

        // acc[date] 배열이 존재하지 않으면 먼저 초기화
        if (!acc[date]) {
            acc[date] = [];
        }

        const extendedMessage: IChatMessageExtended = {
            ...message,
            isFirstMessageOfDay: acc[date].length === 0, // 이 시점에서는 acc[date]가 항상 정의되어 있으므로 안전
        };

        acc[date].push(extendedMessage);

        return acc;
    }, {} as { [key: string]: IChatMessageExtended[] });

    const slider: HTMLElement | null = document.querySelector('.chatScreen__recommend'); // 스크롤을 적용할 요소의 클래스나 ID 선택

    if (slider) {
        let isDown: boolean = false;
        let startX: number;
        let scrollLeft: number;

        slider.addEventListener('mousedown', (e: MouseEvent) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });

        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });

        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });

        slider.addEventListener('mousemove', (e: MouseEvent) => {
            if (!isDown) return;
            e.preventDefault();
            const x: number = e.pageX - slider.offsetLeft;
            const walk: number = (x - startX) * 1.5; // 스크롤 속도 조절
            slider.scrollLeft = scrollLeft - walk;
        });
    }

    useEffect(() => {
        // "button-fixed" 클래스를 가진 요소를 선택합니다.
        const bottomFixed = document.querySelector('.bottom-fixed') as HTMLElement;
        const chatScreen = document.querySelector('.chatScreen__content') as HTMLElement;

        if (bottomFixed && chatScreen) {
            if (loading) {
                // text가 빈 문자열일 경우, "d-none" 클래스를 추가합니다.
                bottomFixed.classList.add('d-none');
                chatScreen.classList.add('d-none');
            } else {
                bottomFixed.classList.remove('d-none');
                chatScreen.classList.remove('d-none');
            }
        }
    }, [loading]);  // text 값의 변화에 따라 useEffect를 트리거합니다.

    const [drawerOpen, setDrawerOpen] = useState(false)

    useEffect(() => {
        const input = document.querySelector('.chatScreen__inputField') as HTMLInputElement;

        if (!input) {
            console.error('Input field not found');
            return;
        }

        // 포커스 될 때 실행될 함수
        const handleFocus = () => {
            const content = document.querySelector('.chatScreen__content');
            content?.classList.add('pb-0');
        };

        // 포커스가 해제될 때 실행될 함수
        const handleBlur = () => {
            const content = document.querySelector('.chatScreen__content');
            content?.classList.remove('pb-0');
        };

        // 이벤트 리스너 등록
        input.addEventListener('focus', handleFocus);
        input.addEventListener('blur', handleBlur);

        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            input.removeEventListener('focus', handleFocus);
            input.removeEventListener('blur', handleBlur);
        };
    }, []);

    const isReadPaper = () => {
        localStorage.setItem('read?', "yes");
    }

    const visitedHome = () => {
        history.push('/')
        localStorage.setItem('home?', "yes");
    }

    const floatingAnimation = `@keyframes float {
    0%, 100% { transform: translateY(-2px); }
    50% { transform: translateY(2px); }
    }`;

    const [reviewEvent, setReviewEvent] = useState(true)
    const [writeReview, setWriteReview] = useState(false)

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    function surveyComplete() {
        setReviewEvent(false)
        setWriteReview(false)

        //
        httpPost('/api/users/recommendEvent', {
            rate: selectedRadio ? 1 : 0,
            reason: badReason,
            mobileNumber: phoneNumber
        }).then((res) => {
            if (res.status >= 0) {
                setSnackbarOpen(true); // Snackbar를 열기
                //
                /*setTimeout(() => {
                    window.location.reload();
                }, 200); // 500ms 후에 페이지를 새로 고침*/
            } else {
            }
        });
    }

    const [selectedRadio, setSelectedRadio] = useState(true)

    const [phoneNumber, setPhoneNumber] = useState('')
    const [badReason, setBadReason] = useState('')

    const [privacyAgree, setPrivacyAgree] = useState(true);

    const phoneNumberRegex = /^\d{11}$/;

    const [toastOpen, setToastOpen] = useState(false);
    const prevFreeChatCount = useRef(chat?.freeChatCount);

    const closeToast = () => {
        setToastOpen(false);
    };

    useEffect(() => {
        if (prevFreeChatCount.current === 1 && chat?.freeChatCount === 0) {
            setToastOpen(true);
        }
        prevFreeChatCount.current = chat?.freeChatCount;
    }, [chat?.freeChatCount]);

    useEffect(() => {
        const elements = document.querySelectorAll('#goodkingApp, #myInfo, #chatWrapper');

        if (character?.type == 20) {
            elements.forEach(element => {
                element.classList.add('tarot');
            });

            const observer = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    mutation.addedNodes.forEach(node => {
                        if (node instanceof Element && node.id === 'myInfo') {
                            node.classList.add('tarot');
                        }
                    });
                });
            });

            observer.observe(document.body, {childList: true, subtree: true});

            // Cleanup function
            return () => {
                elements.forEach(element => {
                    element.classList.remove('tarot');
                });

                observer.disconnect();
            };
        }
    }, [character?.hashID]);

    // tarot card image grid

    const tarotImages = new Array(78).fill('/tarot/tarot.svg');

    const [hiddenImages, setHiddenImages] = useState<boolean[]>(new Array(78).fill(false));

    const [selectedCards, setSelectedCards] = useState([null, null, null]);

    useEffect(() => {
        if (!tarotOpen) {
            setSelectedCards([null, null, null]);
            setHiddenImages(tarotImages.map(() => false)); // 타로 이미지를 초기화합니다.
        }
    }, [tarotOpen]);

    const handleTarotClick = (index: number) => {
        const newHiddenImages = [...hiddenImages];
        newHiddenImages[index] = true;
        setHiddenImages(newHiddenImages);

        const newSelectedCards = [...selectedCards];
        const firstEmptyIndex = newSelectedCards.indexOf(null);
        if (firstEmptyIndex !== -1) {
            newSelectedCards[firstEmptyIndex] = tarotImages[index];
            setSelectedCards(newSelectedCards);
        }
    };

    const [userTarotInfo, setUserTarotInfo] = useState(false)

    useEffect(() => {
        if (!selectedCards.includes(null) && chat?.state == 1) {
            setTarotOpen(false)
            setUserTarotInfo(true)
        }
    }, [selectedCards])

    const [richgoInform, setRichgoInform] = useState(false)

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
        if (character?.hashID === 'k8llg8' && document.querySelector('.please-pay') !== null && localStorage.getItem('richgoInform') !== 'yes') {
            timer = setTimeout(() => {
                setRichgoInform(true);
            }, 1500); // 2초 뒤에 실행
        }

        // 컴포넌트가 언마운트될 때 또는 의존성 배열이 변경될 때 타이머를 정리
        return () => clearTimeout(timer);
    }, [___messages])

    const [unseStepText, setUnseStepText] = useState<string[]>([]);

    useEffect(() => {
        if (character?.unseSteps && Array.isArray(character.unseSteps[1])) {
            setUnseStepText(character.unseSteps[1]);
        } else {
            setUnseStepText([]);  // 배열이 아닌 경우 빈 배열로 설정
        }
    }, [character]);

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [buttonSteps, setButtonSteps] = useState<{ [key: number]: string[] }>({});

    useEffect(() => {
        if (chat && character?.isUnse) {
            updateUnseStep(currentStep);
        }
    }, [currentStep, chat, character]);

    const updateUnseStep = async (step: number) => {
        setCurrentStep(step);  // 즉시 로컬 상태 업데이트
        try {
            const response = await httpPost('/api/chats/updateUnseStep', {
                chatHashID: chat?.hashID,
                currentStep: step
            });
        } catch (error) {
        }
    };

    const [isRecommendVisible, setIsRecommendVisible] = useState(true);

    const [isLastStep, setIsLastStep] = useState(false);

    useEffect(() => {
        if (Object.keys(buttonSteps).length == chat?.chatUnseStep) {
            setIsLastStep(true)
        }
    }, [chat?.chatUnseStep])

    const handleUnseButtonClick = (text: string, event: React.MouseEvent<HTMLButtonElement>) => {
        if (!loading) {
            submitMessageByText(text);

            setIsRecommendVisible(false);

            setTimeout(() => {
                const nextStep = currentStep + 1;
                if (buttonSteps[nextStep]) {
                    setCurrentStep(nextStep);
                    setIsRecommendVisible(true);
                } else {
                    // 다음 단계가 없을 때 (현재가 마지막 단계일 때)
                    setIsLastStep(true);
                    removeRecommend(event);
                }
            }, 1000);
        }
    };

    useEffect(() => {
        if (character?.unseSteps) {
            setButtonSteps(character.unseSteps);
            if (chat?.chatUnseStep !== undefined) {
                setCurrentStep(chat.chatUnseStep);
            } else {
                setCurrentStep(1); // 기본값으로 1 설정
            }
        }
    }, [character]);

    useEffect(() => {
        if (chat?.hashID && currentStep !== undefined) {
            httpPost('/api/chats/updateUnseStep', {
                chatHashID: chat.hashID,
                currentStep: currentStep
            }).then((res) => {
                if (res.status === StatusCode.SUCCESS) {

                } else {

                }
            });
        }
    }, [currentStep, chat?.hashID]);

    useEffect(() => {
        if (chat && chat.chatUnseStep !== undefined) {
            setCurrentStep(chat?.chatUnseStep);
        }
    }, []);

    const handleStarSubmit = async () => {
        if (!chat) {
            console.error("handleStarSubmit: chat is undefined");
            alert('채팅 정보를 찾을 수 없습니다.');
            return;
        }

        if (rating === 0) {
            console.error("handleStarSubmit: rating is 0");
            alert('최소 별점은 1점입니다');
            return;
        }

        try {
            console.log("Sending request to update star rate...");
            const response = await httpPost('/api/chats/updateStarRate', {
                chatHashID: chat.hashID,
                starRate: rating
            });

            console.log("Full server response:", response);

            if (response && response.status >= StatusCode.SUCCESS) {
                console.log("Star rating update successful");
                setChat({...chat, starRate: rating});
                alert('별점이 성공적으로 제출되었습니다');
            } else {
                console.error("Star rating update failed. Response:", response);
                alert('별점 제출에 실패했습니다. 다시 시도해주세요.');
            }
        } catch (error) {
            console.error("Exception occurred during star rating submission:", error);
            alert('별점 제출 중 오류가 발생했습니다. 네트워크 연결을 확인해주세요.');
        }
    };

    useEffect(() => {
        if (character?.isUnse && character?.type == 20) {
            const tarotPickElement = document.getElementById('tarotPick');
            const isAnyCardNull = selectedCards.some(card => card === null);

            if (tarotPickElement !== null && isAnyCardNull) {
                setIsRecommendVisible(false);
            } else {
                setIsRecommendVisible(true);
            }
        }
    }, [messagesByDate]);


    const copyUrl = () => {
        navigator.clipboard.writeText("http://fluming.ai/play/npw9gr").then(function () {
            alert("링크가 복사되었습니다✨");
        }, function () {
            alert("Failed to copy text. Please try again.");
        });
    };

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleEnded = () => {
        setIsPlaying(false);
        if(callToInfluencer) {
            startRecognition();
        }
    };

    return (
        <>
            <audio style={{display: 'none'}} ref={audioRef} autoPlay onPlay={handlePlay} onPause={handlePause} onEnded={handleEnded}/>
            <div className="top-fixed">
                <Header backButton={"/"} onRight={like} title=""/>
                <div className="chat-character-box">
                    <div className="chat-character-top" style={{position: 'relative'}}>
                        {/*<div className="chat-character-name" dangerouslySetInnerHTML={{__html: character?.name || ''}}
                             style={{textAlign: "center"}}/>*/}

                        <div style={{visibility: 'hidden'}}/>

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginRight: 'auto'}}>
                            <img src={character?.imageURL || `/flumingLogo.png`} style={{width: '32px', height: '32px', borderRadius: '100px'}} alt=""/>
                            <h6 style={{margin: '0px 6px', fontSize: '17px'}}>슈</h6> <img src="/verify.svg" alt=""/>
                            {
                                chat?.homeAddress && character?.hashID == 'k8llg8' &&
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: '13px',
                                    fontWeight: "400",
                                    color: '#00000073'
                                }}>
                                    <img src="/location.svg" style={{marginRight: '3px'}}
                                         alt="플루밍 위치"/>{chat?.homeAddress}
                                </div>
                            }

                        </div>

                        {___chatState == ChatState.PLAY && character?.isUnse !== true ?
                            <>
                                {
                                    tab == ChatTab.CHATS ?
                                        <button
                                            style={{
                                                visibility: character?.hashID === 'm8kez8' || character?.type == 20 ? "hidden" : "visible",
                                                width: character?.type == 20 ? "0px" : "auto"
                                            }}
                                            onClick={() => {
                                                setTab(ChatTab.FORTUNE);
                                                isReadPaper();
                                            }}
                                        >
                                            보고서
                                        </button>
                                        :
                                        <button onClick={() => setTab(ChatTab.CHATS)}>채팅화면</button>
                                }
                                {
                                    localStorage.getItem('read?') !== 'yes' && character?.type == 20 &&
                                    <div style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                        bottom: '100%',
                                        top: '60px',
                                        right: '13px',
                                        animation: 'float 2s ease-in-out infinite'
                                    }}>
                                        <style>{floatingAnimation}</style>
                                        <div style={{display: 'inline-block', borderBottom: '1px dotted black'}}
                                             className="tooltip">
                                            <span style={{
                                                visibility: 'visible',
                                                padding: '12px 16px',
                                                width: '120px',
                                                backgroundColor: '#63686F',
                                                color: 'white',
                                                textAlign: 'center',
                                                borderRadius: '6px',
                                                opacity: '1',
                                                fontWeight: 'bold'
                                            }} className="tooltiptext">
                                                보고서를 지금 확인해보세요 ✨
                                            </span>
                                        </div>
                                    </div>
                                }
                                {
                                    localStorage.getItem('rid') === 'richgo' && localStorage.getItem('read?') === 'yes' && localStorage.getItem('home?') !== 'yes' && tab == ChatTab.CHATS &&
                                    <div style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                        bottom: '100%',
                                        top: '55px',
                                        right: '23%',
                                        cursor: 'pointer',
                                        animation: 'float 2s ease-in-out infinite'
                                    }} onClick={() => visitedHome()}>
                                        <style>{floatingAnimation}</style>
                                        <div style={{display: 'inline-block', borderBottom: '1px dotted black'}}
                                             className="tooltip">
                                            <span style={{
                                                visibility: 'visible',
                                                padding: '12px 16px',
                                                width: '120px',
                                                backgroundColor: '#63686F',
                                                color: 'white',
                                                textAlign: 'center',
                                                borderRadius: '6px',
                                                opacity: '1',
                                                fontSize: '12px',
                                                fontWeight: 'bold'
                                            }} className="tooltiptext">
                                                여기를 클릭해 더 많은 플루밍들을 만나보세요 ✨
                                            </span>
                                            <span style={{
                                                position: 'absolute',
                                                width: '10px',
                                                height: '10px',
                                                display: 'inline-block',
                                                transform: 'rotate(45deg)',
                                                backgroundColor: '#63686F',
                                                top: '-13px',
                                                left: '44%'
                                            }}/>
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <button style={{visibility: "hidden"}}/>
                        }
                    </div>
                </div>

                {/*<div style={{width: '100%', backgroundColor: 'rgba(255, 246, 244, 0.8)', visibility: tab == ChatTab.CHATS ? 'visible' : 'hidden'}}>
                    <div className="chat-character-image" style={{
                        backgroundImage: `url('${character?.imageURL || '/fluming-bg.png'}')`,
                        backgroundSize: '100%',
                        backgroundPosition: 'center',
                    }}/>

                </div>
*/}
            </div>
            {(chat &&
                <>
                    {tab == ChatTab.CHATS &&
                        <div
                            className={`${___chatState === ChatState.PLAY ? "" : "expand-height"} ${character?.type == 20 ? "tarot" : ""} chatScreen__content`}
                            id="chatWrapper" ref={refDivDialogBox}
                            style={{paddingTop: chat?.homeAddress && character?.hashID === 'k8llg8' ? "80px" : "65px"}}>
                            {/*<div id="chatBox" style={{backgroundImage: "url(" + character?.imageURL + ")", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                        {character?.desc && <p className="chatScreen__center">
                            {character?.desc}
                        </p>}
                        <p className="chatScreen__center">{getString('Chat_Since_F')}{days}</p>
                    </div>*/}
                            <div id="dialogBox">
                                {Object.entries(messagesByDate).map(([date, messagesOfDate]) => (
                                    <React.Fragment key={date}>
                                        {/* 날짜 표시 */}
                                        <div className="date-separator">{date}</div>
                                        {/* 해당 날짜의 메시지들 렌더링 */}
                                        {messagesOfDate.map((message, index) => (
                                            <ChatMessageElement
                                                now={now}
                                                key={message.id || `message-${index}`}
                                                character={character}
                                                chat={chat}
                                                message={message}
                                                isOpponent={!message.senderHashID && !message.senderSessionID}
                                                isLast={messagesOfDate.length - 1 === index}
                                                userImageURL={character?.imageURL || ''}
                                                onClickImage={(url) => setImageModalURL(url)}
                                                onShowBirthdayInput={() => setShowBirthdayInputDialog(true)}
                                                onBuy={() => setDrawerOpen(true)}
                                                onShowResult={() => setTab(ChatTab.FORTUNE)}
                                                charName={character?.name || ''}
                                                setTarotOpen={setTarotOpen}
                                                submitMessage={submitMessage}
                                                addMessage={addMessage}
                                                loading={loading}
                                            />
                                        ))}
                                    </React.Fragment>
                                ))}
                                {loading &&
                                    <p className="chatScreen__desc" style={{margin: '0px 0px 1rem'}}>
                                        슈🩵님이 대답합니다. 잠시만 기다려주세요
                                    </p>
                                }
                            </div>
                        </div>
                    }

                    {tab == ChatTab.FORTUNE &&
                        <div className="chatScreen__content" id="fortunePaper">
                            <ChatFortune
                                chat={chat}
                                character={character}
                                fortunePayload={fortunePayload}
                            />
                        </div>
                    }
                    {tab == ChatTab.CHATS && ___chatState == ChatState.PLAY && !isWaitingForResponse && character?.isUnse !== true &&
                        <div className={`bottom-fixed`}>
                            <div className={`chatScreen__recommend ${character?.type == 20 ? "tarot" : ""}`}>
                                {shuffledIndices.map(index => (
                                    <button
                                        key={index}
                                        className={`chat-btn ${index ? "" : "d-none"}`}
                                        // onClick에서 character.sayHints의 존재 여부를 확인하고 기본값 처리
                                        onClick={(event) => {
                                            if (!loading) {
                                                submitMessageByText(getString(character?.sayHints?.[index] ?? ''));
                                                removeRecommend(event); // 함수 호출
                                            }
                                        }}
                                        style={{}}
                                    >
                                        {getString(character?.sayHints?.[index] ?? '')}
                                    </button>
                                ))}
                                {
                                    /*
                                      <button className="chat-btn" style={{display: 'flex', alignItems: 'center'}} onClick={shuffleHints}>
                                        <img src="/refresh.svg" width={16} height={16}/> 질문항목 새로 추천 받기
                                      </button>
                                    */
                                }
                            </div>

                            <form className="chatScreen__input">
                                <div className="input-box">
                                    <input
                                        value={input}
                                        onChange={e => setInput(e.target.value)}
                                        ref={r => refInput.current = r}
                                        className="chatScreen__inputField"
                                        placeholder={(___chatState == ChatState.PLAY && (
                                            (chat.freeChatCount > 0
                                                && '메세지를 입력하세요'
                                                || '메세지를 입력하세요'
                                            )) || undefined)}
                                        type="text"
                                        onFocus={onFocusInput}
                                        disabled={___chatState != ChatState.PLAY}
                                    />
                                    <button
                                        onClick={handleSend}
                                        className={`send-btn`}
                                        type="submit"
                                        style={{cursor: 'pointer'}}
                                    >
                                        {/*<span className={`free-count`}
                                          style={{minWidth: '42px', maxWidth: '42px', width: '42px',}}>
                                        {chat.freeChatCount === 0 ?
                                            <span style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                paddingTop: '3px',
                                                marginRight: '4px'
                                            }}>
                                                <img src="/gold.svg" width="16" height="16"
                                                     style={{marginTop: '1px', marginRight: '2px'}}
                                                     alt="플루밍 다이아"/>1
                                            </span>
                                            :
                                            "[무료]"
                                        }
                                    </span>*/}
                                        <img src="/arrow_up.svg" alt=""/>
                                    </button>
                                </div>
                                <button type="button" className="voice-btn" onClick={(e) => {setCallToInfluencer(true);}}>
                                    <img src="/callIcon.svg" alt=""/>
                                </button>
                            </form>
                        </div>
                    }

                    <Drawer
                        anchor="bottom"
                        open={callToInfluencer}
                        onClose={() => {
                            setCallToInfluencer(false);
                        }}
                        style={{position: 'relative', boxSizing: 'border-box', borderRadius: '0px'}}
                        id="callToInfluencer"
                    >
                        <div className="cti-container">
                            <div className="cti-image-box">
                                <img
                                    src={character?.imageURL}
                                    style={{animation: isPlaying ? 'character-breathe 1.5s ease-in-out infinite' : 'none'}}
                                />
                            </div>
                            <div className="cti-name">
                                슈🩵 <img src="/verify.svg"/>
                            </div>
                            <p className="cti-state">
                                { isListening ?
                                    '듣는 중 ...' :
                                    isPlaying ?
                                        '말하는 중 ...'
                                        :
                                        loading ?
                                            '생각 중 ...'
                                            :
                                            ''
                                }
                            </p>
                            <div className="cti-bye">
                                {!isListening && !isPlaying && !loading && (<div>
                                    <button
                                        type="button"
                                        className="voice-btn"
                                        style={{animation: input ? 'breathe 1.5s ease-in-out infinite' : 'none'}}
                                        onClick={(e) => {startRecognition(); e.preventDefault();}}
                                    >
                                        <img src="/speak.svg"/>
                                    </button>
                                    <p>탭하여 말하기</p>
                                </div>)}
                                <div>
                                    <img src="/decline.svg" alt="" onClick={() => {setCallToInfluencer(false);}}/>
                                    <p>통화종료</p>
                                </div>
                                {playbackFailed && (
                                    <div>
                                        <p>자동 재생이 차단되었습니다. 아래 버튼을 눌러 수동으로 재생해주세요.</p>
                                        <button onClick={handleManualPlay}>수동으로 재생하기</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Drawer>

                    {
                        character?.isUnse && chat?.state >= 6 && !isLastStep ? (
                            <div className="bottom-fixed unse">
                                <div
                                    className={`chatScreen__recommend ${isRecommendVisible && character?.hashID !== 'kp31lr' ? 'chatScreen__recommend--visible' : character?.hashID == 'kp31lr' && chat?.base64 !== null && chat?.base64 !== '' ? 'chatScreen__recommend--visible' : 'chatScreen__recommend--hidden'} ${character?.type == 20 ? "tarot" : ""}`}
                                    style={{justifyContent: 'center'}}
                                >
                                    {buttonSteps[currentStep]?.map((text, index) => (
                                        <button
                                            key={index}
                                            className="chat-btn"
                                            onClick={(event) => handleUnseButtonClick(text, event)}
                                        >
                                            {text}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            character?.isUnse && chat?.state >= 6 && isLastStep && !loading && (character?.type == 20 ? isRecommendVisible : true) &&
                            <div className="bottom-fixed">
                                {/*{
                                    chat?.starRate == 0 &&
                                    <div className="star-point">
                                        <span>대화가 유익하셨나요?</span>
                                        <StarRatingComponent
                                            name="rate1"
                                            starCount={5}
                                            value={rating}
                                            onStarClick={onStarClick}
                                            emptyStarColor="#cccccc"
                                        />
                                        <button onClick={handleStarSubmit}>별점 제출</button>
                                    </div>
                                }*/}
                                {
                                    chat?.starRate !== 0 &&
                                    <div
                                        className={`chatScreen__recommend ${character?.type == 20 ? "tarot" : ""}`}
                                        style={{justifyContent: 'center'}}
                                    >
                                        <button
                                            className="chat-btn"
                                            style={{marginRight: '0px'}}
                                            onClick={(event) => history.push('/')}
                                        >
                                            {me?.name}님의 인생을 예언할 운세 더보기
                                        </button>
                                    </div>
                                }
                                {
                                    chat?.starRate !== 0 && character?.isUnse && character?.cashPrice == 0 &&
                                    <button className="share-link" onClick={() => copyUrl()}>친구에게 공유하기</button>
                                }
                            </div>
                        )
                    }

                    {
                        chat.freeChatCount === 0 && me && !!me?.recommendEventJoined === false && localStorage.getItem('review?') !== 'reject' &&
                        <Drawer
                            anchor="bottom"
                            open={reviewEvent}
                            onClose={() => setReviewEvent(false)}
                            style={{position: 'relative', boxSizing: 'border-box'}}
                            id="surveyDrawer"
                        >
                            <div>
                                <div className="header" style={{
                                    display: 'flex',
                                    borderBottom: '1px solid #F1F1F1',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    height: '70px',
                                    padding: '0px',
                                    position: 'relative',
                                    zIndex: '1',
                                    background: 'transparent',
                                }}>
                                    <h6 style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        padding: '0px 24px'
                                    }}>
                                        무료채팅 횟수가 모두 차감되었어요!
                                    </h6>
                                </div>
                                <div style={{padding: '24px', boxSizing: 'border-box'}}>
                                    <p style={{marginBottom: '24px', textAlign: 'center', lineHeight: '1.5'}}>
                                        10초만에 끝나는 사용후기 설문에 참여하고, <br/>
                                        <b>추가로 2다이아를 무료로 받으세요!</b>
                                    </p>
                                    <div className="button-box"
                                         style={{display: 'flex', alignItems: 'center', gap: '0px 8px'}}>
                                        <button
                                            style={{
                                                display: 'block',
                                                width: '50%',
                                                height: '42px',
                                                border: 'none',
                                                borderRadius: '8px',
                                                backgroundColor: '#D81A1D',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                color: '#ffffff',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setWriteReview(true);
                                                setReviewEvent(false)
                                            }}
                                        >
                                            네, 받을게요
                                        </button>
                                        <button
                                            style={{
                                                display: 'block',
                                                width: '50%',
                                                height: '42px',
                                                border: 'none',
                                                borderRadius: '8px',
                                                backgroundColor: '#BCC0C4',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                color: '#ffffff',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setReviewEvent(false);
                                                localStorage.setItem('review?', 'reject')
                                            }}
                                        >
                                            괜찮아요
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Drawer>
                    }

                    <Drawer
                        anchor="bottom"
                        open={richgoInform}
                        onClose={() => setRichgoInform(false)}
                        style={{position: 'relative', boxSizing: 'border-box'}}
                        id="surveyDrawer"
                    >
                        <div>
                            <div className="header" style={{
                                display: 'flex',
                                borderBottom: '1px solid #F1F1F1',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '70px',
                                padding: '0px',
                                position: 'relative',
                                zIndex: '1',
                                background: 'transparent',
                            }}>
                                <h6 style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    padding: '0px 24px'
                                }}>
                                    5다이아 사용을 망설이지 마세요!
                                </h6>
                            </div>
                            <div style={{padding: '24px', boxSizing: 'border-box'}}>
                                <p style={{
                                    marginBottom: '24px',
                                    textAlign: 'center',
                                    lineHeight: '1.5',
                                    fontSize: '14px'
                                }}>
                                    <b>첫 사용</b>이시라면, 플루밍을 마음껏 경험해보시라는 의미에서<br/>
                                    <b>무료로 5다이아를 충전해드렸어요!</b>
                                </p>
                                <div className="button-box"
                                     style={{display: 'flex', alignItems: 'center', gap: '0px 8px'}}>
                                    <button
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            height: '42px',
                                            border: 'none',
                                            borderRadius: '8px',
                                            backgroundColor: '#D81A1D',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            color: '#ffffff',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setRichgoInform(false);
                                            localStorage.setItem('richgoInform', 'yes')
                                        }}
                                    >
                                        닫기
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Drawer>

                    {
                        writeReview &&
                        <Drawer
                            anchor="bottom"
                            open={writeReview}
                            onClose={() => setWriteReview(false)}
                            style={{position: 'relative', boxSizing: 'border-box'}}
                            id="surveyDrawer"
                        >
                            <div id="reviewPage">
                                <div className="reviewPage-contents">
                                    <div className="header" style={{borderBottom: '1px solid #F1F1F1'}}>
                                        <div className="left-side" style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            padding: '0px 24px'
                                        }}>
                                            <h6>플루밍 사용 만족도 조사</h6>
                                        </div>
                                    </div>
                                    <img src="/surveyImage.jpeg" width="100%" alt="플루밍 설문"/>
                                    <form style={{padding: '24px', boxSizing: 'border-box'}}>
                                        <div className="form-wrapper">
                                            <div className="input-wrapper" style={{marginBottom: '0px'}}>
                                                <label>플루밍 사용에 만족하시나요?</label>
                                                <FormControl>
                                                    <RadioGroup value={selectedRadio} style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center'
                                                    }}>
                                                        <FormControlLabel className="cash-row" value={true} control={
                                                            <Radio sx={{
                                                                color: '#E5E6E8',
                                                                '&.Mui-checked': {color: '#19DDC5',},
                                                            }}/>} label={
                                                            <>
                                                                <span>네</span>
                                                            </>
                                                        } onClick={() => setSelectedRadio(true)}/>
                                                        <FormControlLabel className="cash-row" value={false} control={
                                                            <Radio sx={{
                                                                color: '#E5E6E8',
                                                                '&.Mui-checked': {color: '#19DDC5',},
                                                            }}/>} label={
                                                            <>
                                                                <span>아니요</span>
                                                            </>
                                                        } onClick={() => setSelectedRadio(false)}/>
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div className="input-wrapper" style={{marginBottom: '16px'}}>
                                                <label>기프티콘 받으실 연락처</label>
                                                <input
                                                    className={`${phoneNumber !== '' && !phoneNumberRegex.test(phoneNumber) ? "error" : ""}`}
                                                    type="text" value={phoneNumber} placeholder="ex) 01012345678"
                                                    onChange={(e) => setPhoneNumber(e.target.value)}/>
                                                {
                                                    phoneNumber !== '' && !phoneNumberRegex.test(phoneNumber) &&
                                                    <p className="error-text">올바르지 않은 휴대폰 번호 형식입니다</p>
                                                }
                                            </div>
                                            {
                                                !selectedRadio &&
                                                <div className="input-wrapper" style={{marginBottom: '16px'}}>
                                                    <label>만족하지 않으시는 이유를 알려주세요</label>
                                                    <input className="line-input"
                                                           type="text"
                                                           value={badReason}
                                                           onChange={(e) => setBadReason(e.target.value)}
                                                           placeholder="ex) 사주 캐릭터가 적어요"
                                                    />
                                                    {/*{
                                        email !== '' && !emailRegex.test(email) &&
                                        <p className="error-text">올바르지 않은 이메일 형식입니다</p>
                                    }*/}
                                                </div>
                                            }

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0px 8px',
                                                    marginBottom: '22px',
                                                    width: '100%',
                                                    height: '48px',
                                                    padding: '12px 16px',
                                                    border: privacyAgree ? '1px solid #19DDC5' : '1px solid #CBCDD1',
                                                    borderRadius: '8px',
                                                    boxSizing: 'border-box',
                                                    fontSize: '16px',
                                                    fontWeight: '400',
                                                    color: privacyAgree ? '#000000' : '#000000D9',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => setPrivacyAgree(!privacyAgree)}
                                            >
                                                <CheckCircleOutline
                                                    style={{color: privacyAgree ? '#19DDC5' : '#BCC0C4'}}/>
                                                개인정보 제공 동의
                                            </div>

                                            <button
                                                type="submit"
                                                className={`signin-btn ${(phoneNumber !== '' && phoneNumberRegex.test(phoneNumber)) && ((selectedRadio === true) || (selectedRadio === false && badReason !== '')) && privacyAgree === true ? "filled" : ""}`}
                                                style={{marginBottom: '0px'}}
                                                disabled={(phoneNumber !== '' && phoneNumberRegex.test(phoneNumber)) && ((selectedRadio === true) || (selectedRadio === false && badReason !== '')) && privacyAgree === true ? false : true}
                                                onClick={() => {
                                                    surveyComplete()
                                                }}
                                            >
                                                제출하기
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Drawer>
                    }


                    {/* <AgreeTermsModal open={showAgreeTermsModal}
                onClose={() => setShowAgreeTermsModal(false)}
                onAgree={onAgreeTerms}/> */}

                    <ImageModal open={!!imageModalURL}
                                onClose={() => setImageModalURL('')}
                                imageURL={imageModalURL}
                    />

                    <ADModal open={showADModal}
                             onClose={() => {
                                 setShowADModal(false);
                                 refInput.current!.focus();
                             }}
                             character={character}
                             sessionID={sessionID}
                    />

                    {
                        drawerOpen &&
                        <Drawer
                            anchor="bottom"
                            open={true}
                            onClose={() => setDrawerOpen(false)}
                        >
                            <div className="character-info chat">
                                <Avatar className="chatScreen__avatar" src={character?.imageURL} style={{
                                    marginRight: '11px',
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: '10px'
                                }}/>
                                <h6 style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    color: '#000000D9'
                                }}>슈🩵</h6>
                            </div>
                            <div className={`${character?.type == 20 ? "tarot" : ""} payment-info`}>
                                <p style={{
                                    marginBottom: '16px',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: '#000000D9'
                                }}>
                                    {character?.isUnse ?
                                        character?.unseName
                                        :
                                        `채팅해제 + 채팅 ${chat?.freeChatCount}회 무료 ${
                                            Number(character?.type) !== 20 && character?.hashID !== 'm8kez8' ? '+ 보고서' : ''
                                        }`}
                                </p>
                                <div style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: '25px'
                                }}>
                                    <div className="price" style={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        color: '#000000D9'
                                    }}>
                                        💎&nbsp;
                                        {character?.cashPrice}
                                    </div>
                                    <span style={{
                                        position: 'relative',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: '#00000073'
                                    }}>

                                        <span style={{marginRight: '3px'}}>보유 다이아</span>
                                        {
                                            me?.cashMoney ?
                                                me?.cashMoney?.toLocaleString()
                                                :
                                                '0'
                                        }
                                        {
                                            ((character?.cashPrice ?? 0) > (me?.cashMoney ?? 0)) &&
                                            <span style={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                color: '#FF3040',
                                                position: 'absolute',
                                                top: '-20px',
                                                right: '0px',
                                                width: '200px',
                                                textAlign: 'right'
                                            }}>보유 다이아가 부족합니다!</span>
                                        }
                                    </span>
                                </div>
                                {
                                    ((character?.cashPrice ?? 0) > (me?.cashMoney ?? 0)) ?
                                        <button
                                            className="fluming-btn"
                                            onClick={() => history.push('/shop')}
                                        >
                                            다이아충전하기
                                        </button>
                                        :
                                        <button
                                            className="fluming-btn"
                                            onClick={() => httpPost('/api/characters/buy?chatHashID=' + chat?.hashID, {}).then((res) => {
                                                if (res.status >= StatusCode.SUCCESS) {
                                                    dispatch(setMe(res.me));
                                                    window.location.reload();
                                                }
                                            })}
                                        >
                                            확인했어요
                                        </button>
                                }
                            </div>
                        </Drawer>
                    }

                    <Dialog onClose={() => setShowMenuDialog(false)} open={showMenuDialog}>
                        <DialogTitle id="simple-dialog-title">{getString('Chat_Menu')}</DialogTitle>
                        <List style={{minWidth: '15em'}}>
                            {/* <ListItem autoFocus button onClick={() => { setShowMenuDialog(false); clickUpload();}}>
                        <ListItemText primary="Upload image" />
                    </ListItem> */}
                            <ListItem autoFocus button onClick={() => {
                                setShowMenuDialog(false);
                                clearChat();
                            }}>
                                <ListItemText primary={getString('Chat_Menu_ClearHistory')}/>
                            </ListItem>
                        </List>
                    </Dialog>
                </>
                ||
                null)
            }

            {
                tarotOpen &&
                <Modal
                    open={tarotOpen}
                    id="selectTarot"
                    onClose={() => setTarotOpen(false)}
                >
                    <div className="tarot-contents">
                        <div className="tarot-contents-header">
                            <img src="/arrowLeftWhite.svg" width={50} onClick={() => setTarotOpen(false)}
                                 alt="플루밍 뒤로가기"/>
                            <p>카드선택</p>
                            <div style={{visibility: 'hidden'}}>empty</div>
                        </div>
                        <div className="tarot-contents-body">
                            <div className="selected-cards">
                                <div className="selected-tarot-card-images">
                                    {selectedCards.map((card, index) => (
                                        <div className="selected-tarot-card" key={index}>
                                            {card ?
                                                <img src="/tarot/tarot.svg" alt={`selected-tarot-${index}`}/>
                                                :
                                                <div>
                                                    <span className="number">{index + 1}</span>
                                                    <div>카드</div>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                <div className="tarot-select-popover">
                                    🔮 마음이 가는 3장의 카드를 골라보세요 ✨
                                    <span className="box-arrow"/>
                                </div>
                            </div>
                            <div className="tarot-cards">
                                {tarotImages.map((src, index) => (
                                    <img
                                        key={index}
                                        src={src}
                                        alt={`tarot-img-${index}`}
                                        className={`tarot-grid-item ${hiddenImages[index] ? 'tarot-hidden' : ''}`}
                                        onClick={() => handleTarotClick(index)}
                                    />
                                ))}
                                <div className="blank" style={{width: '100%', height: '150px'}}/>
                            </div>
                        </div>
                        <Dialog
                            open={chat?.state !== undefined && chat.state > 1 && !selectedCards.includes(null)}
                            onClose={() => setTarotOpen(false)}
                        >
                            <div className="tarot-confirm-modal">
                                <p>이 3장의 카드를 제출할까요?</p>
                                <img src="/tarot_confirm.png" width={182} alt="플루밍 타로"/>
                                <sub>
                                    다시 뽑게 된다면<br/>
                                    이전에 골랐던 카드는 뽑을 수 없어요
                                </sub>
                                <div className="btn-wrapper">
                                    <button onClick={() => setSelectedCards([null, null, null])}>다시뽑기</button>
                                    <button className="pay" onClick={() => {
                                        setTarotOpen(false);
                                        setTarotSubmit(true)
                                    }}>제출하기
                                    </button>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </Modal>
            }

            <BirthdayInputDialog
                show={showBirthdayInputDialog}
                onClose={() => setShowBirthdayInputDialog(false)}
                save={___chatState == ChatState.INPUT}
                nowCharacter={character}
                setTarotOpen={setTarotOpen}
                userTarotInfo={userTarotInfo}
                onConfirm={(data) => {
                    window.localStorage.setItem(___chatState == ChatState.INPUT_TWO ? LocalStorageKey.BIRTHDAY_TWO : LocalStorageKey.BIRTHDAY, JSON.stringify(data));

                    //
                    let birthday = JSON.parse(window.localStorage.getItem(LocalStorageKey.BIRTHDAY) || '{}') || {};
                    let birthday2 = JSON.parse(window.localStorage.getItem(LocalStorageKey.BIRTHDAY_TWO) || '{}') || {};

                    //
                    httpPost('/api/characters/birthday?chatHashID=' + params.chatHashID, {
                        birthday: birthday,
                        birthday2: birthday2
                    }).then((res) => {
                        if (res.status >= StatusCode.SUCCESS) {
                            setChatState(res.chatState);

                            //
                            if (res.chatState == ChatState.PLAY || res.chatState == ChatState.BEFORE_PAYING)
                                window.location.reload();
                        }
                    });
                }}/>

            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={toastOpen}
                autoHideDuration={5000}
                onClose={closeToast}
                ContentProps={{
                    style: {
                        borderRadius: '8px',
                        textAlign: 'center',
                        fontWeight: 400,
                        fontSize: '14px',
                        minWidth: '370px',
                        justifyContent: 'center',
                    }
                }}
                message={
                    <span>
                        무료 채팅 횟수가 모두 소진되었습니다✨<br/>
                        이후 메세지당 1다이아가 소모됩니다.
                    </span>
                }
            />

            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={closeSnackbar}
                ContentProps={{
                    style: {
                        borderRadius: '8px',
                        textAlign: 'center',
                        fontWeight: 400,
                        fontSize: '14px',
                        minWidth: '370px',
                        justifyContent: 'center',
                    }
                }}
                message={
                    <span>
                        제출이 완료되었습니다✨<br/>
                        2다이아를 충전해드렸습니다
                    </span>
                }
            />
        </>
    )
}

export default ChatScreen
import React, {useEffect, useState} from 'react'

function InfoFooter() {

    const [ infoOpen, setInfoOpen ] = useState(false)

    const body = document.documentElement || document.body;

    useEffect(() => {
        body.scrollTo(0, body.scrollHeight);
    }, [!infoOpen])

    return (
        <div className="fluming-footer">
            <div className={`fluming-footer--header ${infoOpen ? "info-open" : ""}`}>
                <span/>{/*<img src="/goodKingTextLogo.svg" width={75} alt="플루밍"/>*/}
                <button onClick={()=> setInfoOpen(!infoOpen)}>사업자 정보 <img className={`${infoOpen ? "info-open" : ""}`} src="/arrowDown.svg" width={16} height={16}/></button>
            </div>
            {
                infoOpen &&
                <div className="fluming-footer--contents">
                    <div className="link-row">
                        <a href="/terms">이용약관</a>
                        <a href="/privacy">개인정보처리방첨</a>
                    </div>
                    <div className="info-row">
                        상호명 : (주) 탱고랜드 | 대표자명 : 박강욱 | 사업자등록번호 : 384-87-03056 <br/>
                        사업장 주소 : 서울시 서초구 사임당로8길 13, 4층 <br/>
                        유선번호 : 010)2205-8464
                    </div>
                    <div className="copy-row">
                        Copyright ⓒ fluming.ai ALL RIGHTS RESERVED
                    </div>
                </div>
            }
            <div className="blank"></div>
        </div>
    )
}

export default InfoFooter
import React from 'react';
import "./Header.css";
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import ForumRounded from '@material-ui/icons/ForumRounded';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link, useHistory } from 'react-router-dom';

interface HeaderProps {
    back?: any
    backIcon?: any
    backButton?: string

    //
    title: string

    //
    right?: any
    rightIcon?: any
    rightButton?: any

    //
    onBack?: () => void
    onRight?: () => void
}

function Header({ back, backButton, backIcon, onBack, right, rightButton, rightIcon, onRight, title }: HeaderProps) {
    const history = useHistory();
    if(!onBack)
        onBack = () => (backButton ? history.push(backButton) : history.goBack());

    return (
        <div className="header">
            <div className="left-side">
                {back || (
                    <IconButton style={{padding:'0px', color: '#4A4E53'}} onClick={onBack}>
                        {backIcon || <img src={`${history.location.pathname.includes(`/play/`) ? "/arrowLeftWhite.svg" : "/arrowLeft.svg"}`} width={50} height={50}/>}
                    </IconButton>
                ) || (
                    <IconButton style={{opacity: 0, padding:'0px'}}>
                        <PersonRoundedIcon fontSize="medium" className="header__icon" />
                    </IconButton>
                )}
                <h6>{title}</h6>
            </div>
            {
                rightButton &&
                <IconButton onClick={() => window.location.href = '/'} style={{padding:'0px'}}>
                    {rightIcon || <img src={`${history.location.pathname.includes(`/play/`) ? "/homeWhite.svg" : "/home.svg"}`} width={42} height={42}/>}
                </IconButton>
            }
        </div>
    )
}

export default Header

 import React, { useEffect, useRef, useState } from 'react'
 import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { AppBar, Box, Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, ImageList, ImageListItem, Paper, Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Toolbar, Typography, makeStyles, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { getString, httpGet, httpPost, httpPostUpload as httpPostFormData } from '@src/core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { ChatState, IChat, IChatMessage, IUser, IUserAttachment } from '../../shared/types';
import { setMe } from '../../store/auth';
import "./AdminChatHistoryScreen.css";
import { ChatMessageElement } from '../chat/ChatScreen';
import Header from '@src/Header';
import prettyMilliseconds from 'pretty-ms';
 import AdminTabs, {AdminTab} from "@src/screens/admin/AdminTabs";

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function AdminChatHistoryScreen() {
  const refMessages = useRef<HTMLElement | null>();
  const [chats, setChats] = useState<IChat []>([]);
  const [chat, setChat] = useState<IChat | null>();
  const [selectedChat, selectChat] = useState<IChat | null>(null);
  const [messages, setMessages] = useState<IChatMessage []>([]);
  const history = useHistory();
  const [now, setNow] = useState(new Date().getTime());
  const [tab, setTab] = useState(AdminTab.HISTORIES);
  const [tarotOpen, setTarotOpen] = useState(false);

  useEffect(() => {
    httpGet('/api/chats/admin').then((res) => {
      setChats(res.chats);
    });
  }, []);
  
  useEffect(() => {
    let elementApp = document.getElementsByClassName('App')[0];
    elementApp.id = 'adminApp';

    return () => {
      elementApp.id = 'goodkingApp';
    }
  }, []);

  function clickChat(chat: IChat) {
    selectChat(chat);
    setMessages([]);

    //
    httpGet('/api/chats/history', {
      chatHashID: chat.hashID,
    }).then((res) => {
      setMessages(res.messages);

      //
      setTimeout(() => {
        var divMessages = refMessages.current;
        divMessages?.scrollTo(0, divMessages!.scrollHeight);
    }, 1);
    });
  }

  function onBack() {
    if(selectedChat)
      selectChat(null);
    else
      history.push('/profile');
  }

  return (
    <>
      <Header backButton="/more" onBack={onBack} title=""/>
      <AdminTabs tab={tab} onChange={(t) => { setTab(t); selectChat(null); }}/>
      <div className="profileChatHistoryScreen">
        {selectedChat == null &&
        <Table aria-label="customized table">
          <colgroup>
            <col width="40%"/>
            <col width="40%"/>
            <col width="20%"/>
          </colgroup>
          <TableHead>
            <TableRow>
              <StyledTableCell>{getString('Username')}</StyledTableCell>
              <StyledTableCell>{getString('Character')}</StyledTableCell>
              <StyledTableCell style={{width: '150px', textAlign: 'right'}}>{getString('Time')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {chats.map(c => 
                <StyledTableRow onClick={() => clickChat(c)} key={c.hashID}>
                  <StyledTableCell>{c.user?.name}{c.user?.signupType && <div>{c.user?.signupType}</div>}</StyledTableCell>
                  <StyledTableCell>{c.character?.name}<br/>{ChatState[c.state]}</StyledTableCell>
                  <StyledTableCell style={{width: '150px', textAlign: 'right'}}>{prettyMilliseconds(now - c.updatedAt, {compact: true})} ago</StyledTableCell>
                </StyledTableRow>
              )}
          </TableBody>
        </Table>}
          <div className="messages" ref={(r) => refMessages.current = r} style={{paddingBottom: '100px'}}>
              {messages.map((message) => (
                  <ChatMessageElement
                      character={undefined}
                      message={message}
                      key={message.id}
                      now={now}
                      charName={selectedChat?.targetUser?.name || ''}
                      isOpponent={!message.senderHashID && !message.senderSessionID}
                      userImageURL={selectedChat?.targetUser?.imageURL || ''}
                      setTarotOpen={setTarotOpen}
                      chat={chat as IChat}
                  />
              ))}
          </div>
      </div>
    </>
  )
}
 
 export default AdminChatHistoryScreen
 
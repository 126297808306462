 import React, { useEffect, useRef, useState } from 'react'
 import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { AppBar, Box, Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, ImageList, ImageListItem, Paper, Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Toolbar, Typography, makeStyles, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { getString, httpGet, httpPost, httpPostUpload as httpPostFormData } from '@src/core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { IChat, IChatMessage, IUser, IUserAttachment, IUserCashReceipt } from '../../shared/types';
import { setMe } from '../../store/auth';
import "./AdminChatHistoryScreen.css";
import { ChatMessageElement } from '../chat/ChatScreen';
import Header from '@src/Header';
import prettyMilliseconds from 'pretty-ms';
 import AdminTabs, {AdminTab} from "@src/screens/admin/AdminTabs";

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function AdminPaymentListScreen() {
  const history = useHistory();
  const [tab, setTab] = useState(AdminTab.PAYMENTS);
  const [receipts, setReceipts] = useState<IUserCashReceipt []>([]);
  const now = new Date().getTime();

  useEffect(() => {
    httpGet('/api/shop/admin').then((res) => {
      setReceipts(res.receipts);
    });
  }, []);
  
  function onBack() {
      history.push('/profile');
  }

  return (
    <>
      <Header backButton="/more" onBack={onBack} title=""/>
      <AdminTabs tab={tab} onChange={(t) => { setTab(t); }}/>
      <div className="profileChatHistoryScreen">
        <Table aria-label="customized table">
          <colgroup>
            <col width="33%"/>
            <col width="33%"/>
            <col width="33%"/>
          </colgroup>
          <TableHead>
            <TableRow>
              <StyledTableCell>{getString('Username')}</StyledTableCell>
              <StyledTableCell>{getString('Amount')}</StyledTableCell>
              <StyledTableCell style={{width: '300px', textAlign: 'right'}}>{getString('Time')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receipts.map(c => {
              return (
                  <StyledTableRow key={c.createdAt}>
                    <StyledTableCell>{c.user?.name}{c.user?.snsID}<br/>{c.user?.signupType && <div>{c.user?.signupType}</div>}</StyledTableCell>
                    <StyledTableCell>{c.cashMoney + c.cashMoneyPromotion}</StyledTableCell>
                    <StyledTableCell style={{width: '150px', textAlign: 'right'}}>
                      {prettyMilliseconds(now - new Date(c.createdAt).getTime(), {compact: true})} ago
                    </StyledTableCell>
                  </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
 
 export default AdminPaymentListScreen
 
import React from 'react';
import { Link } from 'react-router-dom';
import "../../assets/css/app.css";

function Point({name, category, message,timestamp}: {
    name: string,
    category?: string,
    message: string,
    timestamp: string,
}) {

    return (
            <div className="chat point-history-item">
                <div className="point__details" style={{overflow: 'hidden'}}>
                    <div className="top-line">
                        <h6 dangerouslySetInnerHTML={{ __html: name }}/>
                        <div className="category">{category}</div>
                    </div>
                    <div className="middle-line">
                        {message}
                    </div>
                    <div className="bottom-line">
                        <time className="point__timestamp">{timestamp}</time>
                    </div>
                </div>
            </div>
    )
}

export default Point

import { List, ListItem, ListItemIcon, ListItemText, Modal } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { httpPost } from "@src/core/utils";

export function CharacterStartScreen() {
    const params: {
        userName: string,
        characterHashID: string,
    } = useParams();

    useEffect(() => {
      httpPost(`/api/characters/start?charHashID=${params.characterHashID}&create=true`, {}).then((res) => {
        if(res.status >= 0) {
          window.location.href = '/chat/' + res.chat.hashID;
        }
      });
    }, []);


    return (
        <>
        </>
    )
}

export default CharacterStartScreen;
import { API_HOST } from "./commons";
import axios, { AxiosError } from "axios";
import { StatusCode } from '@shared/statuscode';
import Strings from '@shared/strings.json';
import { IBirthday, IUser } from "@src/shared/types";

export enum LocalStorageKey {
    ACCESS_TOKEN = 'at',
    SESSION_ID = 'sid',
    AGREE_CHAT_TERMS = 'act',
    BIRTHDAY = 'bd',
    BIRTHDAY_TWO = 'bd2',
    ME = 'me',
    REFERRAL_ID = 'rid',
    GUEST_TOKEN = 'gt',
}

export function gotoSignin(includeRedirectURL: boolean = false) {

    if(includeRedirectURL) {
        window.location.href = '/signin?redirect=' + encodeURIComponent(window.location.pathname);
    } else {
        window.location.href = '/signin';
    }
}

export async function httpGet(url: string, params = {}, includeRedirectURL: boolean = false, verbose: boolean = true, redirectToSignin: boolean = true) {
    try {
        const token = window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
        const res = await axios.get(API_HOST + url, {
            params: {
                ...params,
                t: Date.now(),
            },
            responseType: 'json',
            headers: {
                'Authorization': 'Basic ' + token,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            }
        });

        if(res.data.status == StatusCode.PLEASE_LOGIN) {
            if(redirectToSignin)
                gotoSignin(includeRedirectURL);
        } else if(verbose && res.data.message) {
            alert(res.data.message)
        } else if(verbose && res.data.status < 0) {
            alert(getString('StatusCode_' + res.data.status, 'Failed. status=' + res.data.status));
        }

        return res.data;
    } catch(e) {
        console.error(e);
        return null;
    }
}

export async function httpPost(url: string, data: any, headers: any = {}, includeRedirectURL: boolean = false, verbose: boolean = true, redirectToSignin: boolean = true) {
    try {
        const token = window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
        const res = await axios.post(API_HOST + url, data, {
            params: {
                t: Date.now(),
            },
            responseType: 'json',
            headers: {
                ...headers,
                'Authorization': 'Basic ' + token,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            }
        });

        if(res.data.status == StatusCode.PLEASE_LOGIN) {
            if(redirectToSignin)
                gotoSignin(includeRedirectURL);
        } else if(verbose && res.data.message) {
            alert(res.data.message)
        } else if(verbose && res.data.status < 0) {
            alert(getString('StatusCode_' + res.data.status, 'Failed. status=' + res.data.status));
        }
        
        return res.data;
    } catch(e) {
        console.error(e);
        if (e instanceof AxiosError) {
            return e.response?.data;
        }
        return null;
    }
}

export async function httpPostUpload(url: string, data: FormData, method: string = "POST") {
    try {
        const token = window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
        const res = await axios.post(API_HOST + url, data, {
            params: {
                t: Date.now(),
            },
            responseType: 'json',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'multipart/form-data',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            method: method,
        });

        if(res.data.status == StatusCode.PLEASE_LOGIN) {
            gotoSignin(false);
        } else if(res.data.message) {
            alert(res.data.message)
        } else if(res.data.status < 0) {
            alert(getString('StatusCode_' + res.data.status, 'Failed. status=' + res.data.status));
        }

        return res.data;
    } catch(e) {
        console.error(e);
        if (e instanceof AxiosError) {
            return e.response?.data;
        }
        return null;
    }
}

export function setAccessToken(token: string | null) {
    if (!token) {
        window.localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
    } else {
        window.localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, token);
    }
}

export function hasAccessToken(): boolean {
    return !!window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
}

const LANGUAGE = navigator.language.toLowerCase();
const DEFAULT_STRINGS = (Strings as any)["ko"] as any;
const MY_STRINGS = (Strings as any)[LANGUAGE] || DEFAULT_STRINGS;

export function getString(key: string, _default: string = ''): string {
    var val = MY_STRINGS[key];
    if(val !== undefined)
        return val;
    return (DEFAULT_STRINGS[key] || _default || key).replace(/\\n/g, '\n');
}

export function localeString(value: string, lang: string = LANGUAGE) {
    try {
      if(value && value.startsWith('{')) {
        let dict = JSON.parse(value);
        return dict[lang] || dict['en'];
      }
    } catch {
    }
  
    return value;
}

function pad2(num: number) {
    return num.toString().padStart(2, '0');
}

export function getStringFromBirthday(birthday: IBirthday) {
    let str = `${birthday.year}-${pad2(birthday.month)}-${pad2(birthday.day)}`;
    if(birthday.sangsi)
        str += ` ${pad2(birthday.hour)}:${pad2(birthday.minute)}`;

    if(birthday.yang)
        str = '양력 ' + str;
    else
        str = '음력 ' + str;

    // if(birthday.birthplace)
    //   str += ' 한국출생';
    // else
    //   str += ' 외국출생';

    if(birthday.sex)
        str += ' 남성';
    else
        str += ' 여성';

    if(birthday.situation)
        str += birthday.situation;

    return str;
}
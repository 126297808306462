 import { LocalStorageKey, httpPost, setAccessToken } from '@src/core/utils';
import { StatusCode } from '@src/shared/statuscode';
import { ReducerType } from '@src/store';
import { setMe } from '@src/store/auth';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
 import { Link, useHistory, useParams } from 'react-router-dom';

function parseUrlFragment(url: string) {
  const hash = url.split('#')[1];
  if (!hash) {
    return {};
  }

  return hash.split('&').reduce((acc: any, part) => {
    const [key, value] = part.split('=');
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});
}


function SigninKakaoScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);

  useEffect(() => {
    let fragment = new URLSearchParams(window.location.search);
    let referralID = window.localStorage.getItem(LocalStorageKey.REFERRAL_ID);
    let redirectUri = `${window.location.protocol}//${window.location.host}/signin/kakao`;
    
    httpPost('/api/users/signin', {
      kakaoCode: fragment.get('code'),
      sessionID,
      referralID,
      redirectUri,
    }).then((res) => {
      if (res.status >= StatusCode.SUCCESS) {

          //
          setAccessToken(res.token);
          dispatch(setMe(res.user));

          //
          let search = new URLSearchParams(window.location.search);
          history.push(search.get('redirect') || '/');
      }
  });
  })

  return (<>
    </>);
 }
 
 export default SigninKakaoScreen
 
import React, { useEffect, useRef, useState } from 'react'
import "./HomeScreen.css";
import {httpGet, httpPost} from '../../core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { ICharacter, IUser, IInfluencer } from '../../shared/types';
import { Link, useParams } from 'react-router-dom';
import AlbumView from "@src/screens/app/AlbumView";
import {Grid, makeStyles, Modal, Drawer} from '@material-ui/core';
import InfoFooter from "@src/screens/app/InfoFooter";
import 'react-circular-progressbar/dist/styles.css';

enum Tab {
    FORTUNE = 'fortune',
    ALL = 'all',
    RECOMMEND = 'recommend',
    FREE = 'free',
}

function HomeScreen() {

    const [selectedInfluencer, setSelectedInfluencer] = useState<IInfluencer | null>(null);
    const params = useParams() as any;
    const influencerName = params.influencerName;

    const [characters, setCharacters] = useState<ICharacter []>([]);
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    const [order, setOrder] = useState('popular');
    const [keyword, setKeyword] = useState('');
    const [tag, setTag] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState('all')
    const dispatch = useDispatch();
    const [showCheckIn, setShowCheckIn] = useState(false)
    const [albumOpen, setAlbumOpen] = useState(false);

    function refresh(reset = true) {
        let _offset = 0;
        if (!reset) {
            _offset = characters.length;
        }

        // 데이터 가져오기 시작 전에 로딩을 true로 설정한다
        setLoading(true);

        httpGet('/api/influencers').then((response) => {
            const influencers: IInfluencer[] = response.influencers;

            const influencer = influencers.find((item: IInfluencer) => item.name === influencerName);

            if (influencer) {
                // user_id가 null일 경우 기본값으로 0을 설정
                const safeInfluencer: IInfluencer = {
                    ...influencer,
                    user_id: influencer.user_id ?? 0  // user_id가 null이면 0으로 설정
                };
                setSelectedInfluencer(safeInfluencer);
            } else {
                console.log('해당 이름의 인플루언서를 찾지 못했습니다.');
            }
        });

        let type = 'all';
        if(tab == Tab.FREE)
            type = 'free';
        else if(tab == Tab.RECOMMEND)
            type = 'recommend';

        httpGet('/api/characters/search', {
            order: order,
            keyword: keyword,
            tag: tag,
            type: type,
            offset: _offset,
        })
            .then((res) => {
                if (res.status >= 0) {
                    if (reset) {
                        setCharacters(res.characters.slice(0, 100)); // Only take the first 20 characters for initial load
                    } else {
                        // Add only up to 20 new characters when fetching additional data
                        const newCharacters = res.characters.slice(0, 100);
                        setCharacters([...characters, ...newCharacters]);
                    }
                }
            })
            .catch((error) => {
                console.error("데이터 가져오기 오류: ", error);
            })
            .finally(() => {
                // Set loading to true as soon as loading is done
                setLoading(true);

                // Set a timeout to change loading to false after 0.5 seconds
                setTimeout(() => {
                    setLoading(false);
                }, 500); // 500 milliseconds = 0.5 seconds
            });
    }

    useEffect(() => {
        refresh();
    }, [order, tag, tab]);

    useEffect(() => {
        window.scrollTo(0, -50);
    })

    useEffect(() => {
        if(me && !me.checkinToday) {
            setShowCheckIn(true);
        } else {
            setShowCheckIn(false);
        }
    }, [me]);

    const slider = document.querySelector('.another-characters') as HTMLElement;

    if (slider) {
        let isDown = false;
        let startX: number;
        let scrollLeft: number;

        const start = (e: MouseEvent | TouchEvent) => {
            isDown = true;
            slider.classList.add('active');
            startX = 'touches' in e ? e.touches[0].pageX : e.pageX;
            scrollLeft = slider.scrollLeft;
        };

        const end = () => {
            isDown = false;
            slider.classList.remove('active');
        };

        const move = (e: MouseEvent | TouchEvent) => {
            if (!isDown) return;
            e.preventDefault();
            const x = 'touches' in e ? e.touches[0].pageX : e.pageX;
            const walk = (x - startX) * 1.5;
            slider.scrollLeft = scrollLeft - walk;
        };

        // 마우스 이벤트
        slider.addEventListener('mousedown', start);
        slider.addEventListener('mouseleave', end);
        slider.addEventListener('mouseup', end);
        slider.addEventListener('mousemove', move);

        // 터치 이벤트
        slider.addEventListener('touchstart', start);
        slider.addEventListener('touchend', end);
        slider.addEventListener('touchcancel', end);
        slider.addEventListener('touchmove', move);
    }

    const [fortuneNumber, setFortuneNumber] = useState<number | null>(null);

    useEffect(() => {
        if (me?.todayFortune) {
            // 정규 표현식을 사용하여 "흉운: "과 "%" 사이의 숫자를 추출
            const match = me.todayFortune.match(/길운:\s*(\d+)%/);
            if (match && match[1]) {
                setFortuneNumber(parseFloat(match[1]));
            }
        }
    }, [me]);

    return (
        <div className="page-contents-wrapper" id="homeScreen">
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '30px 18px 16px', backgroundColor: '#ffffff', boxSizing: 'border-box'}}>
                <img src="/flumingTextLogo.png" alt="" style={{width: '150px', marginBottom: '20px', cursor: 'pointer'}} onClick={() => window.location.href = '/'}/>
                <div className="fluming-main-background">
                    <img src={selectedInfluencer?.mainImageURL || '/flumingLogo.png'}/>
                </div>
                <div className="fluming-main-info">
                    <div className="main-name">{selectedInfluencer?.kor_name}<img src="/verify.svg"/></div>
                    <p className="hello-text">{selectedInfluencer?.follower} Followers</p>
                </div>
                <button className="album-btn" onClick={() => setAlbumOpen(true)}>{selectedInfluencer?.kor_name}의 사진도감 ✨</button>
            </div>
            <div className="character-tabs">
                <div className={`tab ${tab === Tab.ALL ? "select" : ""}`} onClick={() => setTab(Tab.ALL)}>콘텐츠</div>
                <div className={`tab ${tab === Tab.FORTUNE ? "select" : ""}`} onClick={() => setTab(Tab.FORTUNE)}>피드</div>
            </div>
            <div id="characterList">
                {tab !== Tab.FORTUNE && characters
                    .filter(c => c?.visible == 1 && c?.owner === influencerName)
                    .map((c) => (
                        <Grid item key={c.hashID}>
                            {c &&
                                <button
                                    onClick={() => httpPost(`/api/characters/start?charHashID=${c?.hashID}&create=true`, {}, undefined, true).then((res) => {
                                        if(res.status >= 0) {
                                            window.location.href = '/chat/' + res.chat.hashID;
                                        }
                                    })}
                                    style={{display: 'flex', alignItems: 'center', gap: '0px 16px', width: '100%', height: '162px', backgroundColor: '#ffffff', padding: '12px 16px', boxSizing: 'border-box', border: 'none', cursor: 'pointer'}}
                                >
                                    <div style={{position: 'relative', display: 'flex', alignItems: 'flex-end', maxWidth: '126px', minWidth: '126px', width: '126px', height: '126px', padding: '8px', backgroundImage: `url('${c.imageURL}')`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '6.5px', boxSizing: 'border-box'}}>
                                        <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 8px', fontSize: '11px', fontWeight: '400', color: '#ffffff', borderRadius: '4px', backgroundColor: 'rgba(0,0,0,0.95)' }}>채팅무료 <b style={{marginLeft: '2px', fontWeight: '600', color: '#FF5471'}}>{c.freeChatCount}회</b></span>
                                    </div>
                                    <div className="character-info">
                                        <div className="category-title">
                                            <span className="category">{c?.categoryName}</span>
                                            <span className="character-list--item" dangerouslySetInnerHTML={{ __html: c.name }}/>
                                        </div>
                                        <p className="description" style={{textAlign: 'left'}}>{c?.desc}</p>
                                        <div className="bottom-line">
                                            <div className="pricing">
                                                {
                                                    c.cashPrice.toLocaleString() == '0' ?
                                                        <b style={{fontWeight: '600', fontSize: '14px', color: 'rgba(0,0,0,0.85)', marginRight: '3px'}}>무료</b>
                                                        :
                                                        <div>
                                                            <span>💎</span>
                                                            <b style={{fontWeight: '600', fontSize: '14px', color: 'rgba(0,0,0,0.85)', marginRight: '3px'}}> {c.cashPrice.toLocaleString()}</b>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </button>}
                        </Grid>
                    ))
                }
                {tab === Tab.FORTUNE && characters
                    .filter(c => c?.visible == 1 && c?.gender == 10)
                    .map((c) => (
                        <Grid item key={c.hashID}>
                            {c &&
                                <Link to={`/play/${c.hashID}`} style={{display: 'flex', alignItems: 'center', gap: '0px 16px', width: '100%', height: '162px', backgroundColor: '#ffffff', padding: '12px 16px', boxSizing: 'border-box'}}>
                                    <div style={{position: 'relative', display: 'flex', alignItems: 'flex-end', minWidth: '126px', width: '126px', height: '126px', minHeight: '124px', padding: '8px', backgroundImage: `url('${c.unseImageURL}')`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '6.5px', boxSizing: 'border-box'}}>
                                        {/*<span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 8px', fontSize: '11px', fontWeight: '400', color: '#ffffff', borderRadius: '4px', backgroundColor: 'rgba(0,0,0,0.95)' }}>자유채팅 <b style={{marginLeft: '2px', fontWeight: '600', color: '#FF5471'}}>{c.freeChatCount}회</b></span>*/}
                                    </div>
                                    <div className="character-info unse">
                                        <div className="category-title">
                                            <span className="category">{c?.categoryName}</span>
                                            <span className="character-list--item" dangerouslySetInnerHTML={{ __html: c.name }}/>
                                        </div>
                                        {/*<b className="character-list--unse" dangerouslySetInnerHTML={{ __html: c.unseName }}/>*/}
                                        <p className="description">{c?.desc}</p>
                                        <div className="bottom-line">
                                            <div className="pricing">
                                                {
                                                    c.cashPrice.toLocaleString() == '0' ?
                                                        <b style={{fontWeight: '600', fontSize: '14px', color: 'rgba(0,0,0,0.85)', marginRight: '3px'}}>무료</b>
                                                        :
                                                        <div>
                                                            <span>💎</span>
                                                            <b style={{fontWeight: '600', fontSize: '14px', color: 'rgba(0,0,0,0.85)', marginRight: '3px'}}> {c.cashPrice.toLocaleString()}</b>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Link>}
                        </Grid>
                    ))
                }
                <div className="MuiGrid-root MuiGrid-item" style={{width: '100%', height: '165px', borderTop: 'none', boxShadow: 'none'}}>
                    <h1 style={{visibility: 'hidden'}}>플루밍</h1>
                </div>
            </div>
            <InfoFooter/>
            <Drawer
                anchor="bottom"
                open={albumOpen}
                onClose={() => setAlbumOpen(false)}
                id="albumDrawer"
            >
                {albumOpen && selectedInfluencer && (
                    <AlbumView
                        selectedInfluencer={selectedInfluencer}
                        setAlbumOpen={setAlbumOpen}
                    />
                )}
            </Drawer>
        </div>
    )
}

// @ts-ignore
export default HomeScreen
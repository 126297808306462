import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Box, FormControl, FormGroup, FormLabel, TextField } from '@material-ui/core';
import {getString, httpGet, httpPostUpload as httpPostFormData} from '@src/core/utils';
import {IInfluencer} from '../../shared/types';
import "./AdminCharacterScreen.css";

interface AlbumItem {
    contents: string;
    image: File | string | null;
    imagePreview: string;
}

interface AlbumObject {
    [key: string]: AlbumItem;
}

function InfluencerForm({selectedInfluencer}: {
    selectedInfluencer: IInfluencer | null,
}) {
    const [name, setName] = useState('');
    const [korName, setKorName] = useState('');
    const [voiceId, setVoiceId] = useState('');
    const [instagram, setInstagram] = useState('');
    const [youtube, setYoutube] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [twitter, setTwitter] = useState('');
    const imageFileInput = useRef<HTMLInputElement>(null);
    const [albumRows, setAlbumRows] = useState<AlbumObject>({});

    useEffect(() => {
        if (selectedInfluencer && selectedInfluencer.album) {
            const convertedAlbum: AlbumObject = Object.entries(selectedInfluencer.album).reduce((acc, [key, value]) => {
                acc[key.toString()] = {
                    contents: value.contents,
                    image: value.image,  // 기존 URL 유지
                    imagePreview: value.image
                };
                return acc;
            }, {} as AlbumObject);

            setAlbumRows(convertedAlbum);
        } else {
            setAlbumRows({});
        }
    }, [selectedInfluencer]);

    function addAlbumItem() {
        const newId = (Object.keys(albumRows).length + 1).toString();
        setAlbumRows(prev => ({
            ...prev,
            [newId]: { contents: '', image: null, imagePreview: '' }
        }));
    }

    function updateAlbumItem(id: string, field: 'contents' | 'image', value: string | File | null) {
        setAlbumRows(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [field]: value,
                imagePreview: field === 'image' && value instanceof File ? URL.createObjectURL(value) :
                    field === 'image' && typeof value === 'string' ? value :
                        prev[id].imagePreview
            }
        }));
    }

    useEffect(() => {
        if (selectedInfluencer) {
            setName(selectedInfluencer.name);
            setKorName(selectedInfluencer.kor_name);
            setVoiceId(selectedInfluencer.voice_id);
            setInstagram(selectedInfluencer.sns.instagram);
            setYoutube(selectedInfluencer.sns.youtube);
            setTiktok(selectedInfluencer.sns.tiktok);
            setTwitter(selectedInfluencer.sns.twitter);
        }

    }, [selectedInfluencer]);

    function deleteAlbumItem(id: string) {
        setAlbumRows(prev => {
            const newAlbumRows = {...prev};
            delete newAlbumRows[id];
            return newAlbumRows;
        });
    }

    function upload() {
        var form = new FormData();
        if (selectedInfluencer)
            form.set('influencerHashID', selectedInfluencer.hashID);

        form.set('name', name);
        form.set('kor_name', korName);
        form.set('voice_id', voiceId);
        form.set('instagram', instagram);
        form.set('youtube', youtube);
        form.set('tiktok', tiktok);
        form.set('twitter', twitter);

        const albumData = Object.entries(albumRows).map(([key, item]) => ({
            id: key,
            contents: item.contents,
            image: item.image instanceof File ? null : item.image,
        }));

        form.set('album', JSON.stringify(albumData));

        Object.entries(albumRows).forEach(([key, item]) => {
            if (item.image instanceof File) {
                form.append(`albumImages[${key}]`, item.image);
            }
        });

        // 파일이 선택되지 않은 경우도 처리
        if (imageFileInput.current?.files && imageFileInput.current?.files[0]) {
            console.log("Selected file: ", imageFileInput.current.files[0]);
            form.set('image', imageFileInput.current.files[0]);
        } else {
            console.log("No file selected, continuing without image");
        }

        Object.entries(albumRows).forEach(([key, item]) => {
            form.append(`album[${key}][contents]`, item.contents);
            if (item.image instanceof File) {
                form.append(`album[${key}][image]`, item.image);
            } else if (typeof item.image === 'string') {
                form.append(`album[${key}][imageUrl]`, item.image);  // 기존 URL 전송
            }
        });

        httpPostFormData('/api/influencers/add', form)
            .then((res) => {
                console.log('Server response:', res); // 디버깅용
                if (res.status >= 0) {
                    setName('');
                    setKorName('');
                    setVoiceId('');
                    setInstagram('');
                    setYoutube('');
                    setTiktok('');
                    setTwitter('');
                    if (imageFileInput.current) imageFileInput.current.value = '';
                    window.location.href = '/admin/influencers';
                } else {
                    console.error('Server returned error status:', res.status);
                }
            })
            .catch((error) => {
                console.error("Error during form submission:", error);
            });
    }

    return (
        <Box sx={{flexGrow: 1}} textAlign="center">
            <form onSubmit={(e) => {
                e.preventDefault();
            }}
                  style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '0 auto',
                      maxWidth: '100%',
                      borderRadius: '8px',
                      boxShadow: 'none',
                  }}
            >
                <div className="form-wrapper edit-influencer" style={{color: '#000000'}}>
                    <FormGroup>
                        <FormControl component="fieldset" style={{marginBottom: '40px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '20px', fontSize: '13px', textAlign: 'left'}}>{getString('influencer_Cover')}</FormLabel>
                            <img src={selectedInfluencer?.mainImageURL || '/flumingLogo.png'} width="100px" height="100px" style={{marginBottom: '15px'}}/>
                            <input
                                type="file"
                                accept="image/*"
                                ref={imageFileInput}
                            />
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('influencer_Name')}</FormLabel>
                            <TextField type="text" value={name} placeholder={getString('influencer_Name')} onChange={(e: any) => setName(e.target.value)}/>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('influencer_Kor_Name')}</FormLabel>
                            <TextField type="text" value={korName} placeholder={getString('influencer_Kor_Name')} onChange={(e: any) => setKorName(e.target.value)}/>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>Voice ID</FormLabel>
                            <TextField type="text" value={voiceId} placeholder="Voice ID" onChange={(e: any) => setVoiceId(e.target.value)}/>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('instagram_id')}</FormLabel>
                            <TextField type="text" value={instagram} placeholder={getString('instagram_id')} onChange={(e: any) => setInstagram(e.target.value)}/>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('youtube_id')}</FormLabel>
                            <TextField type="text" value={youtube} placeholder={getString('youtube_id')} onChange={(e: any) => setYoutube(e.target.value)}/>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('tiktok_id')}</FormLabel>
                            <TextField type="text" value={tiktok} placeholder={getString('tiktok_id')} onChange={(e: any) => setTiktok(e.target.value)}/>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '30px'}}>
                            <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>{getString('twitter_id')}</FormLabel>
                            <TextField type="text" value={twitter} placeholder={getString('twitter_id')} onChange={(e: any) => setTwitter(e.target.value)}/>
                        </FormControl>
                        <p style={{margin: '10px 0px 10px 0px', textAlign: 'left', fontSize: '13px', color: 'rgba(0, 0, 0, 0.54)'}}>컨텐츠 아이디 / 이미지 입력</p>
                        {Object.entries(albumRows).map(([id, item]) => (
                            <div key={id} style={{display: 'flex', marginBottom: '20px', padding: '10px', border: '1px solid #ddd'}}>
                                <input
                                    value={item.contents}
                                    onChange={(e) => updateAlbumItem(id, 'contents', e.target.value)}
                                    placeholder="Contents"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => updateAlbumItem(id, 'image', e.target.files?.[0] || null)}
                                />
                                {item.imagePreview && (
                                    <img
                                        src={item.imagePreview}
                                        alt="Preview"
                                        style={{width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px'}}
                                    />
                                )}
                                <button type="button" onClick={() => deleteAlbumItem(id)} style={{marginLeft: '10px'}}>
                                    삭제
                                </button>
                            </div>
                        ))}
                        <button type="button" onClick={addAlbumItem}>앨범사진추가</button>
                        {selectedInfluencer &&
                            <button className="fluming-btn" type="button" style={{margin: '10px 0px'}} onClick={upload}>{getString('Save')}</button>
                            ||
                            <button className="fluming-btn" type="button" style={{margin: '10px 0px'}} onClick={upload}>{getString('Add')}</button>
                        }
                    </FormGroup>
                </div>
            </form>
        </Box>
    )
}

function AdminInfluencerScreen() {

    const [selectedInfluencer, setSelectedInfluencer] = useState<IInfluencer | null>(null);
    const params = useParams() as any;
    const influencerHashID = params.influencerHashID;

    function refresh() {
        if (influencerHashID !== 'add') {
            httpGet('/api/influencers', { influencerHashID: influencerHashID }).then((res) => {
                setSelectedInfluencer(res?.influencer || null);
            }).catch((error) => {
                console.error("Error fetching influencer:", error);
                setSelectedInfluencer(null);  // 오류가 발생한 경우에도 null로 설정
            });
        }
    }

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        let elementApp = document.getElementsByClassName('App')[0];
        elementApp.id = 'adminApp';

        return () => {
            elementApp.id = 'goodkingApp';
        }
    }, []);

    return (
        <div className="">
            <Box sx={{ flexGrow: 1 }} style={{width: '50em', margin: '1em auto', maxWidth: '100%'}}>
                <InfluencerForm selectedInfluencer={selectedInfluencer}/>
            </Box>
        </div>
    )
}

export default AdminInfluencerScreen
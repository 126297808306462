import * as React from 'react';

// Define interfaces for props as needed
interface InitLoginButtonProps {
  clientId: string;
  callbackUrl: string;
  render?: any;
  onSuccess: (data: any) => void; // Specify the type of data if known
  onFailure: (error: any) => void;
}

const NAVER_ID_SDK_URL = "https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.0.js";

const isServer = (): boolean => typeof window === "undefined";

// Utilize the defined interface for type safety
const initLoginButton = (props: InitLoginButtonProps): void => {
  if (isServer()) {
    return;
  }
  const { clientId, callbackUrl, onSuccess, onFailure } = props;
  const naver = (window as any)["naver"];
  const naverLogin = new naver.LoginWithNaverId({
    callbackUrl,
    callbackHandle: true,
    clientId,
    isPopup: false,
    loginButton: { color: "green", type: 3, height: 60 },
  });
  naverLogin.init();
  if (!window.opener) {
    naver.successCallback = (data: any) => onSuccess(data);
    naver.failureCallback = onFailure;
  } else {
    naverLogin.getLoginStatus((status: boolean) => {
      if (status) {
        onSuccess(naverLogin.user);
        window.opener.naver.successCallback(naverLogin.user);
      } else {
        window.opener.failureCallback();
      }
      window.close();
    });
  }
};

const appendNaverButton = (): void => {
  if (document && document.querySelectorAll("#naverIdLogin").length === 0) {
    const naverId = document.createElement("div");
    naverId.id = "naverIdLogin";
    naverId.style.position = "absolute";
    naverId.style.top = "-10000px";
    document.body.appendChild(naverId);
  }
};

const loadScript = (props: InitLoginButtonProps): void => {
  if (document && document.querySelectorAll("#naver-login-sdk").length === 0) {
    const script = document.createElement("script");
    script.id = "naver-login-sdk";
    script.src = NAVER_ID_SDK_URL;
    script.onload = () => initLoginButton(props);
    document.head.appendChild(script);
  }
};

// Define component with TypeScript, including props and state types if necessary
class LoginNaver extends React.Component<InitLoginButtonProps> {
  componentDidMount(): void {
    if (isServer()) {
      return;
    }
    appendNaverButton();
    loadScript(this.props);
  }

  render(): React.ReactNode {
    const { render }: any = this.props;
    return render({
      onClick: (e: Event) => {
        e?.preventDefault();
        if (!document || !document.querySelector("#naverIdLogin")?.firstChild) return;
        const naverLoginButton = document.querySelector("#naverIdLogin")!.firstChild as HTMLElement;
        naverLoginButton.click();
      },
    });
  }
}

export default LoginNaver;
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, withStyles } from '@material-ui/core';
import { getString, httpGet, httpPost } from '@src/core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { IInfluencer, IUser } from '../../shared/types';
import AdminTabs, { AdminTab } from './AdminTabs';

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginRight: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    imageList: {
        width: 500,
        height: 450,
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function AdminInfluencerListScreen() {
    const classes = useStyles();
    const history = useHistory();
    const [influencers, setInfluencers] = useState<IInfluencer[]>([]);
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    const dispatch = useDispatch();
    const [tab, setTab] = useState(AdminTab.INFLUENCERS);

    useEffect(() => {
        refreshInfluencers();
    }, []);

    useEffect(() => {
        let elementApp = document.getElementsByClassName('App')[0];
        elementApp.id = 'adminApp';

        return () => {
            elementApp.id = 'goodkingApp';
        }
    }, []);

    function refreshInfluencers() {
        httpGet('/api/influencers').then((res) => {
            setInfluencers(res.influencers || []);  // res 객체에서 influencers 배열을 추출
        });
    }

    function deleteInfluencer(hashID: string) {
        if(window.confirm('Are you sure?')) {
            httpPost('/api/influencers/remove', {
                influencerHashID: hashID
            }).then((res) => res.status >= 0 && refreshInfluencers());
        }
    }

    return (
        <>
            <AdminTabs tab={tab} onChange={(t) => setTab(t)}/>
            <Box sx={{flexGrow: 1, paddingX: '15px'}} className="character-list-page">
                <button className="fluming-btn" style={{ margin: "20px 0px", }} onClick={() => history.push('/admin/influencers/add')}>새 인플루언서 만들기</button>

                <TableContainer component={Paper}>
                    <Table width="100%">
                        <colgroup>
                            <col width="50%"/>
                            <col width="20%"/>
                            <col width="30%"/>
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{color: '#ffffff'}}>이미지</StyledTableCell>
                                <StyledTableCell style={{color: '#ffffff'}}>{getString('CharacterList_Name')}</StyledTableCell>
                                <StyledTableCell style={{color: '#ffffff', textAlign: 'center'}}></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {influencers && influencers?.map((influencer) => (
                                <StyledTableRow key={influencer?.hashID}>
                                    <StyledTableCell>
                                        <img src={influencer?.mainImageURL || '/flumingLogo.png'} width={50} height={50} style={{borderRadius: '100px'}}/>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <div>
                                            {influencer?.kor_name}
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Box style={{display: 'flex', flexDirection: 'column'}}>
                                            <Link style={{display: 'inline-block', width: "100%", marginBottom:'5px'}} to={'/admin/influencers/' + influencer.hashID}>
                                                <button className="fluming-sm-btn">편집</button>
                                            </Link>
                                            <Link to="#" onClick={(e) => deleteInfluencer(influencer.hashID)}>
                                                <button className="fluming-sm-btn red">삭제</button>
                                            </Link>
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default AdminInfluencerListScreen

import React, { useEffect, useState } from 'react'
import "./ChatListScreen.css";
import Chat from "./Chat";
import { Link } from 'react-router-dom';
import { getString, httpGet } from '../../core/utils';
import { IChat, IUser } from '../../shared/types';
import { useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import prettyMilliseconds from 'pretty-ms';
import NotificationsActiveOutlined from "@material-ui/icons/NotificationsActiveOutlined";


function ChatListScreen() {

    const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);
    const [chats, setChats] = useState<IChat []>([]);
    const [now, setNow] = useState(new Date().getTime());

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    useEffect(() => {
        httpGet('/api/chats', {sessionID: sessionID}).then((res) => {
            let chats: IChat [] = res.chats;
            setChats(chats);
        });
        
    }, []);

    return (
        <div className="page-contents-wrapper chat-list-page">
            <div className="chats more chat-list f-container">
                <div style={{padding: '13.5px 16px',}}>
                    <p style={{paddingTop: '15px', fontSize: '18px', fontWeight: '700', color: 'rgba(0,0,0,0.85)', textAlign: 'center'}}>
                        채팅목록
                    </p>
                </div>
                {chats.length !== 0 &&
                    <div className="chat-list-box">
                        {chats.map(g => (
                            <Chat
                                key={g.hashID}
                                name={g.character?.name + ''}
                                category={g.name}
                                message={g.lastMessage}
                                timestamp={prettyMilliseconds(now - g.updatedAt, {compact: true})}
                                profilePic={g.character?.imageURL || ''}
                                link={'/chat/' + g.hashID}
                            />
                        ))}
                    </div>
                }
                {chats.length === 0 && 
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 50px)'}}>
                        <p style={{fontSize: '14px', fontWeight: '500', color: '#7F8495'}}>채팅 내역이 없습니다 😅</p>
                    </div>
                }
            </div>
        </div>
    )
}
 
 export default ChatListScreen
 
 import React, { useEffect, useState } from 'react'
 import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { AppBar, Box, Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Paper, Radio, RadioGroup, TextField, Toolbar, Typography, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { httpGet, httpPost } from '@src/core/utils';
import InfoIcon from '@material-ui/icons/Info';
import { ICharacter, IUser, IUserAttachment } from '../../shared/types';
import { useSelector } from 'react-redux';
import { ReducerType } from '@src/store';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

function ChatCharacterListScreen() {
  const classes = useStyles();
  const history = useHistory();
  const params: any = useParams();
  const [user, setUser] = useState<IUser | null>();
  const [characters, setCharacters] = useState<ICharacter []>([]);
  const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);

  useEffect(() => {
    httpGet('/api/users', {
      snsID: params.userName
    }).then((res) => {
      if(res.status >= 0) {
        setUser(res.user);

        //
        httpGet('/api/characters', {
          userHashID: res.user.hashID,
          sessionID: sessionID,
        }).then((res) => {
          setCharacters(res.characters);
        });
      }
    })
  }, []);

  return (
      <ImageList rowHeight={250} className={classes.imageList}>
        <ImageListItem key="Subheader" cols={2} style={{ height: 'auto', textAlign: 'center' }}>
          <ListSubheader component="div" >{user?.name}의 앨범 리스트</ListSubheader>
        </ImageListItem>
        {characters.map((item) => (
          <ImageListItem key={item.hashID}
          style={{backgroundImage: `url('${item.imageURL}')`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <Link to={`/@${user?.snsID}/${item.hashID}`}>
              <div style={{width: '100%', height: '100%'}}>
              <ImageListItemBar
                title={item.name + (item.chat && " Lv." + item.chat.loveLevel || "")}
                subtitle={"완성도 0%"}
              />
              </div>
            </Link>
          </ImageListItem>
        ))}
      </ImageList>
  );
}
 
 export default ChatCharacterListScreen
 
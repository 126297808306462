import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {
    AppBar,
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
    Toolbar,
    Typography,
    makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {LocalStorageKey, getString, httpGet, httpPost, setAccessToken} from '@src/core/utils';
import {useDispatch, useSelector} from 'react-redux';
import {ReducerType} from '@src/store';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { setMe } from '@src/store/auth';

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginRight: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    profile: {
        backgroundImage: "url('https://img.piku.co.kr/w/uploads/721FCA/3795bd98e8918513062bee2a16da417a.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        width: '50%',
        height: '10em',
    }
}));


function PassWordEdit() {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [step, setStep] = useState(0);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState('');
    const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);
    const searchParams = new URLSearchParams(document.location.search);
    const classes = useStyles();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const dispatch = useDispatch();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
    const nameRegex = /^[가-힣A-Za-z0-9]+$/;

    async function changePassword() {
        setLoading(true);

        let recaptchaToken = await executeRecaptcha!('changePassword');
        httpPost('/api/users/changePassword', {
            password,
            passwordConfirm,
            recaptchaToken
        }).then((res) => {
            if (res.status >= 0) {
                alert('We sent an confirmation link to your email.\nPlease check your email and click the link to activate your account.');
                history.push('/');
            }
        }).finally(() => setLoading(false));

        return false;
    }

    useEffect(() => {
        const p = searchParams.get('payload') || '';
        setPayload(p);
    }, [])

    useEffect(() => {
        if(payload && executeRecaptcha) {
            setLoading(true);

            executeRecaptcha!('changePasswordWithPayload').then((recaptchaToken) => {
                httpPost('/api/users/changePassword', {
                    payload: payload,
                    recaptchaToken,
                }).then((res) => {
                    if (res.status >= 0) {
                        setAccessToken(res.token);

                        httpGet('/api/users/me').then((res) => {
                            if(res && res.status >= 0) {
                                dispatch(setMe(res.user));

                                //
                                history.push('/');
                            }
                        });
                    }
                }).finally(() => setLoading(false));
            });
        }
    }, [payload, executeRecaptcha]);
    ;
    return (
        <article id="emailSignForm">
            <a href="/signin" className="back-btn">뒤로가기</a>
            <form onSubmit={(e) => {e.preventDefault(); changePassword();}}>
                <div className="form-wrapper">
                    {
                        step === 0 &&
                        <>
                            <h6>비밀번호 재설정</h6>
                            <div className="input-wrapper">
                                <label>{getString('Signup_Email')}</label>
                                <input className={`${email !== '' && !emailRegex.test(email) ? "error" : ""}`} type="email" value={email} placeholder="이메일 입력" onChange={(e) => setEmail(e.target.value)} autoComplete="email"/>
                                {
                                    email !== '' && !emailRegex.test(email) &&
                                    <p className="error-text">올바르지 않은 이메일 형식입니다</p>
                                }
                            </div>
                            <button
                                type="submit"
                                className={`signin-btn ${email === '' ? "" : "filled"}`}
                                disabled={email === '' ? true : false}
                                onClick={()=> {changePassword(); setStep(1)}}
                            >
                                확인
                            </button>
                        </>
                    }
                    {
                        step === 1 &&
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <img src="/mail.svg" width={140} height={140} alt="플루밍 메일"/>
                            <h6 style={{margin: '16px 0px'}}>비밀번호 재설정</h6>
                            <p style={{textAlign: 'center', fontSize: '14px', fontWeight: '400', lineHeight: '150%', color: 'rgba(0, 0, 0, 0.65)'}}>
                                비밀번호 재설정을 위한 메일이 전송되었습니다. <br/>
                                메일을 확인해 주세요.
                            </p>
                            <p style={{marginTop: '230px', fontSize: '14px', fontWeight: '500', lineHeight: '150%', color: 'rgba(0, 0, 0, 0.45)'}}>
                                이메일이 오지 않았다면, <a href="" style={{textDecoration: 'underline'}}>여기를 눌러주세요.</a>
                            </p>
                        </div>
                    }
                    {
                        step === 2 &&
                        <>
                            <h6>비밀번호 재설정</h6>
                            <div className="input-wrapper">
                                <label>{getString('Signup_Email')}</label>
                                <input className={`${email !== '' && !emailRegex.test(email) ? "error" : ""}`} type="email" value="example123@sajuking.co.kr" placeholder="example123@sajuking.co.kr" disabled/>
                            </div>
                            <div className="input-wrapper">
                                <label>새 {getString('Signup_Password')}</label>
                                <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="password" placeholder="영문, 숫자, 기호 포함 8~20자"/>
                                {
                                    password !== '' && !passwordRegex.test(password) &&
                                    <p className="error-text">영문, 숫자, 기호 포함 8~20자</p>
                                }
                            </div>
                            <div className="input-wrapper">
                                <label>새 비밀번호 확인</label>
                                <input
                                    type="password"
                                    name="passwordConfirm"
                                    value={passwordConfirm}
                                    onChange={(e) => setPasswordConfirm(e.target.value)}
                                    autoComplete="password"
                                    placeholder="비밀번호 확인"
                                />
                                {
                                    password !== passwordConfirm &&
                                    <p className="error-text">비밀번호가 일치하지 않습니다</p>
                                }
                            </div>
                            <button
                                type="submit"
                                className={`signin-btn ${password === '' || passwordConfirm === '' || password !== passwordConfirm ? "" : "filled"}`}
                                disabled={loading}
                                onClick={() => setStep(3)}
                            >
                                비밀번호 재설정
                            </button>
                        </>
                    }
                    {
                        step === 3 &&
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <img src="/complete.svg" width={140} height={140} style={{marginBottom: '32px'}} alt="플루밍 완료"/>
                            <h6 style={{margin: '16px 0px'}}>비밀번호 재설정 완료</h6>
                            <p style={{textAlign: 'center', fontSize: '14px', fontWeight: '400', lineHeight: '150%', color: 'rgba(0, 0, 0, 0.65)'}}>
                                비밀번호 재설정이 완료되었습니다. <br/>
                                새 비밀번호로 로그인해 주세요.
                            </p>
                            <a
                                href="/signin"
                                className="signin-btn filled"
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '230px'}}
                            >
                                로그인하러 가기
                            </a>
                        </div>
                    }
                </div>
            </form>
        </article>
        /*<Box sx={{flexGrow: 1}} textAlign="center">
            {!payload && <form onSubmit={(e) => {
                e.preventDefault();
                signup();
            }} style={{
                display: 'flex',
                justifyContent: 'center',
                width: '30em',
                margin: '0 auto',
                maxWidth: '100%',
                background: '#fff',
                padding: '60px 0px',
                borderRadius: '8px',
                boxShadow: 'none',
            }}>
                <div className="form-wrapper" style={{width: '360px'}}>
                    <FormGroup className={classes.root} style={{marginBottom: '10px'}}>
                        <FormControl component="fieldset" style={{marginBottom: '15px'}}>
                            <TextField type="email" name="email" value={email} variant="outlined" label={getString('Signup_Email')}
                                       onChange={(e) => setEmail(e.target.value)}  autoComplete="email"/>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '15px'}}>
                            <TextField type="text" name="name" value={name} variant="outlined" label={getString('Signup_Username')}
                                       onChange={(e) => setName(e.target.value)}/>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '15px'}}>
                            <TextField type="password" name="password" value={password} variant="outlined" label={getString('Signup_Password')}
                                       onChange={(e) => setPassword(e.target.value)} autoComplete="password"/>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginBottom: '15px'}}>
                            <TextField type="password" name="passwordConfirm" value={passwordConfirm} variant="outlined" label={getString('Signup_Password_Confirm')}
                                       onChange={(e) => setPasswordConfirm(e.target.value)} autoComplete="password"/>
                        </FormControl>
                    </FormGroup>
                    <div className="btn-box" style={{display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center', marginTop: '2rem', gap: '2rem 0px'}}>
                        <button type="submit" className="signup-btn small" disabled={loading}>{getString('Signup_Button')}</button>
                    </div>
                </div>
            </form>}
        </Box>*/
    )
}

export default PassWordEdit

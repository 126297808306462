import { Tab, Tabs } from "@material-ui/core";
import { getString } from "@src/core/utils";
import { IUser } from "@src/shared/types";
import { ReducerType } from "@src/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export enum AdminTab {
    PROFILE = 'profile',
    INFLUENCERS = 'influencers',
    CHARACTERS = 'characters',
    IMAGES = 'images',
    ATTACHMENTS = 'attachments',
    HISTORIES = 'histories',
    PAYMENTS = 'payments',
    CONNECT = 'connect',
  }
  

interface AdminTabProps {
    tab: string,
    onChange: (tab: AdminTab) => void,
}

function AdminTabs({
    tab,
    onChange,
}: AdminTabProps) {

    const history = useHistory();
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);

    useEffect(() => {
        if(tab == AdminTab.HISTORIES) {
            history.push('/admin/histories');
        } else if(tab == AdminTab.CHARACTERS) {
            history.push('/admin/characters');
        } else if(tab == AdminTab.INFLUENCERS) {
                history.push('/admin/influencers');
        } else if(tab == AdminTab.IMAGES) {
            history.push('/admin/images');
        } else if(tab == AdminTab.PAYMENTS) {
            history.push('/admin/payments');
        } else if(tab == AdminTab.CONNECT) {
            history.push('/admin/connect/richgo');
        }
    }, [tab]);
  
    return (

        <Tabs className="profile-tabs" value={tab} onChange={(e, newValue) => onChange(newValue)} centered>
          {me && me.gmLevel! >= 100 && <Tab style={{width: "90px", minWidth: 'unset'}} label={getString('Tab_Influencers')} value={AdminTab.INFLUENCERS}/>}
          {me && me.gmLevel! >= 100 && <Tab style={{width: "90px", minWidth: 'unset'}} label={getString('Tab_Characters')} value={AdminTab.CHARACTERS}/>}
          {me && me.gmLevel! >= 100 && <Tab style={{width: "90px", minWidth: 'unset'}} label={getString('Tab_Images')} value={AdminTab.IMAGES}/>}
          {me && me.gmLevel! >= 100 && <Tab style={{width: "90px", minWidth: 'unset'}} label={getString('Tab_Histories')} value={AdminTab.HISTORIES}/>}
          {me && me.gmLevel! >= 100 && <Tab style={{width: "90px", minWidth: 'unset'}} label={getString('Payments')} value={AdminTab.PAYMENTS}/>}
          {me && me.gmLevel! >= 100 && <Tab style={{width: "90px", minWidth: 'unset'}} label={getString('Connect')} value={AdminTab.CONNECT}/>}
        </Tabs>
    )
}

export default AdminTabs;
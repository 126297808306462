import React, { useEffect, useState, useMemo } from 'react';
import { IInfluencer } from '../../shared/types';
import { IUserCashReceipt } from '../../shared/types'
import { httpGet } from "@src/core/utils";

interface AlbumViewProps {
    selectedInfluencer: IInfluencer | null;
    setAlbumOpen: (open: boolean) => void;
}

const AlbumView: React.FC<AlbumViewProps> = ({ selectedInfluencer, setAlbumOpen }) => {
    const [receipt, setReceipt] = useState<IUserCashReceipt[]>([]);
    const [albumEntries, setAlbumEntries] = useState<[string, {image: string, contents: string}][]>([]);

    useEffect(() => {
        httpGet('/api/shop/receipts').then((res) => {
            // 날짜를 기준으로 정렬하고 최신 100개만 선택
            const sortedReceipts = res.receipts
                .sort((a: IUserCashReceipt, b: IUserCashReceipt) => {
                    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                })
                .slice(0, 100);
            setReceipt(sortedReceipts);
            console.log("Fetched and sorted receipts:", sortedReceipts);
        });

        if (selectedInfluencer) {
            setAlbumEntries(Object.entries(selectedInfluencer.album || {}));
            console.log("Set album entries:", selectedInfluencer.album);
        }
    }, [selectedInfluencer]);

    const albumItemsWithImages = useMemo(() => {
        console.log("Calculating albumItemsWithImages");
        return albumEntries.map(([key, item]) => {
            console.log(`Processing album item: ${key}`, item);
            // contents가 배열인 경우 첫 번째 요소를 사용
            const contentToMatch = Array.isArray(item.contents) ? item.contents[0] : item.contents;
            const matchingReceipt = receipt.find(r => r.targetCharacterHashID === contentToMatch);
            console.log(`Matching receipt for ${contentToMatch}:`, matchingReceipt);
            const imageSrc = matchingReceipt ? item.image : "/flumingLogo.png";
            console.log(`Selected image source for ${contentToMatch}:`, imageSrc);
            return { key, contents: contentToMatch, imageSrc };
        });
    }, [albumEntries, receipt]);

    console.log("Final albumItemsWithImages:", albumItemsWithImages);

    if (!selectedInfluencer) {
        console.log("No selected influencer, returning null");
        return null;
    }

    return (
        <div className="album-container">
            <div className="album-header">
                <h3>{selectedInfluencer.kor_name}의 사진도감</h3>
                <button onClick={() => setAlbumOpen(false)} type="button">닫기</button>
            </div>
            <div className="album-body">
                {albumItemsWithImages.length === 0 ? (
                    <p>앨범에 사진이 없습니다.</p>
                ) : (
                    <div className="album-grid">
                        {albumItemsWithImages.map(({ key, contents, imageSrc }) => {
                            console.log(`Rendering album item: ${key}`, { contents, imageSrc });
                            return (
                                <div key={key} className="album-item">
                                    <img src={imageSrc} alt={contents} />
                                    {/*<p>{contents}</p>*/}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AlbumView;
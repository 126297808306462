import { httpPost } from "@src/core/utils";
import { setMe } from "@src/store/auth";
import React, {useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";


export function ShopResultModal() {
    const dispatch = useDispatch();
    const history = useHistory();
    const params: {
        result: string
    } = useParams();

    useEffect(() => {
        let query = new URLSearchParams(window.location.search);

        //
        if(params.result == 'success') {

            //
            let data: any = {};
            query.forEach((value, key) => data[key] = value);
            httpPost('/api/shop/payment', data).then((res) => {
                if(res.message)
                    alert(res.message);

                if (res.status >= 0) {
                    dispatch(setMe(res.user));
                }

                history.push('/shop');
            });
        } else {
            history.push('/shop');
        }
    }, []);

    return (
        <>
        </>
    )
}

export default ShopResultModal;
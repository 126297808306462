import { List, ListItem, ListItemIcon, ListItemText, Modal } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useRef, useState } from "react";


function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      maxWidth: '100%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  

export function ImageModal({open, onClose, imageURL}: {
    open: boolean,
    onClose: () => void,
    imageURL: string
}) {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);

    return (
        <Modal
            open={open}
            onClose={onClose}
            >
            <div style={modalStyle} className={classes.paper}>
                <img src={imageURL} width="100%" height="100%"/>
            </div>
        </Modal>
    )
}

export default ImageModal;
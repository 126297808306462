 import React, { useEffect, useState } from 'react'
 import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { AppBar, Box, Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, makeStyles, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { httpGet, httpPost } from '@src/core/utils';
import InfoIcon from '@material-ui/icons/Info';
import { IUser, IUserAttachment, IUserProgress } from '../../shared/types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function RankingScreen() {
  const classes = useStyles();
  const history = useHistory();
  const [rankings, setRankings] = useState<IUser []>([]);

  useEffect(() => {
    httpGet('/api/users/all-rankings').then((res) => {
      setRankings(res.userProgresses);
    })
  })

  return (
    <Table aria-label="customized table">
    <TableHead>
      <TableRow>
        <StyledTableCell>ID</StyledTableCell>
        <StyledTableCell>User name</StyledTableCell>
        <StyledTableCell>Level</StyledTableCell>
        <StyledTableCell>EXP</StyledTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
        {rankings.map((c, i) => 
          <StyledTableRow>
            <StyledTableCell>{i + 1}</StyledTableCell>
            <StyledTableCell>{c.name}</StyledTableCell>
            <StyledTableCell>{1}</StyledTableCell>
            <StyledTableCell>{0}</StyledTableCell>
          </StyledTableRow>
        )}
    </TableBody>
  </Table>
  );
}
 
 export default RankingScreen
 
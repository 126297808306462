 import React, { useEffect, useState } from 'react'
 import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { AppBar, Box, Button, ButtonGroup, FormControl, FormGroup, FormLabel, Grid, IconButton, Paper, TextField, Toolbar, Typography, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { getString, httpPost, setAccessToken } from '@src/core/utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  profile: {
    backgroundImage: "url('https://img.piku.co.kr/w/uploads/721FCA/3795bd98e8918513062bee2a16da417a.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    width: '50%',
    height: '10em',
  }
}));


 function ForgotPasswordScreen() {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [payload, setPayload] = useState('');
    const searchParams = new URLSearchParams(document.location.search);
    const [loading, setLoading] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
      setPayload(searchParams.get('payload') || '');
      setEmail(searchParams.get('email') || '');
    }, []);

    async function execute() {

      let form: any = {};
      if(payload) {
        form.payload = payload;
        form.password = password;
        form.passwordConfirm = confirm;
      } else {
        form.email = email;
      }

      form.recaptchaToken = await executeRecaptcha!('forgotPassword');

      setLoading(true);
      httpPost('/api/users/forgotPassword', form).then((res) => {
        if(res.status >= 0) {
          history.push('/');
        }
      }).finally(() => setLoading(false));

      return false;
    }

    const classes = useStyles();
     return (
      <>
        <Box sx={{ flexGrow: 1 }} textAlign="center">
          <form onSubmit={(e) => {e.preventDefault(); execute();}}
                style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '30em',
                        margin: '0 auto',
                        maxWidth: '100%',
                        background: '#fff',
                        padding: '60px 0px',
                        borderRadius: '8px',
                        boxShadow: 'none'}}
          >
          {!payload && <div className="form-wrapper" style={{width:'360px'}}>
              <FormGroup className={classes.root} style={{width: '30em', maxWidth: '100%'}}>
                  <FormControl component="fieldset">
                      <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>
                          {getString('Signup_Email')}
                      </FormLabel>
                      <TextField type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                  </FormControl>
              </FormGroup>
              <Button variant="contained" color="primary" type="submit"style={{
                  background: '#FF7178',
                  color: '#fff',
                  padding: '10px 15px',
                  border: 'none',
                  borderRadius: '4px',
                  width: '100%',
                  height: '50px',
                  marginTop: '30px',
                  fontWeight: 'bold',
              }}>
                  {getString('ForgotPassword')}
              </Button>
          </div>}
          {payload && <div className="form-wrapper" style={{width:'360px'}}>
              <FormGroup className={classes.root} style={{width: '30em', maxWidth: '100%'}}>
                  <FormControl component="fieldset">
                      <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>
                          {getString('Signup_Password')}
                      </FormLabel>
                      <TextField type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                  </FormControl>
                  <FormControl component="fieldset">
                      <FormLabel component="legend" style={{display: 'block', marginBottom: '5px', fontSize: '13px', textAlign: 'left'}}>
                          {getString('Signup_Password_Confirm')}
                      </FormLabel>
                      <TextField type="password" name="confirm" value={confirm} onChange={(e) => setConfirm(e.target.value)}/>
                  </FormControl>
              </FormGroup>
              <Button variant="contained" color="primary" type="submit" style={{
                  background: '#FF7178',
                  color: '#fff',
                  padding: '10px 15px',
                  border: 'none',
                  borderRadius: '4px',
                  width: '100%',
                  height: '50px',
                  marginTop: '30px',
                  fontWeight: 'bold',
              }} disabled={loading}>
                  {getString('ForgotPassword_Change')}
              </Button>
          </div>}
          </form>
        </Box>
      </>
     )
 }
 
 export default ForgotPasswordScreen
 
import React, { useEffect, useState } from 'react'
import "./MoreScreen.css";
import { Link } from 'react-router-dom';
import { getString, httpGet } from '../../core/utils';
import { ICategory, IChat, IUser } from '../../shared/types';

function MoreItem({title, subtitle, link, onClick}: {
    title: string
    subtitle: string
    link: string
    onClick?: (e: any) => void
}) {

    return (
        <Link to={link} onClick={onClick}>
            <div className="chat">
                <div className="chat__details" style={{overflow: 'hidden'}}>
                    <h2>{title}</h2>
                    <p style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{subtitle}</p>
                </div>
            </div>
        </Link>
    )
}

function CategoryScreen() {

    let [categories, setCategories] = useState<ICategory []>([]);

    httpGet('/api/categories').then((res) => {
        setCategories(res.categories);
    })

    return (
        <div className="chats f-container">
            <MoreItem 
            title={getString('Filter_All')}
            subtitle={""}
            link={"/"}
            />
            {categories.map(x => 
                <MoreItem 
                title={getString('Category_' + x.id, x.name)}
                subtitle={""}
                link={"/?category=" + x.id}
                />
            )}
        </div>
    )
}
 
 export default CategoryScreen
 
import React, {useEffect, useRef, useState, RefObject, Fragment} from 'react'
import "./HomeScreen.css";
import { httpGet } from '../../core/utils';
import { useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { ICharacter, ITag, IUser } from '../../shared/types';
import { Link, useHistory } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Grid, makeStyles } from '@material-ui/core';
import ForumRounded from '@material-ui/icons/ForumRounded';
import NotificationsActiveOutlined from '@material-ui/icons/NotificationsActiveOutlined';
import HomeIcon from '@mui/icons-material/GiteRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { throttle } from 'lodash';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import ShopModal from '../app/ShopModal';


const useStyles = makeStyles((theme) => ({
    imageList: {
      width: '100%',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
}));

function useOutsideClick(ref: RefObject<HTMLElement>, callback: () => void) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}

function Ting() {
    const history = useHistory();
    const [characters, setCharacters] = useState<ICharacter []>([]);
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    const [order, setOrder] = useState('popular');
    const [keyword, setKeyword] = useState('');
    let [tags, setTags] = useState<ITag []>([]);
    let [tag, setTag] = useState(0);
    let [loading, setLoading] = useState(false);
    let [chatCount, setChatCount] = useState(0);
    const refCardContainer = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);

    function refresh(reset = true) {
        let _offset = 0;
        if (!reset) {
            _offset = characters.length;
        }

        // 데이터 가져오기 시작 전에 로딩을 true로 설정한다
        setLoading(true);

        // 현재 스크롤 위치를 저장
        const currentScrollPosition = refCardContainer.current ? refCardContainer.current.scrollTop : 0;

        httpGet('/api/characters/search', { order: order, keyword: keyword, tag: tag, offset: _offset })
            .then((res) => {
                if (res.status >= 0) {
                    if (reset) {
                        setCharacters(res.characters.slice(0, 20)); // Only take the first 20 characters for initial load
                    } else {
                        // Add only up to 20 new characters when fetching additional data
                        const newCharacters = res.characters.slice(0, 20);
                        setCharacters([...characters, ...newCharacters]);
                        if (refCardContainer.current) {
                            refCardContainer.current.scrollTop = currentScrollPosition;
                        }
                    }
                }
            })
            .catch((error) => {
                console.error("데이터 가져오기 오류: ", error);
            })
            .finally(() => {
                // Set loading to true as soon as loading is done
                setLoading(true);

                // Set a timeout to change loading to false after 0.5 seconds
                setTimeout(() => {
                    setLoading(false);
                }, 500); // 500 milliseconds = 0.5 seconds
            });
    }


    useEffect(() => {
        refresh();

        httpGet('/api/tags').then((res) => {
            setTags(res.tags);
        });
    }, []);

    useEffect(() => {
        const element = refCardContainer.current;
        if (element) {
            element.addEventListener('scroll', checkScrollEnd);

            return () => {
                element.removeEventListener('scroll', checkScrollEnd);
            };
        }
    }, [refCardContainer.current]);

    useEffect(() => {
        refresh();
    }, [order, tag]);

    useEffect(() => {
        refresh();
    }, [order]);

    useEffect(() => {
        refresh();
    }, [tag]);

    function search() {
        refresh();
    }

    const [buttonOpen, setButtonOpen] = useState(false);
    const ref = useRef(null);

    useOutsideClick(ref, () => setButtonOpen(false));

    function checkScrollEnd() {
        // if (refCardContainer.current) {
        //     const { scrollTop, scrollHeight, clientHeight } = refCardContainer.current;
        //     if (scrollTop + clientHeight >= scrollHeight - 10) {
        //         refresh(false);
        //     }
        // }
    }

    useEffect(() => {
        const element = refCardContainer.current;
        if (element) {
            const throttledCheckScroll = throttle(checkScrollEnd, 100); // 100ms 간격으로 제한
            element.addEventListener('scroll', throttledCheckScroll);

            return () => {
                element.removeEventListener('scroll', throttledCheckScroll);
            };
        }
    }, [refCardContainer.current, loading]);

    const [lockState, islockState] = useState(true)
    const [refreshState, isRefreshState] = useState(true)

    return (
        <div className="page-contents-wrapper" id="homeScreen">

            {/*<Header backButton="/profile"
                    backIcon={<PersonIcon fontSize="medium" className="header__icon" style={{padding:'0px'}}/>
            }
            right={
                <IconButton onClick={() => history.push("/chats")} style={{padding:'0px'}}>
                    <div className="header__icon">
                        <Badge badgeContent={chatCount} color="primary">
                            <ForumIcon fontSize="medium"/>
                        </Badge>
                    </div>
                </IconButton>
            }/>*/}
            <div className="tinderCards__cardContainer">
                {/*<div className="header-tool-box">
                    <div/>
                    <div className="right-icon"><NotificationsActiveOutlined onClick={() => history.push('/notification')}/></div>
                </div>*/}
                <div style={{display: 'flex', alignItems:'center', justifyContent: 'flex-end', gap: '0px 10px', marginTop: '2rem', marginBottom: '2rem', color: '#000000'}}>
                    <ForumRoundedIcon style={{fontSize: '27px',paddingTop: '5px', cursor: 'pointer'}} onClick={() => history.push('/chats')}/>
                    <PersonOutlineOutlinedIcon style={{fontSize: '27px',paddingTop: '5px', cursor: 'pointer'}} onClick={() => history.push('/more')}/>
                </div>
                <h4 style={{display:'flex', alignItems:'center', justifyContent: 'space-between', fontSize: '29px'}}>
                    <span style={{display: 'flex', alignItems: 'center', fontSize: '25px', color: '#000000'}}>궁합의 신에게 온 것을 환영하네!<button onClick={() => islockState(!lockState)}>-</button></span>
                </h4>


                {
                    lockState &&
                    <div style={{ position: 'fixed', backgroundColor: '#000000', padding: '20px', borderRadius: '10px', textAlign: 'center', zIndex: '100', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                        <p>자네가 누군지 알아야 궁합을 봐주지 않겠나? <br/> 이름과 생년월일을 알려주게나</p>
                        <br/>
                        <Link style={{color: 'dodgerblue'}} to={`/profile`}>네 알려드릴게요</Link>
                    </div>
                }

                <div id="tingLock" className={`${lockState ? "" : "unlock"} `} style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '2rem 0px', marginTop: '3rem', filter: 'blur(15px)'}}>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <button onClick={() => { if(window.confirm('1다이아를 사용해서 \n궁합팅 목록을 새로고침 하시겠습니까?')) { isRefreshState(!refreshState); } }}>새로고침</button>
                    </div>
                    {
                        refreshState &&
                        <Fragment>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end', backgroundImage: `url('/ting-1.jpg')`, backgroundSize:'100%', backgroundPosition: 'center', width: '100%', height: '250px', borderRadius: '10px', overflow: "hidden"}}>
                                <div style={{position:'relative', display: 'flex', flexDirection:'column', justifyContent: 'flex-end', width: '100%', height: '100%', backdropFilter: 'blur(3px)', padding: '20px', boxSizing: 'border-box'}}>
                                    <b>나와의 궁합도 : 77%</b>
                                    <br/>
                                    <b>이름 : 이OO</b>
                                    <b>거주지 : 서울시</b>
                                    <b>연력 : 20대</b>
                                    <button style={{position: 'absolute', top: '15px', right: '15px'}} onClick={() => alert("궁합팅을 신청했습니다. \n상대가 수락하면 궁합팅이 시작됩니다")}>궁합팅 신청하기</button>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end', backgroundImage: `url('/ting-2.jpg')`, backgroundSize:'100%', backgroundPosition: 'center', width: '100%', height: '250px', borderRadius: '10px', overflow: "hidden"}}>
                                <div style={{position:'relative', display: 'flex', flexDirection:'column', justifyContent: 'flex-end', width: '100%', height: '100%', backdropFilter: 'blur(3px)', padding: '20px', boxSizing: 'border-box'}}>
                                    <b>나와의 궁합도 : 77%</b>
                                    <br/>
                                    <b>이름 : 이OO</b>
                                    <b>거주지 : 서울시</b>
                                    <b>연력 : 20대</b>
                                    <button style={{position: 'absolute', top: '15px', right: '15px'}} onClick={() => alert("궁합팅을 신청했습니다. \n상대가 수락하면 궁합팅이 시작됩니다")}>궁합팅 신청하기</button>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end', backgroundImage: `url('/ting-3.jpg')`, backgroundSize:'100%', backgroundPosition: 'center', width: '100%', height: '250px', borderRadius: '10px', overflow: "hidden"}}>
                                <div style={{position:'relative', display: 'flex', flexDirection:'column', justifyContent: 'flex-end', width: '100%', height: '100%', backdropFilter: 'blur(3px)', padding: '20px', boxSizing: 'border-box'}}>
                                    <b>나와의 궁합도 : 77%</b>
                                    <br/>
                                    <b>이름 : 이OO</b>
                                    <b>거주지 : 서울시</b>
                                    <b>연력 : 20대</b>
                                    <button style={{position: 'absolute', top: '15px', right: '15px'}} onClick={() => alert("궁합팅을 신청했습니다. \n상대가 수락하면 궁합팅이 시작됩니다")}>궁합팅 신청하기</button>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end', backgroundImage: `url('/ting-4.jpg')`, backgroundSize:'100%', backgroundPosition: 'center', width: '100%', height: '250px', borderRadius: '10px', overflow: "hidden"}}>
                                <div style={{position:'relative', display: 'flex', flexDirection:'column', justifyContent: 'flex-end', width: '100%', height: '100%', backdropFilter: 'blur(3px)', padding: '20px', boxSizing: 'border-box'}}>
                                    <b>나와의 궁합도 : 77%</b>
                                    <br/>
                                    <b>이름 : 이OO</b>
                                    <b>거주지 : 서울시</b>
                                    <b>연력 : 20대</b>
                                    <button style={{position: 'absolute', top: '15px', right: '15px'}} onClick={() => alert("궁합팅을 신청했습니다. \n상대가 수락하면 궁합팅이 시작됩니다")}>궁합팅 신청하기</button>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end', backgroundImage: `url('/ting-5.jpg')`, backgroundSize:'100%', backgroundPosition: 'center', width: '100%', height: '250px', borderRadius: '10px', overflow: "hidden"}}>
                                <div style={{position:'relative', display: 'flex', flexDirection:'column', justifyContent: 'flex-end', width: '100%', height: '100%', backdropFilter: 'blur(3px)', padding: '20px', boxSizing: 'border-box'}}>
                                    <b>나와의 궁합도 : 77%</b>
                                    <br/>
                                    <b>이름 : 이OO</b>
                                    <b>거주지 : 서울시</b>
                                    <b>연력 : 20대</b>
                                    <button style={{position: 'absolute', top: '15px', right: '15px'}} onClick={() => alert("궁합팅을 신청했습니다. \n상대가 수락하면 궁합팅이 시작됩니다")}>궁합팅 신청하기</button>
                                </div>
                            </div>
                        </Fragment>
                    }
                    {
                        !refreshState &&
                        <Fragment>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end', backgroundImage: `url('/ting-6.jpg')`, backgroundSize:'100%', backgroundPosition: 'center', width: '100%', height: '250px', borderRadius: '10px', overflow: "hidden"}}>
                                <div style={{position:'relative', display: 'flex', flexDirection:'column', justifyContent: 'flex-end', width: '100%', height: '100%', backdropFilter: 'blur(3px)', padding: '20px', boxSizing: 'border-box'}}>
                                    <b>나와의 궁합도 : 77%</b>
                                    <br/>
                                    <b>이름 : 이OO</b>
                                    <b>거주지 : 서울시</b>
                                    <b>연력 : 20대</b>
                                    <button style={{position: 'absolute', top: '15px', right: '15px'}} onClick={() => alert("궁합팅을 신청했습니다. \n상대가 수락하면 궁합팅이 시작됩니다")}>궁합팅 신청하기</button>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end', backgroundImage: `url('/ting-7.jpg')`, backgroundSize:'100%', backgroundPosition: 'center', width: '100%', height: '250px', borderRadius: '10px', overflow: "hidden"}}>
                                <div style={{position:'relative', display: 'flex', flexDirection:'column', justifyContent: 'flex-end', width: '100%', height: '100%', backdropFilter: 'blur(3px)', padding: '20px', boxSizing: 'border-box'}}>
                                    <b>나와의 궁합도 : 77%</b>
                                    <br/>
                                    <b>이름 : 이OO</b>
                                    <b>거주지 : 서울시</b>
                                    <b>연력 : 20대</b>
                                    <button style={{position: 'absolute', top: '15px', right: '15px'}} onClick={() => alert("궁합팅을 신청했습니다. \n상대가 수락하면 궁합팅이 시작됩니다")}>궁합팅 신청하기</button>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end', backgroundImage: `url('/ting-8.jpg')`, backgroundSize:'100%', backgroundPosition: 'center', width: '100%', height: '250px', borderRadius: '10px', overflow: "hidden"}}>
                                <div style={{position:'relative', display: 'flex', flexDirection:'column', justifyContent: 'flex-end', width: '100%', height: '100%', backdropFilter: 'blur(3px)', padding: '20px', boxSizing: 'border-box'}}>
                                    <b>나와의 궁합도 : 77%</b>
                                    <br/>
                                    <b>이름 : 이OO</b>
                                    <b>거주지 : 서울시</b>
                                    <b>연력 : 20대</b>
                                    <button style={{position: 'absolute', top: '15px', right: '15px'}} onClick={() => alert("궁합팅을 신청했습니다. \n상대가 수락하면 궁합팅이 시작됩니다")}>궁합팅 신청하기</button>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end', backgroundImage: `url('/ting-9.jpg')`, backgroundSize:'100%', backgroundPosition: 'center', width: '100%', height: '250px', borderRadius: '10px', overflow: "hidden"}}>
                                <div style={{position:'relative', display: 'flex', flexDirection:'column', justifyContent: 'flex-end', width: '100%', height: '100%', backdropFilter: 'blur(3px)', padding: '20px', boxSizing: 'border-box'}}>
                                    <b>나와의 궁합도 : 77%</b>
                                    <br/>
                                    <b>이름 : 이OO</b>
                                    <b>거주지 : 서울시</b>
                                    <b>연력 : 20대</b>
                                    <button style={{position: 'absolute', top: '15px', right: '15px'}} onClick={() => alert("궁합팅을 신청했습니다. \n상대가 수락하면 궁합팅이 시작됩니다")}>궁합팅 신청하기</button>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end', backgroundImage: `url('/ting-10.jpg')`, backgroundSize:'100%', backgroundPosition: 'center', width: '100%', height: '250px', borderRadius: '10px', overflow: "hidden"}}>
                                <div style={{position:'relative', display: 'flex', flexDirection:'column', justifyContent: 'flex-end', width: '100%', height: '100%', backdropFilter: 'blur(3px)', padding: '20px', boxSizing: 'border-box'}}>
                                    <b>나와의 궁합도 : 77%</b>
                                    <br/>
                                    <b>이름 : 이OO</b>
                                    <b>거주지 : 서울시</b>
                                    <b>연력 : 20대</b>
                                    <button style={{position: 'absolute', top: '15px', right: '15px'}} onClick={() => alert("궁합팅을 신청했습니다. \n상대가 수락하면 궁합팅이 시작됩니다")}>궁합팅 신청하기</button>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>
                
            </div>
        </div>
    )
}

export default Ting

import React, { useEffect, useState } from 'react'
import "./FavoriteCharacterListScreen.css";
import Chat from "./Chat";
import { Link } from 'react-router-dom';
import { getString, httpGet } from '../../core/utils';
import { ICharacter, IChat, IUser } from '../../shared/types';
import { useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import prettyMilliseconds from 'pretty-ms';
import { Avatar } from '@material-ui/core';


function FavoriteCharacterListScreen() {

const sessionID = useSelector<ReducerType, string>(state => state.auth.sessionID);
const [characters, setCharacters] = useState<ICharacter []>([]);

useEffect(() => {
    httpGet('/api/characters/favorites').then((res) => {
        setCharacters(res.characters);
    });
}, []);

    return (
        <div className="chats f-container">
        {characters.map(g => (
            <Link to={`/play/${g.hashID}/start`}>
            <div className="chat">
                <Avatar className="chat__image" src={g.imageURL}/>
                <div className="chat__details" style={{overflow: 'hidden'}}>
                    <h2>{g.name}</h2>
                    <p style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{g.helloText}</p>
                </div>
            </div>
            </Link>
        ))}
        {characters.length === 0 && <div style={{textAlign: 'center'}}>
            {getString('Favorite_Empty')}
        </div>}
        </div>
    )
}
 
 export default FavoriteCharacterListScreen
 
import React, {useEffect, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom';
import {LocalStorageKey, getString, httpGet, httpPost, setAccessToken} from '@src/core/utils';
import {useDispatch, useSelector} from 'react-redux';
import { setMe } from '@src/store/auth';

function ConnectScreen() {
    const params: {
        type: string,
    } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(document.location.search);

    useEffect(() => {
        connect();
    }, []);

    async function connect() {
        try {
            let payload: any = {};
            let type = params.type;
            let redirectURL = '';

            //
            window.localStorage.setItem(LocalStorageKey.REFERRAL_ID, type);

            //
            if(type == 'richgo') {
                payload = {
                    richgo: {
                        uid: searchParams.get('uid'),
                        sd: searchParams.get('sd'),
                        sgg: searchParams.get('sgg'),
                        emd: searchParams.get('emd'),
                        name: searchParams.get('name'),
                    }
                }
                redirectURL = '/';
            } else {
                history.push('/');
                return;
            }

            //
            const res = await httpPost('/api/users/connect', payload);

            //
            setAccessToken(res.token);
            dispatch(setMe(res.user));
            history.push(redirectURL || res.redirectURL);
        } catch (error) {
            console.error('Connect error:', error);
        }
    }

    return (
        <>
        </>
    )
}

export default ConnectScreen
 
export enum StatusCode {
    EXPIRED_CODE = -17,
    INTERNAL_SERVER_ERROR = -16,
    NOT_FOUND_CHAT = -15,
    RECAPTCHA_FAILED = -14,
    FREE_CHAT_LIMIT_EXCEEDED = -13,
    NOT_FOUND_CHARACTER = -12,
    NOT_ENOUGH_CASH_MONEY = -11,
    ALREADY_EXISTS_EMAIL = -10,
    INVALID_FORM = -9,
    NOT_FOUND = -8,
    DUPLICATE_SNS_ID = -7,
    WRONG_PASSWORD = -6,
    PLEASE_LOGIN = -5,
    NOT_FOUND_QUIZ = -4,
    NOT_AUTHORIZED = -3,
    NOT_FOUND_USER = -2,
    BAD_REQUEST = -1,
    SUCCESS = 0,
}
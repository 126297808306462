import React, { useEffect, useRef, useState, RefObject } from 'react'
import "./DetailScreen.css";
import {Link, useParams} from 'react-router-dom';
import {getString, httpGet, httpPost} from '@src/core/utils';
import {ICharacter, IUser} from '@src/shared/types';
import {Modal, Box, Typography} from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import {useSelector} from "react-redux";
import {ReducerType} from "@src/store";

function DetailScreen() {
    const params: {
        characterHashID: string,
    } = useParams();

    const [character, setCharacter] = useState<ICharacter>();
    const [loading, setLoading] = useState(true);
    const [chatHashID, setChatHashID] = useState<string>();

    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);

    useEffect(() => {

        setLoading(true);

        httpGet('/api/characters?characterHashID=' + params.characterHashID)
            .then((res) => {
                setCharacter(res.character);

                httpPost(`/api/characters/start?charHashID=${params.characterHashID}&create=false`, {}, undefined, true, false, false).then((res) => {
                    if(res.status >= 0) {
                        setChatHashID(res.chat.hashID);
                    }
                }).finally(() => {
                    setLoading(false);
                })
        }, );
    }, []);

    function start() {
        window.location.href = '/chat/' + chatHashID;
    }

    function newStart() {
        httpPost(`/api/characters/start?charHashID=${params.characterHashID}&create=true`, {}, undefined, true).then((res) => {
            if(res.status >= 0) {
                window.location.href = '/chat/' + res.chat.hashID;
            }
        });
    }

    useEffect(() => {
        if (character?.hashID === 'k8llg8' && me?.signupType === 'richgo') {
            setRichgoOpen(true);
        }
    }, [character]);

    const [richgoOpen, setRichgoOpen] = React.useState(false);

    return (character && (
        <>
            <Modal
                open={richgoOpen}
                onClose={() => setRichgoOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box id="richModal">
                    <p>
                        <span>🎉 리치고 유저에게만 드리는 한정적 혜택 🎉</span>
                        <strong>{me?.homeAddress}</strong> 부동산이  <br/>
                        당신과 잘맞는 <b>3가지 이유</b>를 <br/>
                        <b>5초만에 확인</b>할 수 있습니다
                    </p>
                    <button onClick={newStart}>지금 무료로 확인하기</button>
                </Box>
            </Modal>

            <div className="detail-image" style={{backgroundImage: `url('${character?.isUnse ? character?.unseImageURL : character.imageURL}')`, backgroundSize: `${character?.isUnse ? 'contain' : 'cover'}`}}>
                {
                    !character?.isUnse &&
                    <p>지금 잠금해제 하면 채팅 <b>{character.freeChatCount}회</b> 무료 상담</p>
                }
            </div>
            <div className="page-contents-wrapper" id="detailPage">
                <div className="detail-info">
                    <span className="category">{character.categoryName}</span>
                    <h6>{character.desc}</h6>
                    <div className="bottom-line">
                        <div className="detail-price">
                            💎
                            <b>{character.cashPrice.toLocaleString()}</b>
                            <p>
                                {
                                    !character?.isUnse && character?.type == 20 ?
                                        '채팅해제 + 보고서' : ''
                                }
                            </p>
                        </div>
                        {/*{character?.isUnse &&
                            <div className="star-point">
                                ⭐<span>{(character?.starPoint ?? 0).toFixed(2).replace(/\.?0+$/, '')}</span>
                            </div>
                        }*/}
                    </div>
                </div>
                <div className="detail-intro">
                    {
                        character?.subImageURL && character?.subImageURL !== '/' &&
                        <img src={`${character?.subImageURL}`} width="100%" alt="플루밍 캐릭터"/>
                    }
                </div>
                {/*<div className="detail-skill">
                    <div className="skill">
                        <b>전문분야</b>
                        <div className="tag-badge">
                            {character?.tags?.map(x => <span>{getString(x)}</span>)}
                            {character?.tags?.map(x => <span>{x.name}</span>)}
                        </div>
                    </div>
                </div>*/}


                {/*이전 대화가 없는 경우*/}
                {!loading && !chatHashID &&
                    <div className="btn-wrapper detail-floating">
                        <button className="fluming-btn" onClick={newStart}>대화시작</button>
                    </div>
                }

                {/*이전 대화가 있는 경우*/}
                {!loading && chatHashID &&
                    <div className="btn-wrapper detail-floating">
                        <button className="fluming-btn white" onClick={newStart}>새로운 대화 시작</button>
                        <button className="fluming-btn" onClick={start}>대화 이어서하기</button>
                    </div>
                }
            </div>
        </>

    )

        ||

        <></>
    )
}

export default DetailScreen
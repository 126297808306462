import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import ForumRounded from '@material-ui/icons/ForumRounded';
import HomeIcon from '@mui/icons-material/GiteRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import { useLocation } from 'react-router-dom';

function Footer() {
    const history = useHistory();
    const location = useLocation();
    const currentPath = location.pathname;

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <BottomNavigation
            value={""}
            onChange={(event, newValue) => {
            }}
            showLabels
            onClick={() => scrollToTop()}
            style={{position: 'fixed', bottom: '0px', display: 'flex', alignItems: 'center', width: '100%', height:'48px', maxWidth: '430px', margin: '0px auto', zIndex: '10', padding: "8px 0px", boxSizing: "border-box", justifyContent: "space-around", borderTop: "1px solid #E2E5EB"}}>

            <div onClick={() => {history.push('/'); scrollToTop();}} style={{cursor: "pointer"}}>
                {currentPath === '/' ?
                    <img src="/home.svg" width={32} height={32}  alt="플루밍 홈"/>
                    :
                    <img src="/home.svg" width={32} height={32}  alt="플루밍 홈"/>
                }
            </div>
            <div onClick={() => {history.push('/chats'); scrollToTop();}} style={{cursor: "pointer"}}>
                {currentPath === '/chats' ?
                    <img src="/chat.svg" width={32} height={32} alt="플루밍 채팅"/>
                    :
                    <img src="/chat.svg" width={32} height={32} alt="플루밍 채팅"/>
                }
            </div>
            <div onClick={() => {history.push('/more'); scrollToTop();}} style={{cursor: "pointer"}}>
                {currentPath === '/more' ?
                    <img src="/profile.svg" width={32} height={32} alt="플루밍 프로필"/>
                    :
                    <img src="/profile.svg" width={32} height={32} alt="플루밍 프로필"/>
                }
            </div>
            <div onClick={() => {history.push('/shop'); scrollToTop();}} style={{cursor: "pointer", marginBottom: '8px'}}>
                💎
            </div>
        </BottomNavigation>
    )
}

export default Footer

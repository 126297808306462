import React, { useEffect, useRef, useState } from 'react'
import "./HomeScreen.css";
import {httpGet, httpPost} from '../../core/utils';
import { useSelector } from 'react-redux';
import { ReducerType } from '../../store';
import { ICharacter, IUser, IInfluencer} from '../../shared/types';
import { Link } from 'react-router-dom';
import {Grid} from '@material-ui/core';
import InfoFooter from "@src/screens/app/InfoFooter";
import 'react-circular-progressbar/dist/styles.css';
import { useHistory } from 'react-router-dom';

enum Tab {
    FOLLOWING = 'following',
    RECOMMEND = 'recommend',
}

function MainScreen() {
    const [characters, setCharacters] = useState<ICharacter []>([]);
    const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
    const [order, setOrder] = useState('popular');
    const [keyword, setKeyword] = useState('');
    const [tag, setTag] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState('following')
    const [influencers, setInfluencers] = useState<IInfluencer[]>([]);

    const history = useHistory();

    function refresh(reset = true) {
        let _offset = 0;
        if (!reset) {
            _offset = characters.length;
        }

        // 데이터 가져오기 시작 전에 로딩을 true로 설정한다
        setLoading(true);

        let type = 'following';

        httpGet('/api/characters/search', {
            order: order,
            keyword: keyword,
            tag: tag,
            type: type,
            offset: _offset,
        })
            .then((res) => {
                if (res.status >= 0) {
                    if (reset) {
                        setCharacters(res.characters.slice(0, 100)); // Only take the first 20 characters for initial load
                    } else {
                        // Add only up to 20 new characters when fetching additional data
                        const newCharacters = res.characters.slice(0, 100);
                        setCharacters([...characters, ...newCharacters]);
                    }
                }
            })
            .catch((error) => {
                console.error("데이터 가져오기 오류: ", error);
            })
            .finally(() => {
                // Set loading to true as soon as loading is done
                setLoading(true);

                // Set a timeout to change loading to false after 0.5 seconds
                setTimeout(() => {
                    setLoading(false);
                }, 500); // 500 milliseconds = 0.5 seconds
            });
    }

    function fetchInfluencers(reset = true) {
        setLoading(true);

        let _offset = 0;
        if (!reset) {
            _offset = influencers.length;
        }

        httpGet('/api/influencers', {
            offset: _offset,
        })
            .then((res) => {
                if (Array.isArray(res)) {
                    const newInfluencers = res.slice(0, 100);
                    if (reset) {
                        setInfluencers(newInfluencers);
                    } else {
                        setInfluencers([...influencers, ...newInfluencers]);
                    }
                } else if (typeof res === 'object' && res !== null && Array.isArray(res.influencers)) {
                    const newInfluencers = res.influencers.slice(0, 100);
                    if (reset) {
                        setInfluencers(newInfluencers);
                    } else {
                        setInfluencers([...influencers, ...newInfluencers]);
                    }
                } else {
                    console.log("예상치 못한 응답 형식입니다.");
                }
            })
            .catch((error) => {
                console.error("데이터 가져오기 오류: ", error);
            })
            .finally(() => {
                setLoading(true);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
    }

    useEffect(() => {
        fetchInfluencers();
    }, []);

    useEffect(() => {
        console.log("Updated influencers:", influencers);
    }, [influencers]);

    useEffect(() => {
        refresh();
    }, [order, tag, tab]);

    useEffect(() => {
        window.scrollTo(0, -50);
    })

    return (
        <div className="page-contents-wrapper" id="MainScreen">
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '30px 18px 16px', backgroundColor: '#ffffff', boxSizing: 'border-box'}}>
                <img src="/flumingTextLogo.png" alt="" style={{width: '150px', marginBottom: '20px'}}/>
            </div>
            <div className="character-tabs">
                <div className={`tab ${tab === Tab.FOLLOWING ? "select" : ""}`} onClick={() => setTab(Tab.FOLLOWING)}>팔로잉</div>
                <div className={`tab ${tab === Tab.RECOMMEND ? "select" : ""}`} onClick={() => setTab(Tab.RECOMMEND)}>추천</div>
            </div>
            <div id="characterList">
                {tab == Tab.FOLLOWING && influencers.map((influencer) => (
                    <Grid>
                        <button
                            onClick={() => history.push(`/${influencer?.name}`)}
                            style={{display: 'flex', alignItems: 'center', gap: '0px 16px', width: '100%', height: '130px', backgroundColor: '#ffffff', padding: '12px 16px', boxSizing: 'border-box', border: 'none', cursor: 'pointer'}}
                        >
                            <img src={influencer?.mainImageURL || '/flumingLogo.png'} width={100} height={100}/>
                            <div className="character-info" style={{display: 'flex', flexDirection: 'column'}}>
                                <div className="category-title">
                                    {/*<span className="category">@shu</span>*/}
                                    <span className="character-list--item">{influencer?.kor_name}</span>
                                    <img src="/verify.svg" width={20} height={20}/>
                                </div>
                                <small style={{ color : 'grey', margin: '5px 0px 13px 0px'}}>Followers : {influencer?.follower}</small>
                                <div className="sns-link" style={{display: 'flex', gap: '0px 10px', alignItems: 'center'}}>
                                    <img src="/instagram.png" width={25} height={25} onClick={(e) => { e.stopPropagation(); window.open(`https://www.instagram.com/${influencer?.sns?.instagram}/`, '_blank'); }}/>
                                    <img src="/youtube.png" width={25} height={25} onClick={(e) => { e.stopPropagation(); window.open(`https://www.youtube.com/@${influencer?.sns?.youtube}`, '_blank'); }}/>
                                    <img src="/tiktok.png" width={25} height={25} onClick={(e) => { e.stopPropagation(); window.open(`https://www.tiktok.com/@${influencer?.sns?.tiktok}`, '_blank'); }}/>
                                    <img src="/x.png" width={20} height={20} onClick={(e) => { e.stopPropagation(); window.open(`https://x.com/${influencer?.sns?.twitter}`, '_blank'); }}/>
                                </div>
                            </div>
                        </button>
                    </Grid>
                    ))
                }
                {tab == Tab.RECOMMEND &&
                    <Grid>
                        <button
                            onClick={() => window.location.href = '/shu/'}
                            style={{display: 'flex', alignItems: 'center', gap: '0px 16px', width: '100%', height: '130px', backgroundColor: '#ffffff', padding: '12px 16px', boxSizing: 'border-box', border: 'none', cursor: 'pointer'}}
                        >
                            <img src="/fluming/shu/main.png" width={100} height={100}/>
                            <div className="character-info" style={{display: 'flex', flexDirection: 'column'}}>
                                <div className="category-title">
                                    {/*<span className="category">@shu</span>*/}
                                    <span className="character-list--item">슈</span>
                                    <img src="/verify.svg" width={20} height={20}/>
                                </div>
                                <small style={{ color : 'grey', margin: '5px 0px 13px 0px'}}>27.7K</small>
                                <div className="sns-link" style={{display: 'flex', gap: '0px 10px', alignItems: 'center'}}>
                                    <img src="/instagram.png" width={25} height={25} onClick={(e) => { e.stopPropagation(); window.open('https://www.instagram.com/2._.shu_/', '_blank'); }}/>
                                    <img src="/youtube.png" width={25} height={25} onClick={(e) => { e.stopPropagation(); window.open('https://www.youtube.com/@2shu', '_blank'); }}/>
                                    <img src="/tiktok.png" width={25} height={25} onClick={(e) => { e.stopPropagation(); window.open('https://www.tiktok.com/@2._.shu_', '_blank'); }}/>
                                    <img src="/x.png" width={20} height={20} onClick={(e) => { e.stopPropagation(); window.open('https://x.com/2_shu_2', '_blank'); }}/>
                                </div>
                            </div>
                            <button className="follow-btn">팔로우</button>
                        </button>
                    </Grid>
                }
                <div className="MuiGrid-root MuiGrid-item" style={{width: '100%', height: '165px', borderTop: 'none', boxShadow: 'none'}}>
                    <h1 style={{visibility: 'hidden'}}>플루밍</h1>
                </div>
            </div>

        </div>
    )
}
// @ts-ignore
export default MainScreen